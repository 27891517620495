/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import Input from '../common/Input';
import * as S from './style';
import useFetch from '../../hooks/useFetch';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CustomButton from '../common/Button';

const MyArtWorkEdit = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isEditable, setIsEditable] = useState(false);
  const [formValue, setFormValue] = useState(null);
  const [originalFormValue, setOriginalFormValue] = useState(null);
  const [preview, setPreview] = useState(null);
  const customerCode = searchParams.get('customerCode');
  const artworkId = searchParams.get('artworkId');

  useEffect(() => {
    if (!customerCode || !artworkId) navigate('/404');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getArtworkDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleResize = () => {
      updateParentHeight();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const updateParentHeight = () => {
    const message = {
      artworksHeight: document.querySelector('#root > div').scrollHeight + 10,
    };
    window.top.postMessage(message, 'https://artmuse.im');
  };

  useEffect(() => {
    updateParentHeight();
  }, [preview, originalFormValue, isEditable]);

  const { fetch: getArtworkDetail } = useFetch({
    url: `/artwork/my/approve/${artworkId}?customerCode=${customerCode}`,
    method: 'get',
    onSuccess: (data) => {
      setFormValue(data);
      setOriginalFormValue(data);
      setPreview(data.imageUrl);
    },
  });

  const { fetch: updateArtwork, isLoading } = useFetch({
    url: `/artwork/${artworkId}`,
    method: 'patch',
    onSuccess: () => {
      alert('작품 정보가 성공적으로 수정되었습니다.');
      setIsEditable(false);
      setOriginalFormValue(formValue);
      getArtworkDetail();
    },
    onError: () => {
      alert('작품 정보 수정에 실패했습니다.');
    },
  });

  const handleCheckInt = (value) => /^\d*$/.test(value);
  const handleCheckMax = (value, max) => parseFloat(value) <= max;
  const handleEditClick = () => {
    setIsEditable(true);
  };

  const handleEditCancel = () => {
    setIsEditable(false);
    setFormValue(originalFormValue);
    setPreview(originalFormValue.imageUrl);
  };

  const handleCancel = () => {
    const message = { cancel: true };
    window.top.postMessage(message, 'https://artmuse.im');
  };

  const handleSaveClick = async () => {
    const formData = new FormData();
    formData.append('title', formValue.title);
    formData.append('description', formValue.description);
    formData.append('price', formValue.price);
    formData.append('originalPrice', formValue.originalPrice);
    formData.append('country', formValue.country);
    formData.append('materials', formValue.materials);
    formData.append('size', formValue.size);
    formData.append('createdYear', formValue.createdYear);
    formData.append('frameSize', formValue.frameSize);

    if (formValue.image instanceof File) {
      formData.append('image', formValue.image);
    }

    await updateArtwork(formData);
    updateParentHeight();
  };

  const handleCheckIntInput = (value, { max }) => {
    if (value === '') return true;

    const isInt = handleCheckInt(value);
    const isMax = handleCheckMax(value, max);

    return isInt && isMax;
  };

  const handleSetPrice = (e) => {
    const value = e.target.value;

    setFormValue((prev) => ({
      ...prev,
      originalPrice: value,
      price: value,
    }));
  };

  const handleInputValueChange = (e) => {
    const key = e.target.id;
    const value = e.target.value;
    setFormValue((prev) => ({ ...prev, [key]: value }));
  };

  const handleFileUpload = async (file, key) => {
    if (!file) return;
    if (!file.type.startsWith('image/jpeg')) {
      window.alert('jpg형태 이미지만 업로드 해주세요.');
      return;
    }
    if (file.size >= 1024 ** 2 * 100) {
      alert('파일 사이즈가 너무 큽니다.');
      return;
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = async function () {
      const width = img.width;
      const height = img.height;

      if (Math.max(width, height) < 4829) {
        alert(
          '이미지 사이즈가 너무 작습니다. \n인쇄 품질을 위해 가로 또는 세로가 4830px 이상의 이미지를 등록해주세요',
        );
        return;
      }

      setFormValue((prev) => ({ ...prev, [key]: file }));

      try {
        const resizedImage = await resizeImage(file, 800, 800); // 이미지 크기 조정
        const preview = await generatePreview(resizedImage); // 미리보기 생성
        setPreview(preview);
      } catch (error) {
        console.error('Error generating preview:', error);
      }
    };
  };

  const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          let width = img.width;
          let height = img.height;

          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }

          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            resolve(new File([blob], file.name, { type: 'image/jpeg' }));
          }, 'image/jpeg');
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const generatePreview = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.readAsDataURL(file);
    });
  };

  const readFile = (e) => {
    const key = e.target.id;
    const file = e.target.files[0];
    handleFileUpload(file, key);
  };

  const getButtonProps = () => {
    const isStatusDone =
      formValue.status === 'DONE' && formValue.isCanceled === true;

    return {
      $state: isStatusDone ? 'default' : 'disabled',
      $variant: isStatusDone ? 'saveEdit' : 'disabledSaveEdit',
      text: isStatusDone ? '수정' : '수정 불가',
      onClick: isStatusDone ? handleEditClick : null,
    };
  };

  if (!formValue) return <div>Loading...</div>;

  return (
    <S.MyArtWorkWrapper>
      <S.FormWrapper>
        <S.Form>
          <S.InputStyle isEditable={!isEditable}>
            {isEditable ? (
              <>
                <label>
                  <span required>
                    작품사진 (가로 또는 세로가 4830px 이상 이미지)
                  </span>
                  <S.FileUpload htmlFor="image">
                    {preview ? (
                      <S.BackgroundImg bgImg={preview}></S.BackgroundImg>
                    ) : (
                      '작품사진을 등록해주세요'
                    )}
                    <input
                      id="image"
                      type={'file'}
                      multiple
                      accept="image/*"
                      onChange={readFile}
                    />
                  </S.FileUpload>
                </label>
                <Input
                  id="title"
                  type={'text'}
                  setValue={handleInputValueChange}
                  label={'작품명'}
                  value={formValue.title || ''}
                  required
                />
                <Input
                  id="createdYear"
                  type={'text'}
                  setValue={handleInputValueChange}
                  value={formValue.createdYear || ''}
                  label={'제작년도'}
                  required
                />
                <label htmlFor="size">
                  <span required>원작 크기</span>
                  <S.Size>
                    <Input
                      id="size"
                      type={'text'}
                      setValue={handleInputValueChange}
                      value={formValue.size || ''}
                      required
                    />
                  </S.Size>
                </label>
                <label>
                  <span required>
                    판화 크기 (비율에 따라 실제 사이즈가 달라질 수 있습니다)
                  </span>
                  <select
                    id="frameSize"
                    defaultValue={'6호'}
                    required
                    onChange={handleInputValueChange}
                  >
                    <option value="">사이즈</option>
                    <option value={'6호'}>6호</option>
                  </select>
                </label>
                <label>
                  <span id="country" required>
                    국가
                  </span>
                  <select
                    id="country"
                    required
                    defaultValue={'대한민국'}
                    onChange={handleInputValueChange}
                  >
                    <option value="">국가 선택</option>
                    <option value={'대한민국'}>대한민국</option>
                  </select>
                </label>
                <Input
                  id="originalPrice"
                  type={'text'}
                  setValue={(e) => {
                    if (
                      !handleCheckIntInput(e.target.value, {
                        max: 999999999,
                      })
                    )
                      return;
                    handleSetPrice(e);
                  }}
                  label={'작품 원작 가격'}
                  value={formValue.originalPrice || ''}
                  required
                  isPrice
                />
                <Input
                  id="materials"
                  type={'text'}
                  setValue={handleInputValueChange}
                  label={'재료'}
                  value={formValue.materials || ''}
                  required
                />
                <label>
                  <span required>작품 설명</span>
                  <textarea
                    id="description"
                    onChange={handleInputValueChange}
                    value={formValue.description || ''}
                    required
                  ></textarea>
                </label>
                <S.ButtonWrapper>
                  <CustomButton
                    size="medium"
                    $state={isLoading ? 'disabled' : 'default'}
                    text="취소"
                    $variant="cancel"
                    onClick={handleEditCancel}
                    disabled={isLoading}
                  />
                  <CustomButton
                    size="medium"
                    $state={isLoading ? 'disabled' : 'default'}
                    text={isLoading ? '저장 중...' : '저장'}
                    $variant="saveEdit"
                    onClick={handleSaveClick}
                    disabled={isLoading}
                  />
                </S.ButtonWrapper>
              </>
            ) : (
              <>
                {/* 비활성화된 입력 필드 */}
                <label>
                  <span>작품사진</span>
                  <S.ProductImg>
                    <img src={formValue.imageUrl} alt="img" loading="lazy" />
                  </S.ProductImg>
                </label>
                <Input
                  id="title"
                  type={'text'}
                  label={'작품명'}
                  value={formValue.title || ''}
                  disabled
                />
                <Input
                  id="createdYear"
                  type={'text'}
                  label={'제작년도'}
                  value={formValue.createdYear || ''}
                  disabled
                />
                <Input
                  id="size"
                  type={'text'}
                  label={'원작 크기'}
                  value={formValue.size || ''}
                  disabled
                />
                <Input
                  id="frameSize"
                  type={'text'}
                  label={'판화 크기'}
                  value={formValue.frameSize || ''}
                  disabled
                />
                <Input
                  id="country"
                  type={'text'}
                  label={'국가'}
                  value={formValue.country || ''}
                  disabled
                />
                <Input
                  id="originalPrice"
                  type={'text'}
                  label={'작품 원작 가격'}
                  value={formValue.originalPrice || ''}
                  disabled
                  isPrice
                />

                <Input
                  id="materials"
                  type={'text'}
                  label={'재료'}
                  value={formValue.materials || ''}
                  disabled
                />
                <label>
                  <span>작품 설명</span>
                  <textarea
                    id="description"
                    value={formValue.description || ''}
                    disabled
                  ></textarea>
                </label>
                <S.ButtonWrapper>
                  <CustomButton
                    size="medium"
                    $state={isLoading ? 'disabled' : 'default'}
                    text="돌아가기"
                    $variant="cancel"
                    onClick={handleCancel}
                  />
                  <CustomButton size="medium" {...getButtonProps()} />
                </S.ButtonWrapper>
              </>
            )}
          </S.InputStyle>
        </S.Form>
      </S.FormWrapper>
    </S.MyArtWorkWrapper>
  );
};

export default MyArtWorkEdit;
