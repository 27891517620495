import { useEffect } from 'react';
import MyPageButtons from '../components/MyPageButtons';

const MypageButtonPage = () => {
  useEffect(() => {
    const handleMessage = () => {
      const message = { height: 40 };
      window.top.postMessage(message, 'https://artmuse.im');
    };

    if (document.readyState === 'complete') {
      handleMessage();
    } else {
      window.addEventListener('load', handleMessage);
      return () => document.removeEventListener('load', handleMessage);
    }
  }, []);

  const handleButtonClick1 = () => {
    window.top.postMessage(
      { action: 'goToArtistEditPage' },
      'https://artmuse.im',
    );
  };

  const handleButtonClick2 = () => {
    window.top.postMessage(
      { action: 'goToArtworkManagementPage' },
      'https://artmuse.im',
    );
  };

  return (
    <MyPageButtons
      onButtonClick1={handleButtonClick1}
      onButtonClick2={handleButtonClick2}
    />
  );
};

export default MypageButtonPage;
