import { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import { formatNumber } from '../../util/format';
import { formatDate } from '../../util/fordate.js';
import CustomButton from '../common/Button';
import Skeleton from './Skeleton.jsx';
import Dropdown from '../common/Dropdown/index.jsx';
import * as S from './style';
import { Banks } from '../../Lib/Banks.js';

const ArtistProfile = ({ hasToken = false }) => {
  // 상태 정의
  const [searchParams] = useSearchParams();
  const artistCode = searchParams.get('artistCode');
  const isEditMode = searchParams.get('edit') === 'true';
  const [isEditing, setIsEditing] = useState(false);
  const [isIframe, setIsIframe] = useState(false);

  const [artist, setArtist] = useState({});
  const [works, setWorks] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const [signImage, setSignImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const productItemWrapperRef = useRef(null);

  let isDown = false;
  let startX;
  let scrollLeft;
  let animationFrameId;

  const handleMouseDown = (e) => {
    isDown = true;
    startX = e.pageX - productItemWrapperRef.current.offsetLeft;
    scrollLeft = productItemWrapperRef.current.scrollLeft;
    cancelAnimationFrame(animationFrameId);
    window.getSelection().removeAllRanges(); // 텍스트 선택 방지
  };

  const handleMouseLeave = () => {
    isDown = false;
    cancelAnimationFrame(animationFrameId);
  };

  const handleMouseUp = () => {
    isDown = false;
    cancelAnimationFrame(animationFrameId);
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - productItemWrapperRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    animationFrameId = requestAnimationFrame(() => {
      productItemWrapperRef.current.scrollLeft = scrollLeft - walk;
    });
  };

  // API 호출부
  const { fetch: getProfile } = useFetch({
    url: `/artwork/artist/introduce?artistCode=${artistCode}`,
    method: 'get',
    onSuccess: (e) => {
      setArtist(e);
    },
    onError: (error) => {
      setError(error);
    },
  });

  const { fetch: getWorks } = useFetch({
    url: `/artwork/artist/more?artistCode=${artistCode}`,
    method: 'get',
    onSuccess: (e) => {
      setWorks(e);
    },
    onError: (error) => {
      setError(error);
    },
  });

  const { fetch: updateProfile } = useFetch({
    url: `/artwork/artist/${artistCode}`,
    method: 'PUT',
    onSuccess: async () => {
      setIsEditing(false);
      await getProfile();
      await getWorks();
      alert(`사용자 정보 수정 완료`);
    },
    onError: (error) => {
      alert(`수정 요청 실패: ${error}`);
    },
  });

  // useEffect
  // 1. 기본정보 호출 및 ifraem 설정
  useEffect(() => {
    const fetchData = async () => {
      try {
        await getProfile();
        await getWorks();
        setIsLoading(false);
        window.parent.postMessage({ isLoading: false }, 'https://artmuse.im');
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // iframe 높이 설정
  const updateParentHeight = () => {
    const message = {
      profileHeight: document.querySelector('#root > div').scrollHeight + 10,
    };
    window.parent.postMessage(message, 'https://artmuse.im');
  };

  useEffect(() => {
    updateParentHeight();
  }, [artist, works, isEditing]);

  // 상위 페이지가 존재하는지 확인 후, 토큰 무시
  useEffect(() => {
    if (window.self !== window.top) {
      setIsIframe(true);
      localStorage.removeItem('accessToken'); // 토큰 무시
    }
  }, []);

  // 창 크기가 변경될 때마다 높이 갱신
  useEffect(() => {
    const handleResize = () => {
      updateParentHeight();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // 함수부
  const renderArtistHistory = (history) => {
    if (!history) return null;
    const lines = history.split('<br/>');
    return lines.map((line, index) => {
      const match = line.match(/<p>(.*?)<\/p>/);
      if (match) {
        return <p key={index}>{match[1]}</p>;
      }
      return <br key={index} />;
    });
  };

  const renderBankItem = (bank) => <S.BasicInfo>{bank}</S.BasicInfo>;

  const postWorkLandingUrlToParent = (landingUrl) => {
    window.top.postMessage({ works: landingUrl }, 'https://artmuse.im');
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  //TODO: handleSave 동작 시, 대기 간에 로딩 모달 보여주도록 공통 모달 컴포넌트 추가 필요- 0605-TW
  const handleSaveClick = async () => {
    try {
      const formData = new FormData();

      // 수정된 작가 정보 추가
      Object.entries(artist).forEach(([key, value]) => {
        if (key === 'history') {
          let convertedHistory = '';
          value.split('\n').forEach((line) => {
            convertedHistory += line ? `<p>${line}</p><br/>` : '<br/>';
          });
          formData.append(key, convertedHistory);
        } else {
          formData.append(key, value);
        }
      });

      // 수정된 프로필 이미지 추가
      if (profileImage instanceof File) {
        formData.append('representativeImg', profileImage);
      }

      // 수정된 서명 이미지 추가
      if (signImage instanceof File) {
        formData.append('signImg', signImage);
      }

      await updateProfile(formData);
    } catch (error) {
      console.error('수정 요청 실패:', error);
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    getProfile();
    getWorks();
  };

  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    setProfileImage(file);
  };

  const handleSignImageChange = (e) => {
    const file = e.target.files[0];
    setSignImage(file);
  };

  // 1. 사용자 정보 조회 시
  const renderArtistInfo = () => {
    return (
      <>
        <S.ArtistNameWrapper>
          <S.ArtistNameTitle>아티스트 활동명</S.ArtistNameTitle>
          <S.ArtistName style={{ opacity: isLoading ? 0 : 1 }}>
            {artist.activityName}
          </S.ArtistName>
        </S.ArtistNameWrapper>
        <S.ArtistDescriptionWrapper>
          <S.ArtistDescriptionTitle style={{ opacity: isLoading ? 0 : 1 }}>
            소개글
          </S.ArtistDescriptionTitle>
          <S.ArtistDescription style={{ opacity: isLoading ? 0 : 1 }}>
            {artist.shortDescription}
          </S.ArtistDescription>
        </S.ArtistDescriptionWrapper>
        <S.ArtistHistoryWrapper>
          <S.ArtistHistoryTitle>작가 연혁</S.ArtistHistoryTitle>
          <S.ArtistHistory style={{ opacity: isLoading ? 0 : 1 }}>
            {renderArtistHistory(artist.history)}
          </S.ArtistHistory>
        </S.ArtistHistoryWrapper>
      </>
    );
  };

  // 2. 사용자 정보 수정 시
  const renderArtistEditForm = () => {
    return (
      <>
        <S.ArtistNameWrapper>
          <S.ArtistNameTitle>아티스트 활동명</S.ArtistNameTitle>
          <S.ArtistNameInput
            type="text"
            value={artist.activityName}
            onChange={(e) =>
              setArtist({ ...artist, activityName: e.target.value })
            }
            placeholder="활동명을 작성해주세요."
            style={{
              color: artist.activityName ? '#1e2024' : '#9FA1A8',
              fontSize: '16px',
            }}
          />
        </S.ArtistNameWrapper>
        <S.ArtistDescriptionWrapper>
          <S.ArtistDescriptionTitle>소개글</S.ArtistDescriptionTitle>
          <S.ArtistDescriptionTextarea
            value={artist.shortDescription}
            onChange={(e) =>
              setArtist({
                ...artist,
                shortDescription: e.target.value,
              })
            }
            placeholder="소개글을 작성해주세요"
            style={{
              color: artist.shortDescription ? '#1e2024' : '#9FA1A8',
              fontSize: '16px',
            }}
          />
        </S.ArtistDescriptionWrapper>
        <S.ArtistHistoryWrapper>
          <S.ArtistHistoryTitle>작가 연혁</S.ArtistHistoryTitle>
          <S.ArtistHistoryTextarea
            value={
              artist.history
                ? artist.history.replace(/<br\/>/g, '\n').replace(/<\/?p>/g, '')
                : ''
            }
            onChange={(e) => {
              const value = e.target.value;
              setArtist({ ...artist, history: value });
            }}
            placeholder="작가 연혁을 작성해주세요"
            style={{
              color: artist.history ? '#1e2024' : '#9FA1A8',
              fontSize: '16px',
            }}
          />
        </S.ArtistHistoryWrapper>
      </>
    );
  };

  return (
    <S.ArtistProfileContainer $hasToken={isIframe ? false : hasToken}>
      {isLoading ? (
        <Skeleton isLoading={isLoading} /> // 로딩 중일 때 스켈레톤 컴포넌트 렌더링
      ) : (
        <>
          {(isIframe ? false : hasToken) ? (
            <S.ArtistDetailInfoTitle>작가 정보 상세</S.ArtistDetailInfoTitle>
          ) : null}
          <S.ContentsWrapper>
            <S.ContentsLeftWrapper>
              {isEditing ? (
                <div style={{ position: 'relative' }}>
                  <S.ProfileImage
                    src={
                      profileImage instanceof File
                        ? URL.createObjectURL(profileImage)
                        : artist.representativeImgUrl
                    }
                    alt={artist.activityName}
                    style={{ opacity: isLoading ? 0 : 1 }}
                  />
                  <S.ImageUploadLabel htmlFor="profile-image-upload">
                    <S.InputButton>이미지 업로드</S.InputButton>
                  </S.ImageUploadLabel>
                  {!profileImage && !artist.representativeImgUrl && (
                    <S.ProfileImageUploadInfo>
                      - 권장 이미지 : 1:1 비율 / png, jpg / 사이즈 최대
                      561*561px
                    </S.ProfileImageUploadInfo>
                  )}
                  <S.HiddenInput
                    id="profile-image-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleProfileImageChange}
                  />
                </div>
              ) : (
                <S.ProfileImage
                  src={artist.representativeImgUrl}
                  alt={artist.activityName}
                />
              )}
              {isEditing ? (
                <S.ProductItemWrapper>
                  {Array.from({ length: 3 }).map((_, index) => (
                    <S.ProductItem key={index}>
                      <S.ProductImage />
                      <S.ProductTitle>내 상품</S.ProductTitle>
                      <S.ProductPrice>0 원</S.ProductPrice>
                    </S.ProductItem>
                  ))}
                </S.ProductItemWrapper>
              ) : (
                <S.ProductItemWrapper
                  ref={productItemWrapperRef}
                  onMouseDown={handleMouseDown}
                  onMouseLeave={handleMouseLeave}
                  onMouseUp={handleMouseUp}
                  onMouseMove={handleMouseMove}
                >
                  {works
                    .filter((work) => work.productId !== null)
                    .map((work) => (
                      <S.ProductItem
                        key={work.artworkId}
                        onClick={() =>
                          postWorkLandingUrlToParent(work.landingUrl)
                        }
                      >
                        <S.ProductImage
                          src={work.resizingImgUrl}
                          alt={work.title}
                        />
                        <S.ProductTitle>{work.title}</S.ProductTitle>
                        <S.ProductPrice>
                          {formatNumber(work.price)} 원
                        </S.ProductPrice>
                      </S.ProductItem>
                    ))}
                </S.ProductItemWrapper>
              )}
              {isEditing ? (
                <div style={{ position: 'relative' }}>
                  <S.SignImage
                    src={
                      signImage instanceof File
                        ? URL.createObjectURL(signImage)
                        : artist.signImgUrl
                    }
                    alt={artist.activityName}
                    isEditing={isEditing}
                  />
                  <S.ImageUploadLabel htmlFor="sign-image-upload">
                    <S.InputButton>이미지 업로드</S.InputButton>
                  </S.ImageUploadLabel>
                  {!signImage && !artist.signImgUrl && (
                    <S.SignImageUploadInfo>
                      - 권장 이미지 : 1:1 비율 / png, jpg / 사이즈 최대
                      561*561px
                    </S.SignImageUploadInfo>
                  )}
                  <S.HiddenInput
                    id="sign-image-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleSignImageChange}
                  />
                </div>
              ) : (
                <S.SignImage
                  src={artist.signImgUrl}
                  alt={artist.activityName}
                  style={{ opacity: isLoading ? 0 : 1 }}
                />
              )}
            </S.ContentsLeftWrapper>

            <S.ContentsRightWrapper>
              {isEditing ? renderArtistEditForm() : renderArtistInfo()}
            </S.ContentsRightWrapper>
          </S.ContentsWrapper>

          {hasToken ? (
            <S.BasicInfoContatiner>
              <S.BasicInfoHeader>
                <S.BasicInfoHeaderTitle>기본정보</S.BasicInfoHeaderTitle>
              </S.BasicInfoHeader>

              <S.BasicInfoContents>
                <S.BasicInfoWrapper>
                  <S.BasicInfotitle>작가실명</S.BasicInfotitle>
                  {isEditing ? (
                    <S.BasicInfoInput
                      type="text"
                      value={artist.name}
                      onChange={(e) =>
                        setArtist({ ...artist, name: e.target.value })
                      }
                    />
                  ) : (
                    <S.BasicInfo>{artist.name}</S.BasicInfo>
                  )}
                </S.BasicInfoWrapper>
                <S.BasicInfoWrapper>
                  <S.BasicInfotitle>이메일</S.BasicInfotitle>
                  {isEditing ? (
                    <S.BasicInfoInput
                      type="email"
                      value={artist.email}
                      disabled
                      style={{ backgroundColor: '#f5f5f5', color: '#9b9b9b' }}
                    />
                  ) : (
                    <S.BasicInfo>{artist.email}</S.BasicInfo>
                  )}
                </S.BasicInfoWrapper>
                <S.BasicInfoWrapper>
                  <S.BasicInfotitle>작가 등록일</S.BasicInfotitle>
                  {isEditing ? (
                    <S.BasicInfoInput
                      type="text"
                      value={formatDate(artist.createdAt)}
                      disabled
                      style={{ backgroundColor: '#f5f5f5', color: '#9b9b9b' }}
                    />
                  ) : (
                    <S.BasicInfo>{formatDate(artist.createdAt)}</S.BasicInfo>
                  )}
                </S.BasicInfoWrapper>
                <S.BasicInfoWrapper>
                  <S.BasicInfotitle>연락처</S.BasicInfotitle>
                  {isEditing ? (
                    <S.BasicInfoInput
                      type="text"
                      value={artist.phoneNumber}
                      onChange={(e) =>
                        setArtist({ ...artist, phoneNumber: e.target.value })
                      }
                    />
                  ) : (
                    <S.BasicInfo>{artist.phoneNumber}</S.BasicInfo>
                  )}
                </S.BasicInfoWrapper>
                <S.BasicInfoWrapper $isdropdown>
                  <S.BasicInfotitle>정산 계좌</S.BasicInfotitle>
                  {isEditing ? (
                    <S.BasicInfoGroup>
                      <Dropdown
                        options={['선택', ...Banks]}
                        selectedValue={
                          Banks.includes(artist.bankName)
                            ? artist.bankName
                            : '선택'
                        }
                        onOptionChange={(selectedBank) =>
                          setArtist({ ...artist, bankName: selectedBank })
                        }
                        renderItem={renderBankItem}
                        minWidth="150px"
                        fontSize="10px"
                        maxHeight="150px"
                        height="30px"
                      />
                      <S.BasicInfoInput
                        type="text"
                        value={artist.bankNumber}
                        onChange={(e) =>
                          setArtist({ ...artist, bankNumber: e.target.value })
                        }
                      />
                    </S.BasicInfoGroup>
                  ) : (
                    <S.BasicInfoGroup>
                      <S.BasicInfo>{artist.bankName}은행</S.BasicInfo>
                      <S.BasicInfo>{artist.bankNumber}</S.BasicInfo>
                    </S.BasicInfoGroup>
                  )}
                </S.BasicInfoWrapper>
              </S.BasicInfoContents>
            </S.BasicInfoContatiner>
          ) : null}
          {isEditMode && (
            <S.ButtonGroup>
              {!isEditing ? (
                <CustomButton
                  size="medium"
                  $state="default"
                  text="수정하기"
                  $variant="saveEdit"
                  onClick={handleEditClick}
                />
              ) : (
                <>
                  <CustomButton
                    size="medium"
                    $state="default"
                    text="처음으로 돌아가기"
                    $variant="gobackhome"
                    onClick={handleCancelClick}
                  />

                  <CustomButton
                    size="medium"
                    $state="default"
                    text="수정 내용 저장하기"
                    $variant="saveEdit"
                    onClick={handleSaveClick}
                  />
                </>
              )}
            </S.ButtonGroup>
          )}
        </>
      )}
    </S.ArtistProfileContainer>
  );
};

export default ArtistProfile;
