export const Banks = [
  'KB국민',
  '기업',
  '농협',
  '산업',
  '수협',
  '신한',
  '우리',
  '우체국',
  '하나',
  '한국씨티',
  'SC제일',
  '카카오뱅크',
  '케이뱅크',
  '토스뱅크',
  '경남',
  '광주',
  '대구',
  '부산',
  '전북',
  '제주',
  '저축',
  '산림조합',
  '새마을',
  '신협',
  '도이치',
  '뱅크오브아메리카',
  '중국건설',
  '중국공상',
  '중국',
  'HSBC',
  'BNP파리바',
  'JP모간체이스',
];
