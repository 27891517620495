import * as S from './style';

const CustomButton = ({
  size,
  $state,
  text,
  $variant,
  onClick,
  type = 'button',
  disabled,
}) => {
  return (
    <S.CustomButton
      size={size}
      $state={$state}
      $variant={$variant}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {text}
    </S.CustomButton>
  );
};

export default CustomButton;
