import styled from '@emotion/styled';

export const MainHeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 850px;
  height: auto;
  z-index: 100;
  background-color: #fff;
`;

export const MainHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 80px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  padding: 27px 0;
  align-items: center;
  gap: 10px;
`;

export const Title = styled.div`
  display: flex;
  font-size: 34px;
  color: #212121;
  font-weight: 800;
`;

export const SubTitle = styled.div`
  display: flex;
  color: #c9c9c9;
  font-size: 20px;
  font-weight: 700;
`;

export const Nav = styled.nav`
  border-top: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
  background: #fff;
  width: 100%;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const NavWrapper = styled.div`
  display: flex;
`;

export const Link = styled.div`
  display: flex;
  padding: 0 10px;

  a {
    outline: none;
    text-decoration: none;
    color: ${({ isPath }) => (isPath ? '#212121' : '#c9c9c9')};
    padding-bottom: 4px;
    border-bottom: ${({ isPath }) => (isPath ? '2px solid #212121' : 'none')};
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 10px;
`;

export const UserInfo = styled.div`
  font-size: 16px;
  color: #212121;
  font-weight: 700;
`;
