// Skeleton.js
import React from 'react';
import * as S from './style';

const Skeleton = ({ isLoading }) => {
  return (
    <S.SkeletonWrapper>
      <S.ContentsLeftWrapper>
        <S.SkeletonProfileImage style={{ opacity: isLoading ? 1 : 0 }} />
        <S.SkeletonProductItemWrapper style={{ opacity: isLoading ? 1 : 0 }}>
          {Array.from({ length: 3 }).map((_, index) => (
            <S.SkeletonProductItem key={index}>
              <S.SkeletonProductImage />
              <S.SkeletonProductTitle />
              <S.SkeletonProductPrice />
            </S.SkeletonProductItem>
          ))}
        </S.SkeletonProductItemWrapper>
        <S.SkeletonSignImage style={{ opacity: isLoading ? 1 : 0 }} />
      </S.ContentsLeftWrapper>

      <S.SkeletonContentsRightWrapper>
        <S.SkeletonArtistNameWrapper>
          <S.SkeletonArtistNameTitle />
          <S.SkeletonArtistName style={{ opacity: isLoading ? 1 : 0 }} />
        </S.SkeletonArtistNameWrapper>
        <S.SkeletonArtistDescriptionWrapper>
          <S.SkeletonArtistDescriptionTitle />
          <S.SkeletonArtistDescription style={{ opacity: isLoading ? 1 : 0 }} />
        </S.SkeletonArtistDescriptionWrapper>
        <S.SkeletonArtistHistoryWrapper>
          <S.SkeletonArtistHistoryTitle />
          <S.SkeletonArtistHistory style={{ opacity: isLoading ? 1 : 0 }} />
        </S.SkeletonArtistHistoryWrapper>
      </S.SkeletonContentsRightWrapper>
    </S.SkeletonWrapper>
  );
};

export default Skeleton;
