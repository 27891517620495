import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorPage from './pages/error-page';
import { RecoilRoot } from 'recoil';
import MyArtWorkPage from './pages/myartwork';
import InviteArtistPage from './pages/invite-artist';
import SuccessPage from './pages/success-page';
import NotFoundPage from './pages/404';
import SignInPage from './pages/signin';
import PendingArtWorksPage from './pages/pending-artworks';
import ExcelUploadOrderPage from './pages/excel-upload-order';
import PurchaseArtsPage from './pages/purchase-arts';
import UploadPaymentPage from './pages/upload-payment';
import MainPage from './pages/main';
import ArtistPage from './pages/artist';
import ArtistProfilePage from './pages/artist-profile';
import DetailItemPage from './pages/item-detail';
import MypageButtonPage from './pages/mypage-button';
import AdminArtistPage from './pages/admin-artist';
import MyArtWorksPage from './pages/myArtworks';
import MyArtWorkEditPage from './pages/myArtworkEdit';
import SettlementCheckPage from './pages/settlementCheck';

const router = createBrowserRouter([
  {
    // 어드민 페이지의 메인 페이지
    path: '/',
    element: <MainPage />,
  },
  {
    // 온라인 작품 등록 페이지 - iframe 페이지 입니다
    path: '/myartwork',
    element: <MyArtWorkPage />,
  },
  {
    // 오프라인 작품 등록 페이지 - iframe 페이지 입니다
    path: '/myartwork/of',
    element: <MyArtWorkPage />,
  },
  {
    // 작가의 작품 리스트 페이지 - iframe 페이지 입니다.
    path: '/myartworkslist',
    element: <MyArtWorksPage />,
  },
  {
    // 작가의 작품 리스트 수정 페이지 - iframe 페이지 입니다.
    path: '/myartworkedit',
    element: <MyArtWorkEditPage />,
  },
  {
    // 작가 등록 페이지 - 메일로 전송됨 초대 링크로만 접근 가능한 페이지 입니다
    path: '/invite-artist',
    element: <InviteArtistPage />,
  },
  {
    // 작품 디테일 페이지 - iframe 페이지 입니다
    path: '/detail-item',
    element: <DetailItemPage />,
  },
  {
    // 어드민 페이지의 작품 등록 성공 페이지
    path: '/success',
    element: <SuccessPage />,
  },
  {
    // 어드민 페이지의 로그인 페이지
    path: '/sign-in',
    element: <SignInPage />,
  },
  {
    // 작가 리스트 페이지 - iframe 페이지 입니다
    path: '/artist',
    element: <ArtistPage />,
  },
  {
    // 작가 프로필 페이지 - iframe 페이지 입니다
    path: '/artist-profile',
    element: <ArtistProfilePage />,
  },
  {
    // 어드민 페이지의 등록 대기중인 작품 리스트 페이지
    path: '/pending-artworks',
    element: <PendingArtWorksPage />,
  },
  {
    // 어드민 페이지의 구매된 작품 목록 리스트 페이지
    path: '/purchase-arts',
    element: <PurchaseArtsPage />,
  },
  {
    // 어드민 페이지의 작가 등록 엑셀 업로드 페이지
    path: '/excel-upload-order',
    element: <ExcelUploadOrderPage />,
  },
  {
    // 어드민 페이지의 결제 승인 페이지
    path: '/upload-payment',
    element: <UploadPaymentPage />,
  },
  {
    // ArtMuse 마이페이지 버튼 페이지
    path: '/mypage-button',
    element: <MypageButtonPage />,
  },
  {
    // 관리자 작가 리스트 페이지
    path: '/admin-artist',
    element: <AdminArtistPage />,
  },
  {
    // 정산 금액 조회 페이지
    path: '/settlement-check',
    element: <SettlementCheckPage />,
  },
  {
    // 404 페이지
    path: '/404',
    element: <NotFoundPage />,
  },
  {
    // 에러 페이지
    path: '*',
    element: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RecoilRoot>
    <RouterProvider router={router} />
  </RecoilRoot>,
);

reportWebVitals();
