import styled from '@emotion/styled';

const TooltipContent = styled.div`
  display: none;
  position: absolute;
  left: -100px;
  z-index: 1;
  background-color: white;
  border: 1px solid rgba(33, 33, 33, 0.1);
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  @media (max-width: 500px) {
    left: -90px;
  }
`;

const Tooltip = ({ children }) => {
  return <TooltipContent>{children}</TooltipContent>;
};

export default Tooltip;
