// 작품 등록 페이지

import { useEffect, useState } from 'react';
import Input from '../common/Input';
import * as S from './style';
import useFetch from '../../hooks/useFetch';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const MyArtWork = () => {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isOffline = pathname === '/myartwork/of';
  const formDefaultValue = {
    country: '대한민국',
    materials: '디지털 판화',
    frameSize: '6호',
    editionCount: isOffline ? 0 : 365,
    orderNo: '',
  };

  const [preview, setPreview] = useState();
  const [formValue, setFormValue] = useState(formDefaultValue);
  const currentYear = new Date().getFullYear();
  const customerCode = searchParams.get('customerCode');

  useEffect(() => {
    if (!customerCode) navigate('/404');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { fetch: createArt, isLoading } = useFetch({
    url: '/artwork',
    method: 'post',
    onSuccess: () => handleInputCancel(),
  });

  const { fetch: createOfArt, isLoading: isOfLoading } = useFetch({
    url: '/artwork/of',
    method: 'post',
    onSuccess: () => handleInputCancel(),
  });

  const handleFileUpload = (file, key) => {
    if (!file) return;
    if (!file.type.startsWith('image/jpeg')) {
      window.alert('jpg형태 이미지만 업로드 해주세요.');
      return;
    }
    if (file.size >= 1024 ** 2 * 100) {
      alert('파일 사이즈가 너무 큽니다.');
      return;
    }

    const reader = new FileReader();
    setFormValue((prev) => ({ ...prev, [key]: file }));

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = function () {
        const width = img.width;
        const height = img.height;

        if (Math.max(width, height) < 4830) {
          alert(
            '이미지 사이즈가 너무 작습니다. \n인쇄 품질을 위해 가로 또는 세로가 4830px 이상의 이미지를 등록해주세요',
          );
        } else {
          setPreview(reader.result);
        }
      };
    };

    reader.readAsDataURL(file);
  };

  const readFile = (e) => {
    const key = e.target.id;
    const file = e.target.files[0];
    handleFileUpload(file, key);
  };

  const handleTextareaChange = (e) => {
    const key = e.target.id;
    let value = '';
    e.target.value.split('\n').forEach((line) => {
      value += line ? `<p>${line}</p><br/>` : '<br/>';
    });
    setFormValue((prev) => ({ ...prev, [key]: value }));
  };

  const handleInputValueChange = (e) => {
    const key = e.target.id;
    const value = e.target.value;

    setFormValue((prev) => ({ ...prev, [key]: value }));
  };

  const handleSetPrice = (e) => {
    const value = e.target.value;

    setFormValue((prev) => ({
      ...prev,
      originalPrice: value,
      price: value,
    }));
  };

  const handleCheckFloat = (value) => /^-?\d*\.?\d*$/.test(value);
  const handleCheckInt = (value) => /^\d*$/.test(value);
  const handleCheckStep = (value, step) =>
    ((value || '').split('.')[1]?.length || 0) <= step;
  const handleCheckMax = (value, max) => parseFloat(value) <= max;

  const handleCheckFloatInput = (value, { step, max }) => {
    if (value === '') return true;

    const isFloat = handleCheckFloat(value);
    const isStep = handleCheckStep(value, step);
    const isMax = handleCheckMax(value, max);

    return isFloat && isStep && isMax;
  };

  const handleCheckIntInput = (value, { max }) => {
    if (value === '') return true;

    const isInt = handleCheckInt(value);
    const isMax = handleCheckMax(value, max);

    return isInt && isMax;
  };

  //map->forEach 수정한 부분 테스트
  const onSubmit = (e) => {
    e.preventDefault();
    if (isLoading || isOfLoading) return;

    const { SizeX, SizeY, ...rest } = formValue;
    const size = `${SizeX}cm x ${SizeY}cm`;
    const formData = new FormData();

    Object.entries({
      size,
      customerCode,
      ...rest,
    }).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (isOffline) return createOfArt(formData);
    createArt(formData);
  };

  const handleInputCancel = () => {
    window.top.postMessage({ cancel: true }, 'https://artmuse.im');
  };

  return (
    <S.MyArtWorkWrapper>
      <S.FormWrapper>
        <S.Form onSubmit={onSubmit}>
          <S.InputStyle>
            <label>
              <span required>
                작품사진 (가로 또는 세로가 4830px 이상 이미지)
              </span>
              <S.FileUpload htmlFor="image">
                {preview ? (
                  <S.BackgroundImg bgImg={preview}></S.BackgroundImg>
                ) : (
                  '작품사진을 등록해주세요'
                )}
                <input
                  id="image"
                  type={'file'}
                  multiple
                  accept="image/*"
                  onChange={readFile}
                  required={!formValue.image}
                />
              </S.FileUpload>
            </label>
            <Input
              id="title"
              type={'text'}
              setValue={handleInputValueChange}
              label={'작품명'}
              placeholder={'작품 명을 입력해주세요'}
              maxLength={100}
              required
            />
            <Input
              id="createdYear"
              type={'text'}
              setValue={(e) => {
                if (
                  !handleCheckIntInput(e.target.value, {
                    max: currentYear,
                  })
                )
                  return;
                handleInputValueChange(e);
              }}
              value={formValue.createdYear || ''}
              label={'제작년도'}
              placeholder={'제작년도를 입력해주세요'}
              pattern="\d+(\.\d+)?"
              title="제작년도는 1000년 ~ 금년까지 입력 가능합니다."
              max={currentYear}
              min={1000}
              required
            />
            <label htmlFor="SizeX">
              <span required>원작 크기</span>
              <S.Size>
                <Input
                  id="SizeX"
                  type={'text'}
                  setValue={(e) => {
                    if (
                      !handleCheckFloatInput(e.target.value, {
                        step: 1,
                        max: 5000,
                      })
                    )
                      return;
                    handleInputValueChange(e);
                  }}
                  fixedDescription="cm"
                  placeholder={'가로'}
                  pattern="^(?!0+(\.0+)?$)\d+(\.\d+)?$"
                  value={formValue.SizeX || ''}
                  required
                />
                <Input
                  id="SizeY"
                  type={'text'}
                  setValue={(e) => {
                    if (
                      !handleCheckFloatInput(e.target.value, {
                        step: 1,
                        max: 5000,
                      })
                    )
                      return;
                    handleInputValueChange(e);
                  }}
                  fixedDescription="cm"
                  placeholder={'세로'}
                  pattern="^(?!0+(\.0+)?$)\d+(\.\d+)?$"
                  value={formValue.SizeY || ''}
                  required
                />
              </S.Size>
            </label>
            <label>
              <span required>
                판화 크기 (비율에 따라 실제 사이즈가 달라질 수 있습니다)
              </span>
              <select
                id="frameSize"
                defaultValue={'6호'}
                required
                onChange={handleInputValueChange}
              >
                <option value="">사이즈</option>
                <option value={'6호'}>6호</option>
              </select>
            </label>
            <label>
              <span id="country" required>
                국가
              </span>
              <select
                id="country"
                required
                defaultValue={'대한민국'}
                onChange={handleInputValueChange}
              >
                <option value="">국가 선택</option>
                <option value={'대한민국'}>대한민국</option>
              </select>
            </label>
            <Input
              id="editionCount"
              type={'text'}
              setValue={(e) => {
                if (
                  !handleCheckIntInput(e.target.value, {
                    max: 365,
                  })
                )
                  return;
                handleInputValueChange(e);
              }}
              label={'작품 판화 수량'}
              placeholder={'작품 판화 수량을 입력해주세요'}
              pattern="^(?!0+(\.0+)?$)\d+(\.\d+)?$"
              value={formValue.editionCount || ''}
              readOnly={!isOffline}
              required
            />
            <Input
              id="originalPrice"
              type={'text'}
              setValue={(e) => {
                if (
                  !handleCheckIntInput(e.target.value, {
                    max: 999999999,
                  })
                )
                  return;
                handleSetPrice(e);
              }}
              label={'작품 원작 가격'}
              pattern="^(?!0+(\.0+)?$)\d+(\.\d+)?$"
              placeholder={'작품 원작 가격을 입력해주세요'}
              value={formValue.originalPrice || ''}
              required
              isPrice
            />
            <label>
              <span required>작품 설명</span>
              <textarea
                id="description"
                onChange={handleTextareaChange}
                maxLength={500}
                required
              ></textarea>
            </label>
            <input
              type="submit"
              value={isLoading || isOfLoading ? '작품 등록중...' : '작품 등록'}
            />
          </S.InputStyle>
          <S.CancelBtn onClick={handleInputCancel}>작품 등록 취소</S.CancelBtn>
        </S.Form>
      </S.FormWrapper>
    </S.MyArtWorkWrapper>
  );
};

export default MyArtWork;
