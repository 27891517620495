import styled from '@emotion/styled';

const TableWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  border: 1px solid rgba(33, 33, 33, 0.1);
  overflow: hidden;

  > table {
    table-layout: fixed;
    width: 500px;
    @media (max-width: 500px) {
      width: 300px;
      font-size: 12px;
    }
  }

  th {
    text-align: center;
    word-break: break-all;
    font-weight: 600;
    background-color: #dddfe3;
    border: 0.5px solid #f1f2f4;
    &.real-price {
      width: 50%; /* 실물 가격 컬럼 너비 */
    }
    &.print-price {
      width: 25%; /* 판화 가격 컬럼 너비 */
    }
    &.selling-price {
      width: 25%; /* 상품 가격 컬럼 너비 */
    }
  }

  tr {
    text-align: center;
    height: 40px;
  }

  td {
    border: 0.5px solid #f1f2f4;
    font-size: 11px;
    &.real-price {
      width: 50%; /* 실물 가격 컬럼 너비 */
    }
    &.print-price {
      width: 25%; /* 판화 가격 컬럼 너비 */
    }
    &.selling-price {
      width: 25%; /* 상품 가격 컬럼 너비 */
    }
    @media (max-width: 500px) {
      font-size: 10px;
    }
  }

  .frame-price {
    text-align: center;
    font-weight: bold;
    padding: 10px;
    border-top: 1px solid #f1f2f4;
  }
`;

const PriceTable = () => {
  return (
    <TableWrapper>
      <table cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            <th className="real-price">실물 가격</th>
            <th className="print-price">판화 가격</th>
            <th className="selling-price">상품 가격</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="real-price">5,000,000 원 미만</td>
            <td className="print-price">40,000 원</td>
            <td className="selling-price">137,000 원</td>
          </tr>
          <tr>
            <td className="real-price">
              5,000,000 원 이상 ~ 20,000,000 원 미만
            </td>
            <td className="print-price">60,000 원</td>
            <td className="selling-price">177,000 원</td>
          </tr>
          <tr>
            <td className="real-price">
              20,000,000 원 이상 ~ 100,000,000 원 미만
            </td>
            <td className="print-price">100,000 원</td>
            <td className="selling-price">257,000 원</td>
          </tr>
          <tr>
            <td className="real-price">
              100,000,000 원 이상 ~ 1,000,000,000 원 미만
            </td>
            <td className="print-price">300,000 원</td>
            <td className="selling-price">657,000 원</td>
          </tr>
          <tr>
            <td className="real-price">1,000,000,000 원 이상</td>
            <td className="print-price">500,000 원</td>
            <td className="selling-price">1,057,000 원</td>
          </tr>
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default PriceTable;
