import { useEffect, useState } from 'react';
import ArtistProfile from '../components/ArtistProfile';
import TokenManager from '../api/TokenManger';
import MainHeader from '../components/common/MainHeader';
import styled from '@emotion/styled';

const PageWrapper = styled.div`
  padding-top: ${({ headerHeight }) => headerHeight}px;
`;

const ArtistProfilePage = () => {
  const [hasToken, setHasToken] = useState(false);
  const headerHeight = 176; // 헤더의 높이를 적절히 조정해주세요.

  useEffect(() => {
    const tokenManager = new TokenManager();
    setHasToken(
      tokenManager.checkAccessExpToken() && !!tokenManager.accessToken,
    );
  }, []);

  return (
    <>
      {hasToken && <MainHeader />}
      {hasToken ? (
        <PageWrapper headerHeight={headerHeight}>
          <ArtistProfile hasToken={hasToken} />
        </PageWrapper>
      ) : (
        <ArtistProfile hasToken={hasToken} />
      )}
    </>
  );
};

export default ArtistProfilePage;
