import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import { PendingModePerStatus } from '../../Lib/PendingModePerStatus';
import * as S from './style';
import Dropdown from '../common/Dropdown';

const PendingMode = {
  OFFLINE: '오프라인',
  ONLINE: '온라인',
};

const ListName = {
  title: '제목',
  status: '상태',
  artist: '작가',
  maxMinting: '에디션 번호',
  createdDate: '등록년도',
};

const PendingStatus = {
  PENDING: '작품승인 대기중',
  FIRST_ONHOLD: '1차 승인 진행중',
  FIRST_APPROVE: '1차 승인 완료',
  SECOND_ONHOLD: '2차 승인 진행중',
  DONE: '최종 완료',
  CANCEL: '승인 취소',
  UPDATE_ONHOLD: '수정 대기 중', // 추가
};

const MyArtWorksList = () => {
  const [pendingStatus, setPendingStatus] = useState('ALL');
  const [myArtworks, setMyArtworks] = useState([]);
  const [pendingMode, setPendingMode] = useState('ONLINE');
  const [searchParams] = useSearchParams();
  const customerCode = searchParams.get('customerCode');
  const navigate = useNavigate();

  useEffect(() => {
    if (!customerCode) navigate('/404');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerCode]);

  const { fetch: getMyArtWorks } = useFetch({
    url: `/artwork/my?status=${pendingStatus}&mode=${pendingMode}&customerCode=${customerCode}`,
    method: 'get',
    onSuccess: (e) => setMyArtworks(e),
  });

  const handleEditArtwork = (id) => {
    window.top.postMessage(
      {
        works: `https://artmuse.im/edit-artwork?artistCode=${customerCode}&artworkId=${id}`,
      },
      'https://artmuse.im',
    );
  };

  const handleSetPendingStatus = (value) => {
    setPendingStatus(value);
  };

  const handleSetPendingMode = (value) => {
    setPendingStatus('ALL');
    setPendingMode(value);
  };

  useEffect(() => {
    if (!pendingStatus) return;
    getMyArtWorks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingStatus, pendingMode]);

  return (
    <S.PendingArtWorksWrapper>
      <div>
        <S.ListWrapper>
          <S.TableHeadTap>
            <h1>{PendingModePerStatus[pendingMode][pendingStatus]}</h1>
            <div>
              <Dropdown
                options={Object.keys(PendingMode)}
                selectedValue={pendingMode}
                onOptionChange={handleSetPendingMode}
                renderItem={(mode) => PendingMode[mode]}
                minWidth="120px"
                height="32px"
              />
              <Dropdown
                options={Object.keys(PendingModePerStatus[pendingMode])}
                selectedValue={pendingStatus}
                onOptionChange={handleSetPendingStatus}
                renderItem={(status) =>
                  PendingModePerStatus[pendingMode][status]
                }
                minWidth="120px"
                height="32px"
              />
            </div>
          </S.TableHeadTap>
          <S.TableStyle>
            <table cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th>제목</th>
                  <th>작가</th>
                  <th>에디션 번호</th>
                  <th>등록 날짜</th>
                  <th>상태</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(myArtworks) && myArtworks.length > 0 ? (
                  myArtworks.map((e) => {
                    const { id, status, ...rest } = e;
                    return (
                      <tr key={id} onClick={() => handleEditArtwork(id)}>
                        {rest &&
                          Object.entries({ ...rest }).map(([key, value]) => (
                            <td key={key}>
                              <div>
                                <strong>{ListName[key]}</strong>
                              </div>
                              <div>{value}</div>
                            </td>
                          ))}
                        <td>
                          <div>
                            <strong>{ListName.status}</strong>
                          </div>
                          <div>
                            <S.PendingState status={status}>
                              {PendingStatus[status]}
                            </S.PendingState>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5">해당 하는 작품 정보가 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </S.TableStyle>
        </S.ListWrapper>
      </div>
    </S.PendingArtWorksWrapper>
  );
};

export default MyArtWorksList;
