import { useEffect } from 'react';
import MyArtWorksList from '../components/MyArtWorkList';

const MyArtWorksPage = () => {
  useEffect(() => {
    const handleMessage = () => {
      const message = { height: document.body.scrollHeight + 500 };
      window.top.postMessage(message, 'https://artmuse.im');
    };

    if (document.readyState === 'complete') {
      handleMessage();
    } else {
      window.addEventListener('load', handleMessage);
      return () => document.removeEventListener('load', handleMessage);
    }
  }, []);
  return <MyArtWorksList />;
};

export default MyArtWorksPage;
