import { isAxiosError } from 'axios';
import { useState, useCallback } from 'react';
import API from '../api';

const useFetch = ({
  url,
  method,
  params,
  onSuccess,
  onFailure,
  onFinally,
  successMessage,
  errorMessage,
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSuccess = useCallback(
    (data) => {
      setData(data);
      if (successMessage) {
        console.log(successMessage);
      }
      if (onSuccess) {
        onSuccess(data);
      }
    },
    [onSuccess, successMessage],
  );

  const handleError = useCallback(
    (error) => {
      setError(error);
      if (isAxiosError(error)) {
        if (error.response) {
          if (error.response.status >= 500) {
            console.log('서버 에러');
          } else if (
            typeof errorMessage === 'object' &&
            errorMessage?.[error.response?.status]
          ) {
            console.log(errorMessage[error.response.status]);
          }
        } else {
          console.log('네트워크 에러');
        }
      } else {
        console.log('알 수 없는 에러');
      }
      if (onFailure) {
        onFailure(error);
      }
    },
    [errorMessage, onFailure],
  );

  const fetch = useCallback(
    async (body, fetchParams) => {
      setIsLoading(true);
      setError(null);

      try {
        const { data } = await API({
          url: url,
          method: method,
          params: fetchParams || params,
          data: body,
        });
        handleSuccess(data);
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
      if (onFinally) {
        onFinally();
      }
    },
    [url, method, params, handleSuccess, handleError, onFinally],
  );

  return { data, isLoading, fetch, error };
};

export default useFetch;
