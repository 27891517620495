import styled from '@emotion/styled';

export const SignInWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Form = styled.form`
  width: 330px;
  @media (max-width: 330px) {
    width: 100%;
  }
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: 200;
  color: #000000;
  margin-bottom: 20px;
`;

export const InputStyle = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  label {
    width: 100%;
  }

  input {
    width: 100%;
    height: 45px;
    border: 1px solid rgba(33, 33, 33, 0.1);
    border-radius: 8px;
    color: #212121;
    font-size: 15px;
    box-sizing: border-box;
    padding: 0 15px;
    ::placeholder {
      color: #919191;
    }
    outline: none;
  }

  input[type='submit'] {
    color: #fff;
    background: #000;
    font-size: 20px;
    font-weight: 600;

    :hover {
      background: #d3d3d3;
      color: #212121;
    }
  }
`;
