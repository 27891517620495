const accessToken = 'accessToken';
const accessExp = 'accessExp';
const role = 'role';

class TokenManager {
  constructor() {
    this.initToken();
  }

  initToken() {
    if (typeof window === 'undefined') return;
    this._accessToken = localStorage.getItem(accessToken);
    this._accessExp = localStorage.getItem(accessExp);
    this._role = localStorage.getItem(role);
  }

  setToken(tokens) {
    this._accessToken = tokens.accessToken;
    this._accessExp = tokens.accessExp;
    this._role = tokens.role;

    localStorage.setItem(accessToken, tokens.accessToken);
    localStorage.setItem(accessExp, tokens.accessExp);
    localStorage.setItem(role, tokens.role);
  }

  deleteToken() {
    localStorage.removeItem(accessToken);
    localStorage.removeItem(accessExp);
    localStorage.removeItem(role);
  }

  checkAccessExpToken() {
    if (!this.accessExp) return;
    return new Date(this.accessExp).getTime() - new Date().getTime() >= 30000;
  }

  getEmailPrefix() {
    if (!this.accessToken) return '';
    const payload = JSON.parse(atob(this.accessToken.split('.')[1]));
    const email = payload.sub;
    return email.split('@')[0];
  }

  get accessToken() {
    return this._accessToken;
  }

  get accessExp() {
    return this._accessExp;
  }

  get role() {
    return this._role;
  }
}

export default TokenManager;
