import { ko } from 'date-fns/locale';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CustomDateRangePicker.css';

import {
  StyledDatePickerContainer,
  StyledDatePickerButtons,
  StyledApplyButton,
  StyledCancelButton,
} from './CustomDateRangePicker.styles';

const CustomDateRangePicker = ({
  startDate,
  endDate,
  onChange,
  onCancel,
  onConfirm,
  selectsRange,
  disabled,
}) => {
  const handleDateChange = (dates) => {
    if (selectsRange) {
      onChange(dates);
    } else {
      onChange(dates[0], dates[1]);
    }
  };

  return (
    <StyledDatePickerContainer>
      <DatePicker
        selected={startDate}
        onChange={handleDateChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange={selectsRange}
        inline
        monthsShown={2}
        locale={ko}
        disabled={disabled}
      />
      <StyledDatePickerButtons>
        <StyledCancelButton onClick={onCancel} disabled={disabled}>
          닫기
        </StyledCancelButton>
        <StyledApplyButton onClick={onConfirm} disabled={disabled}>
          적용하기
        </StyledApplyButton>
      </StyledDatePickerButtons>
    </StyledDatePickerContainer>
  );
};

export default CustomDateRangePicker;
