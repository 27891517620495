import { useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import * as S from './style';
import {
  PiMagnifyingGlass,
  PiCaretUp,
  PiCaretDown,
  PiWarningCircle,
} from 'react-icons/pi';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import Dropdown from '../Dropdown';
import CustomButton from '../Button';

/**
 * 커스텀 공통 테이블 컴포넌트
 *
 * @component
 * @name CustomTable
 * @description 재사용 가능한 커스텀 테이블 컴포넌트로, 데이터를 테이블 형태로 표시하고 검색, 페이지네이션, 정렬, 행 선택 등의 기능을 제공합니다.
 *
 * @param {object} props - 컴포넌트 props
 * @param {string} props.title - 테이블 제목
 * @param {array} props.columns - 테이블 열 정의 배열
 * @param {array} props.data - 테이블 데이터 배열
 * @param {string} props.searchPlaceholder - 검색 입력 필드의 placeholder 텍스트
 * @param {string} props.rowNames - 행 이름 텍스트
 * @param {array} props.pageSizeOptions - 페이지 크기 옵션 배열
 * @param {function} props.onPageSizeChange - 페이지 크기 변경 시 호출되는 콜백 함수
 * @param {function} props.onSelectedRowsAction - 선택된 행에 대한 작업 수행 시 호출되는 콜백 함수
 * @param {object} props.buttonProps - 테이블 우측 상단에 표시되는 버튼의 props
 * @param {array} [props.columnWidths=[]] - 열 너비 정의 배열
 * @param {string} [props.tableWidth='100%'] - 테이블 전체 너비
 * @param {array} [props.initialSortBy=[]] - 초기 정렬 상태 정의 배열
 * @param {function} [props.onRowClick] - 행 클릭 시 호출되는 콜백 함수
 *
 * @returns {JSX.Element} 커스텀 공통 테이블 컴포넌트
 *
 * @example
 * const columns = [
 *   {
 *     header: '컬럼 1',
 *     accessorKey: 'column1',
 *     enableSorting: true,
 *   },
 *   {
 *     header: '컬럼 2',
 *     accessorKey: 'column2',
 *     enableSorting: false,
 *   },
 * ];
 *
 * const data = [
 *   { id: 1, column1: 'value1', column2: 'value2' },
 *   { id: 2, column1: 'value3', column2: 'value4' },
 * ];
 *
 * const handleRowClick = (rowData) => {
 *   console.log('Clicked row:', rowData);
 * };
 *
 * <CustomTable
 *   title="테이블 제목"
 *   columns={columns}
 *   data={data}
 *   searchPlaceholder="검색어를 입력하세요"
 *   rowNames="데이터"
 *   pageSizeOptions={[10, 20, 50]}
 *   onPageSizeChange={handlePageSizeChange}
 *   onSelectedRowsAction={handleSelectedRowsAction}
 *   buttonProps={{ text: '선택한 행 다운로드' }}
 *   columnWidths={[{ id: 'column1', width: '50%' }, { id: 'column2', width: '50%' }]}
 *   onRowClick={handleRowClick}
 * />
 *
 * @see {@link https://tanstack.com/table/v8/docs/api/overview|TanStack Table Documentation}
 * @see {@link https://www.npmjs.com/package/@tanstack/react-table|@tanstack/react-table}
 *
 * @author 김태웅
 * @version 1.0.0
 * @since 2023-06-05
 */
const CustomTable = ({
  title,
  columns,
  data,
  searchPlaceholder,
  rowNames,
  pageSizeOptions,
  onPageSizeChange,
  buttonProps = [],
  columnWidths = [],
  tableWidth = '100%',
  initialSortBy = [],
  onRowClick,
  showSearchField,
}) => {
  const [rowSelection, setRowSelection] = useState({});
  const [isFocused, setIsFocused] = useState(false);

  const getSelectedRowsData = () => {
    const selectedRowsData = table
      .getSelectedRowModel()
      .flatRows.map((row) => row.original);
    return selectedRowsData;
  };

  const handlePageSizeChange = (pageSize) => {
    table.setPageSize(pageSize);
    onPageSizeChange(pageSize);
  };

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: pageSizeOptions[0],
      },
      sorting: initialSortBy,
    },
    state: {
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel({ onlyVisibleColumns: true }),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  return (
    <S.TableWrapper>
      {title && <S.TableTitle>{title}</S.TableTitle>}

      <S.TableHeaderWrapper style={{ width: tableWidth }}>
        <S.TableHeaderBoxFirst>
          <S.RowNames>
            {rowNames} {table.getRowCount().toLocaleString()}{' '}
          </S.RowNames>
        </S.TableHeaderBoxFirst>
        <S.TableHeaderBoxSecond>
          {showSearchField && ( // 검색 필드 표시 여부에 따라 조건부 렌더링
            <S.SearchInputWrapper isFocused={isFocused}>
              <PiMagnifyingGlass size={20} />
              <S.SearchInput
                type="text"
                value={table.getState().globalFilter || ''}
                onChange={(e) => table.setGlobalFilter(e.target.value)}
                placeholder={searchPlaceholder}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />
            </S.SearchInputWrapper>
          )}
          <Dropdown
            options={pageSizeOptions}
            selectedValue={table.getState().pagination.pageSize}
            onOptionChange={handlePageSizeChange}
            renderItem={(option) => `${option}개씩 보기`}
            minWidth="120px"
            height="32px"
            fontSize="13px"
          />
          <S.BtnWrapper>
            {buttonProps.map((btnProps, index) => (
              <CustomButton
                key={index}
                {...btnProps}
                onClick={() => btnProps.onClick(getSelectedRowsData())}
              />
            ))}
          </S.BtnWrapper>
        </S.TableHeaderBoxSecond>
      </S.TableHeaderWrapper>

      <table style={{ width: tableWidth }}>
        {columnWidths.length > 0 && (
          <colgroup>
            {columnWidths.map((columnWidth) => (
              <col key={columnWidth.id} style={{ width: columnWidth.width }} />
            ))}
          </colgroup>
        )}
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <div
                      {...{
                        onClick: header.column.getToggleSortingHandler(),
                        style: {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent:
                            header.id === 'select'
                              ? 'center'
                              : header.column.columnDef.align === 'right'
                              ? 'flex-end'
                              : header.column.columnDef.align === 'center'
                              ? 'center'
                              : 'flex-start',
                          cursor: header.column.getCanSort()
                            ? 'pointer'
                            : 'default',
                        },
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {header.column.getCanSort() && (
                        <span style={{ marginLeft: '4px' }}>
                          {header.column.getIsSorted() === 'desc' ? (
                            <PiCaretDown size={'12px'} />
                          ) : header.column.getIsSorted() === 'asc' ? (
                            <PiCaretUp size={'12px'} />
                          ) : (
                            <PiCaretDown
                              style={{ opacity: 0.3 }}
                              size={'12px'}
                            />
                          )}
                        </span>
                      )}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.length === 0 ? (
            <tr>
              <td
                colSpan={table.getAllColumns().length}
                style={{ textAlign: 'center', padding: '20px' }}
              >
                <div
                  style={{
                    display: 'flex',
                    height: '200px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <PiWarningCircle
                    size={50}
                    style={{ marginRight: '8px', color: '#9a9a9a' }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      fontSize: '20px',
                      fontWeight: '700',
                      margin: '20px',
                      color: '#9a9a9a',
                    }}
                  >
                    조건과 일치하는 정보가 없습니다
                  </div>
                </div>
              </td>
            </tr>
          ) : (
            table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                style={{
                  cursor: onRowClick ? 'pointer' : 'default',
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    onClick={(e) => {
                      if (cell.column.id !== 'select' && onRowClick) {
                        onRowClick(row.original);
                      }
                    }}
                    style={{ textAlign: cell.column.columnDef.align }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>

      <S.PaginationWrapper>
        {table.getRowModel().rows.length > 0 && (
          <>
            <S.PageButton
              onClick={() =>
                table.setPageIndex(table.getState().pagination.pageIndex - 1)
              }
              disabled={!table.getCanPreviousPage()}
            >
              <MdChevronLeft size={16} />
            </S.PageButton>
            {Array.from({ length: table.getPageCount() }, (_, index) => (
              <S.PageNumberButton
                key={index}
                onClick={() => table.setPageIndex(index)}
                className={
                  table.getState().pagination.pageIndex === index
                    ? 'active'
                    : ''
                }
              >
                {index + 1}
              </S.PageNumberButton>
            ))}
            <S.PageButton
              onClick={() =>
                table.setPageIndex(table.getState().pagination.pageIndex + 1)
              }
              disabled={!table.getCanNextPage()}
            >
              <MdChevronRight size={16} />
            </S.PageButton>
          </>
        )}
      </S.PaginationWrapper>
    </S.TableWrapper>
  );
};

export default CustomTable;
