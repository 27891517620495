import { useEffect, useState } from 'react';
import useFetch from '../../hooks/useFetch';
import * as S from './style';
import TokenManager from '../../api/TokenManger';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../common/CustomTable/index';

// 좋아요 버튼, 주문번호, 구매자, 상품명, 에디션 번호,날짜, 다운로드
const PurchaseArts = () => {
  const [orderList, setOrderList] = useState([]);
  const [size, setSize] = useState(10);

  const navigate = useNavigate();
  const { fetch: getOrderList } = useFetch({
    url: '/order',
    method: 'get',
    onSuccess: (e) => {
      setOrderList(e);
    },
    onFailure: (e) => {
      if (e.response?.status === 401) {
        const tokenManager = new TokenManager();
        tokenManager.deleteToken();
        return navigate('/sign-in');
      }
    },
  });

  const { fetch: downLoadCheck } = useFetch({
    url: '/order/check',
    method: 'patch',
    onSuccess: () => getOrderList(),
    onFailure: (e) => {
      if (e.response?.status === 401) {
        const tokenManager = new TokenManager();
        tokenManager.deleteToken();
        navigate('/sign-in');
      }
    },
  });

  const handleDownLoadCheck = (orderNo) => downLoadCheck({ orderId: orderNo });

  const handlePageSizeChange = (newPageSize) => {
    setSize(newPageSize);
  };

  useEffect(() => {
    getOrderList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      header: '상품명',
      accessorKey: 'artworkName',
      enableSorting: false,
      align: 'center',
    },
    {
      header: '구매자',
      accessorKey: 'name',
      enableSorting: false,
      align: 'center',
    },
    {
      header: '에디션 번호',
      accessorKey: 'editionNo',
      enableSorting: false,
      align: 'center',
    },
    { header: '주문 시간', accessorKey: 'orderDate', align: 'center' },
    {
      header: 'NFT 발행 상태',
      accessorKey: 'isCheck',
      enableSorting: false, // 정렬 비활성화
      align: 'center',
      cell: ({ row }) => (
        <span>{row.original.downloadUrl ? '발행 완료' : '미발행'}</span>
      ),
    },
    {
      header: '주문번호',
      accessorKey: 'orderNo',
      enableSorting: false, // 정렬 비활성화
      align: 'center',
      cell: ({ row }) => (
        <S.CopyWrapper
          onClick={() => navigator.clipboard.writeText(row.original.orderNo)}
        >
          복사하기
        </S.CopyWrapper>
      ),
    },
    {
      header: '다운로드',
      accessorKey: 'downloadUrl',
      enableSorting: false, // 정렬 비활성화
      align: 'center',
      cell: ({ row }) => (
        <S.DownLoadBtn
          isDownLoasd={!!row.original.downloadUrl}
          onClick={(e) => {
            e.stopPropagation();
            if (!row.original.downloadUrl) return;
            window.location.href = row.original.downloadUrl;
          }}
        >
          {row.original.downloadUrl ? '다운로드' : '--'}
        </S.DownLoadBtn>
      ),
    },
  ];

  return (
    <S.PurchaseArtsWrapper>
      <div>
        <S.Title>판매 목록</S.Title>
        <CustomTable
          columns={columns}
          data={orderList}
          rowNames="상품"
          pageSizeOptions={[10, 20, 50]}
          onPageSizeChange={handlePageSizeChange}
          tableWidth="1000px"
        />
      </div>
    </S.PurchaseArtsWrapper>
  );
};

export default PurchaseArts;
