import React from 'react';
import styled from '@emotion/styled';

const CardContainer = styled.div`
  width: 160px;
  height: 70px;
  padding: 8px 24px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const CardTitle = styled.div`
  text-align: center;
  color: #686b73;
  font-size: 11px;
  font-family: 'Pretendard';
  font-weight: 500;
  line-height: 14.3px;
  word-wrap: break-word;
`;

const CardValue = styled.div`
  text-align: center;
  color: black;
  font-size: 16px;
  font-family: 'Pretendard';
  font-weight: 500;
  line-height: 20.8px;
  word-wrap: break-word;
`;

const BasicInfoCard = ({ backgroundColor, title, value }) => {
  return (
    <CardContainer backgroundColor={backgroundColor}>
      <CardTitle>{title}</CardTitle>
      <CardValue>{value} 원</CardValue>
    </CardContainer>
  );
};

export default BasicInfoCard;
