import { useEffect, useState } from 'react';
import * as S from './style';
import useFetch from '../../hooks/useFetch';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import Dropdown from '../common/Dropdown';

const ExcelUploadOrder = () => {
  const previewNumber = 5;
  const navigate = useNavigate();
  const [file, setFile] = useState('');
  const [excel, setExcel] = useState();
  const [artistList, setArtistList] = useState([]);
  const [selectedGeneralManager, setSelectedGeneralManager] = useState({});
  const [generalManagerList, setGeneralManagerList] = useState([]);
  const [inviteCode, setInviteCode] = useState('');
  const [previewList, setPreviewList] = useState([]);
  const [navigationNumber, setNavigationNumber] = useState({
    current: 1,
    max: 0,
  });
  const downloadUrl = process.env.REACT_APP_DOWNLOAD_URL_ARTIST_FORM_XLSX;

  const readExcelFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const readedData = XLSX.read(data, { type: 'array' });
        const sheetName = readedData.SheetNames[0];
        const sheetData = readedData.Sheets[sheetName];
        const dataParse = XLSX.utils.sheet_to_json(sheetData);
        resolve(dataParse);
      };
      reader.onerror = () => {
        reject(new Error('파일 읽기 중 오류가 발생했습니다.'));
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const handleReadExcel = async (e) => {
    e.preventDefault();
    const files = e.target.files[0];
    if (!files) return;
    setExcel(files);

    try {
      const dataParse = await readExcelFile(files);
      const newSheetData = [];

      for (let i = 1; i < dataParse.length; i++) {
        if (dataParse[i]['이름'] === '홍OO') continue;

        newSheetData.push({
          artistName: dataParse[i]['이름'],
          email: dataParse[i]['이메일'],
        });
      }
      if (newSheetData.length === 0) return alert('저장된 작가가 없습니다.');
      setFile(files.name);
      setArtistList(newSheetData);
      handleSetPreviewList();
      setNavigationNumber({
        current: 1,
        max: Math.ceil(newSheetData.length / previewNumber),
      });
    } catch (error) {
      console.error('엑셀 파일 읽기 에러:', error);
    }
  };

  const handleFailure = (e) => {
    if (e.response?.status === 401) {
      navigate('/sign-in');
    }
  };

  const { fetch: uploadExcel, isLoading } = useFetch({
    url: '/excel/file',
    method: 'post',
    onSuccess: (e) => {
      const excelFileUrl = e.fileUrl;
      uploadArtistsList({ artistList, excelFileUrl, inviteCode });
    },
    onFailure: handleFailure,
  });

  const { fetch: uploadArtistsList } = useFetch({
    url: '/excel',
    method: 'post',
    onSuccess: () => {
      setFile('');
      setArtistList([]);
    },
    onFailure: handleFailure,
  });

  const { fetch: getGeneralManagerList } = useFetch({
    url: '/user/invite-code',
    method: 'get',
    onSuccess: (e) => setGeneralManagerList(e.list),
    onFailure: handleFailure,
  });

  const onSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file', excel);

    uploadExcel(formData);
  };

  const handleGetInviteCode = (value) => {
    setSelectedGeneralManager(value);
    setInviteCode(value.inviteCode);
  };

  const handleNavigationNext = () => {
    const { max, current } = navigationNumber;
    if (!max) return;
    if (current >= max) return;

    setNavigationNumber((prev) => ({
      ...prev,
      current: ++prev.current,
    }));
  };

  const handleNavigationUndo = () => {
    const { max, current } = navigationNumber;
    if (!max) return;
    if (current === 1) return;

    setNavigationNumber((prev) => ({
      ...prev,
      current: --prev.current,
    }));
  };

  const handleSetPreviewList = () => {
    const { current } = navigationNumber;
    const newList = artistList.filter(
      (_, index) =>
        index >= (current - 1) * previewNumber &&
        index < current * previewNumber,
    );

    setPreviewList(newList);
  };

  useEffect(() => {
    handleSetPreviewList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationNumber]);

  useEffect(() => {
    getGeneralManagerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.ExcelUploadOrderWrapper>
      <S.FormWrapper>
        <S.Title>작가 초대</S.Title>
        <S.Description>
          작가 초대 시, 하단 양식 파일을 다운로드 받아 작성 후 엑셀 파일을
          <br />
          업로드 해주세요.
        </S.Description>
        <S.Form onSubmit={onSubmit}>
          <S.ExcelSampleDownLoad>
            <S.ExcelSampleDownLoadBtn
              onClick={() => {
                if (!downloadUrl) return;
                window.location.href = downloadUrl;
              }}
            >
              양식 다운로드
            </S.ExcelSampleDownLoadBtn>
          </S.ExcelSampleDownLoad>
          <S.InputStyle>
            <label htmlFor="general-manager-dropdown">
              <span required>위원장</span>
              <Dropdown
                options={generalManagerList}
                selectedValue={selectedGeneralManager}
                onOptionChange={handleGetInviteCode}
                renderItem={(option) => option.name || '위원장 선택'}
                minWidth="100%"
                height="45px"
                fontSize="15px"
              />
            </label>
            <label>
              <span required>작가 등록 엑셀 파일</span>
              <S.FileUpload htmlFor="signImg" isFile={!!file}>
                <div>{file || '등록된 엑셀 파일이 없습니다'}</div>
                <input
                  id="orderExcel"
                  type="file"
                  accept="xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={handleReadExcel}
                  required={!file}
                />
              </S.FileUpload>
            </label>
            {!!artistList.length && (
              <>
                <S.TableWrapper>
                  <table cellSpacing="0" cellPadding="0">
                    <caption>
                      엑셀 미리보기 - 작가 : {artistList.length} 명
                    </caption>
                    <thead>
                      <tr>
                        <th>이메일</th>
                        <th>이름</th>
                      </tr>
                    </thead>
                    <tbody>
                      {previewList.map(({ email, artistName }) => {
                        return (
                          <tr key={email}>
                            <td>{email}</td>
                            <td>{artistName}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </S.TableWrapper>
                <S.NavigationBar>
                  <S.NavigationBtn id="undo" onClick={handleNavigationUndo}>
                    {'<'}
                  </S.NavigationBtn>
                  <span>{`${navigationNumber.current} / ${navigationNumber.max}`}</span>
                  <S.NavigationBtn id="next" onClick={handleNavigationNext}>
                    {'>'}
                  </S.NavigationBtn>
                </S.NavigationBar>
                <input
                  type="submit"
                  value={
                    isLoading ? '메일 전송중...' : '작가 등록 이메일 보내기'
                  }
                />
              </>
            )}
          </S.InputStyle>
        </S.Form>
      </S.FormWrapper>
    </S.ExcelUploadOrderWrapper>
  );
};

export default ExcelUploadOrder;
