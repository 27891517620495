import styled from '@emotion/styled';

export const PurchaseArtsWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  > div {
    width: 900px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }
`;

export const Title = styled.p`
  text-align: center;
  font-weight: 700;
  color: #1e2024;
  font-size: 30px;
`;

export const TableStyle = styled.div`
  margin-top: 30px;

  table {
    table-layout: fixed;
    width: 100%;
    box-shadow: 0px 0px 3px rgba(55, 55, 55, 0.2);
    border-radius: 3px;
    overflow: hidden;
  }

  tr,
  th {
    :nth-of-type(1) {
      width: 5%;
    }
    :nth-of-type(2) {
      width: 10%;
    }
    :nth-of-type(3) {
      width: 8%;
    }
    :nth-of-type(4) {
      width: 8%;
    }
    :nth-of-type(5) {
      width: 15%;
    }
    :nth-of-type(6) {
      width: 8%;
    }
    :nth-of-type(7) {
      width: 8%;
    }
  }

  th {
    color: rgba(55, 55, 55, 0.6);
    padding: 20px 15px;
    word-break: break-all;
    text-align: start;
    font-weight: 400;
  }

  td {
    height: 50px;
    padding: 0 15px;
    border-top: 1px solid rgba(55, 55, 55, 0.2);
    color: #1e2024;

    > div {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      > strong {
        font-weight: 400;
      }
      :nth-of-type(1) {
        display: none;
      }
      :nth-of-type(2) {
        color: rgba(55, 55, 55, 0.8);
        font-weight: 400;
      }
    }
  }

  @media screen and (max-width: 900px) {
    thead {
      display: none;
      box-shadow: none;
    }

    tr,
    th {
      :nth-of-type(1),
      :nth-of-type(2),
      :nth-of-type(3),
      :nth-of-type(4),
      :nth-of-type(5),
      :nth-of-type(6),
      :nth-of-type(7) {
        width: auto;
      }
    }

    tr {
      display: block;
      margin-bottom: 10px;
      overflow: hidden;
      border-top: 1px solid rgba(55, 55, 55, 0.6);
    }

    td {
      display: flex;
      gap: 2px;
      padding: 0;
      border: none;

      > div {
        width: 100%;
        min-height: 40px;
        padding: 10px;
        display: flex;
        align-items: center;
        text-overflow: inherit;
        white-space: nowrap;
        overflow: scroll;

        :nth-of-type(1) {
          display: block;
        }
        :nth-of-type(2) {
          -webkit-box-orient: horizontal;
        }
      }
    }
  }
`;

export const CheckToggleBtn = styled.input`
  cursor: pointer;
`;

export const DownLoadBtn = styled.button`
  display: block;
  width: 100%;
  height: 30px;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  background: ${({ isDownLoasd }) => (isDownLoasd ? '#e5faec' : '#F1F2F4')};
  color: ${({ isDownLoasd }) => (isDownLoasd ? '#00ad74' : '#686B73')};
  ${({ isDownLoasd }) => (isDownLoasd ? 'cursor: pointer' : ':read-only')}
`;
export const CopyWrapper = styled.button`
  cursor: pointer;
  display: block;
  width: 100%;
  height: 30px;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  background: #ebf5ff;
  color: #2f6eff;
  :hover {
    color: #686b73;
  }
`;
