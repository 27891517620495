// 작품 디테일 페이지

import { useEffect } from 'react';
import ItemDetail from '../components/DetailItem';

const DetailItemPage = () => {
  useEffect(() => {
    const handleMessage = () => {
      const message = {
        height: document.querySelector('#root').scrollHeight + 80,
      };
      window.top.postMessage(message, 'https://artmuse.im');

      const observer = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const message = { height: entry.target.scrollHeight + 80 };
          window.top.postMessage(message, 'https://artmuse.im');
        }
      });
      observer.observe(document.querySelector('#root'));
    };

    if (document.readyState === 'complete') {
      handleMessage();
    } else {
      window.addEventListener('load', handleMessage);
      return () => document.removeEventListener('load', handleMessage);
    }
  }, []);

  return <ItemDetail />;
};

export default DetailItemPage;
