import * as S from './style';

const Modal = ({ isOpen, onClose, title, content, actions }) => {
  if (!isOpen) return null;

  return (
    <S.ModalOverlay onClick={onClose}>
      <S.ModalWrapper onClick={(e) => e.stopPropagation()}>
        {title && <S.ModalTitle>{title}</S.ModalTitle>}
        {content && <S.ModalContent>{content}</S.ModalContent>}
        {actions && <S.ModalActions>{actions}</S.ModalActions>}
      </S.ModalWrapper>
    </S.ModalOverlay>
  );
};

export default Modal;
