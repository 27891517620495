import styled from '@emotion/styled';

export const MyArtWorkWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  background: #fff;
`;

export const FormWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Form = styled.form`
  width: 500px;
  padding: 0 10px;
  margin: 20px 0;
  box-sizing: border-box;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const InputStyle = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  label {
    width: 100%;
    cursor: pointer;
    color: rgb(33, 33, 33);
    display: flex;
    flex-direction: column;
    gap: 5px;
    > span[required] {
      ::after {
        content: '*';
        position: relative;
        left: 5px;
        color: red;
      }
    }
  }

  input {
    width: 100%;
    height: 45px;
    border: 1px solid rgba(33, 33, 33, 0.1);
    border-radius: 8px;
    color: #212121;
    font-size: 15px;
    box-sizing: border-box;
    padding: 0 15px;
    ::placeholder {
      color: #919191;
    }
    outline: none;
  }
  input[type='number'] {
    padding: 0 30px 0 15px;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  input[type='submit'] {
    background: #576ee0;
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    cursor: pointer;
  }

  textarea {
    width: 100%;
    height: 15em;
    resize: none;
    outline: none;
    border: 1px solid rgba(33, 33, 33, 0.1);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 8px;
  }

  select {
    width: 100%;
    height: 45px;
    border: 1px solid rgba(33, 33, 33, 0.1);
    border-radius: 8px;
    color: #212121;
    font-size: 15px;
    box-sizing: border-box;
    padding: 0 15px;
  }
`;

export const FileUpload = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(33, 33, 33, 0.1);
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  color: #919191;

  input[type='file'] {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
  }
`;

export const Size = styled.div`
  display: flex;
  gap: 10px;

  label {
    > span[required] {
      ::after {
        content: '';
      }
    }
  }
`;

export const CancelBtn = styled.div`
  background: #fff0f0;
  color: #e82c2c;
  font-weight: 700;
  font-size: 20px;
  border: none;
  width: 100%;
  height: 45px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 20px;
`;

export const BackgroundImg = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${({ bgImg }) => (bgImg ? `url(${bgImg})` : '')};
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
`;

export const PriceList = styled.div`
  > div {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 8px;
    > span {
      font-size: 16px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      > p {
        :nth-of-type(2) {
          color: #919191;
        }
      }
    }
  }
`;

export const TotalPrice = styled.div`
  width: 100%;
  height: 60px;
  color: #212121;
  box-sizing: border-box;
  text-align: right;
  border-bottom: 1px solid black;
  display: flex;
  align-content: center;
  justify-content: space-between;
  p {
    display: flex;
    align-items: center;
    :nth-of-type(2) {
      font-weight: 700;
      font-size: 24px;
    }
  }
`;

export const TableWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  border: 1px solid rgba(33, 33, 33, 0.1);
  border-radius: 8px;
  overflow: hidden;

  > table {
    table-layout: fixed;
    width: 500px;

    @media (max-width: 500px) {
      width: 100%;
      font-size: 14px;
    }
  }

  th {
    text-align: center;
    word-break: break-all;
    font-weight: 600;
    background-color: #dddfe3;
    border: 0.5px solid #f1f2f4;
  }

  tr {
    text-align: center;
    height: 40px;
  }

  td {
    border: 0.5px solid #f1f2f4;
    font-size: 11px;
  }
`;

export const ProductImg = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  border: 1px solid rgba(33, 33, 33, 0.1);
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
`;

export const InfoField = styled.div`
  width: 100%;
  height: 45px;
  color: #212121;
  font-size: 15px;
  box-sizing: border-box;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  strong {
    font-weight: 600;
  }

  p {
    color: #555;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  @media (max-width: 500px) {
    margin-top: 0px;
    gap: 10px;
  }
`;
