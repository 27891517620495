import { useState, useEffect } from 'react';
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import * as S from './style';
import CustomButton from '../../common/Button/index';
import TokenManager from '../../../api/TokenManger';
import Modal from '../Modal';
import useModal from '../../../hooks/useModal';

const MainHeader = () => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState('관리자');

  const {
    isModalOpen,
    modalTitle,
    modalContent,
    showModal,
    closeModal,
    handleConfirm,
  } = useModal();

  useEffect(() => {
    const tokenManager = new TokenManager();
    const emailPrefix = tokenManager.getEmailPrefix();
    setUserEmail(emailPrefix);
  }, []);

  const handleLogout = () => {
    const tokenManager = new TokenManager();
    tokenManager.deleteToken();
    navigate('/sign-in');
  };

  const openLogoutModal = () => {
    showModal(
      '로그아웃',
      '정말로 로그아웃 하시겠습니까?',
      'logout',
      handleLogout,
    );
  };

  const actions = (
    <>
      <CustomButton
        size="small"
        $state="default"
        text="취소"
        $variant="modalCancel"
        onClick={closeModal}
      />
      <CustomButton
        size="small"
        $state="default"
        text="로그아웃"
        $variant="modalLogoutConfirm"
        onClick={handleConfirm}
      />
    </>
  );

  return (
    <S.MainHeaderContainer>
      <S.MainHeaderWrapper>
        <S.TitleWrapper>
          <S.Title>ARTMUSE</S.Title>
          <S.SubTitle> 관리자 페이지</S.SubTitle>
        </S.TitleWrapper>
        <S.UserWrapper>
          <S.UserInfo>{userEmail} 님, 환영합니다.</S.UserInfo>
          <CustomButton
            size="small"
            $state="default"
            text="로그아웃"
            $variant="logout"
            onClick={openLogoutModal}
          />
        </S.UserWrapper>
      </S.MainHeaderWrapper>

      <S.Nav>
        <S.NavWrapper>
          <S.Link isPath={'/' === pathname}>
            <NavLink to="/">HOME</NavLink>
          </S.Link>

          <S.Link isPath={'/pending-artworks' === pathname}>
            <NavLink to="/pending-artworks">작품 목록</NavLink>
          </S.Link>

          <S.Link isPath={'/admin-artist' === pathname}>
            <NavLink to="/admin-artist">작가 목록</NavLink>
          </S.Link>

          <S.Link isPath={'/purchase-arts' === pathname}>
            <NavLink to="/purchase-arts">판매 목록</NavLink>
          </S.Link>
          <S.Link isPath={'/settlement-check' === pathname}>
            <NavLink to="/settlement-check">정산 관리</NavLink>
          </S.Link>
          <S.Link isPath={'/excel-upload-order' === pathname}>
            <NavLink to="/excel-upload-order">작가 초대</NavLink>
          </S.Link>
          <S.Link isPath={'/upload-payment' === pathname}>
            <NavLink to="/upload-payment">주문 등록(테스트용)</NavLink>
          </S.Link>
        </S.NavWrapper>
      </S.Nav>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle}
        content={modalContent}
        actions={actions}
      />
    </S.MainHeaderContainer>
  );
};

export default MainHeader;
