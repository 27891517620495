export function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}.${month}.${day}`;
}

// 일(DD) 형식으로 날짜 포맷팅 함수
export const formatDateDD = (date) => {
  if (!date) {
    return '';
  }
  const day = String(date.getDate()).padStart(2, '0');
  return `${day}일`;
};

// 월.일(MM.DD) 형식으로 날짜 포맷팅 함수
export const formatDateMMDD = (date) => {
  if (!date) {
    return '';
  }
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${month}.${day}`;
};

// 년 월.일(YYYY.MM.DD) 형식으로 날짜 포맷팅 함수
export const formatDateYYYYMMDD = (date) => {
  if (!date) {
    return '';
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}/${month}/${day}`;
};
