import styled from '@emotion/styled';

export const ArtistWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  background: #fff;
`;
export const ArtistsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  padding: 0px 28px;
  justify-content: center;
  @media (max-width: 700px) {
    padding: 12px;
    gap: 16px;

    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ArtistItemWrapper = styled.div`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 288px;
  height: 360px;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 0px 1px rgba(55, 55, 55, 0.2);
  cursor: pointer;
  overflow: hidden;
`;

export const ArtistInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`;

export const ArtistImg = styled.img`
  width: 288px;
  height: 288px;
  object-fit: cover;
`;

export const ArtistName = styled.div`
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  margin-top: 5px;
`;

export const ArtistDescription = styled.div`
  width: 100%;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
  line-height: 18px;
  color: #999;
  word-break: keep-all;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ShortDescription = styled.div`
  height: 30px;
  border-top: 1px solid black;
  padding-top: 10px;
  margin-top: 20px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const NavigationBar = styled.div`
  width: 100%;
  margin: 20px 0 20px 0;
  display: flex;
  justify-content: center;
  gap: 20px;

  > div {
    cursor: pointer;
    font-size: 14px;
    color: #666;
  }
`;

export const NavigationBtn = styled.button`
  border: none;
  background: none;
  font-size: 20px;
  color: #333;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #000;
  }

  &:disabled {
    color: #ccc;
    cursor: default;
  }
`;

export const DefaultUserIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 193px;
  height: 193px;
  background-color: #e0e0e0;
  color: #fff;
  font-size: 4rem;
`;

export const SkeletonWrapper = styled.div`
  width: 288px;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 1px rgba(55, 55, 55, 0.2);
`;

export const SkeletonImage = styled.div`
  width: 288px;
  height: 288px;
  background-color: #e0e0e0;
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #e0e0e0, #f0f0f0, #e0e0e0);
    animation: skeleton-loading 1.5s infinite;
  }

  @keyframes skeleton-loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

export const SkeletonText = styled.div`
  width: 80%;
  height: 16px;
  background-color: #e0e0e0;
  margin-bottom: 8px;
  position: relative;
  overflow: hidden;

  &:last-child {
    width: 60%;
    margin-bottom: 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #e0e0e0, #f0f0f0, #e0e0e0);
    animation: skeleton-loading 1.5s infinite;
  }

  @keyframes skeleton-loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;
