import styled from '@emotion/styled';

export const PendingArtWorksWrapper = styled.div`
  width: 100%;
  /* min-height: 100vh; */
  display: flex;
  justify-content: center;
  margin-top: 20px;

  > div {
    padding-bottom: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
`;
export const Title = styled.p`
  text-align: center;
  font-weight: 700;
  color: #1e2024;
  font-size: 30px;
`;

export const ListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TableHeadTap = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 200px;
  padding-right: 200px;

  > h1 {
    display: inline;
    padding: 10px;
    font-weight: 700;
    color: #1e2024;
    font-size: 26px;
  }

  > div {
    display: flex;
    gap: 10px;

    > select {
      border: 1px solid #dddfe3;
      border-radius: 8px;
      width: 140px;
      height: 40px;
      background: none;
      color: #1e2024;
      font-weight: 600;
      font-size: 14px;
      outline: 0;
      cursor: pointer;
      > option {
        cursor: pointer;
      }
    }
  }
`;

export const PendingState = styled.div`
  display: block;
  position: relative;
  margin-left: 15px;
  ::before {
    position: absolute;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    content: '';
    width: 5px;
    height: 5px;
    background: ${({ status }) => {
      switch (status) {
        case 'FIRST_ONHOLD':
        case 'SECOND_ONHOLD':
        case 'PENDING':
          return '#ffb200';
        case 'DONE':
        case 'FIRST_APPROVE':
          return '#00ad74';
        default:
          return '#686B73';
      }
    }};
    margin-right: 5px;
  }
`;
