// 어드민 페이지의 로그인 페이지

import { useState } from 'react';
import Input from '../common/Input';
import * as S from './style';
import useFetch from '../../hooks/useFetch';
import TokenManager from '../../api/TokenManger';
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
  const [formValue, setFormValue] = useState({});
  const navigate = useNavigate();

  const { fetch: signIn } = useFetch({
    url: '/auth',
    method: 'post',
    onSuccess: (e) => {
      const tokenManager = new TokenManager();
      tokenManager.setToken(e);
      return navigate('/');
    },
  });

  const handleInputValueChange = (e) => {
    const key = e.target.id;
    const value = e.target.value;
    setFormValue((prev) => ({ ...prev, [key]: value }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { ...rest } = formValue;
    signIn({ ...rest });
  };

  return (
    <S.SignInWrapper>
      <S.Form onSubmit={onSubmit}>
        <S.Title>
          로그인을 위해
          <br />
          <strong>아이디와 비밀번호를 입력하세요</strong>
        </S.Title>
        <S.InputStyle>
          <Input
            id="email"
            type={'text'}
            placeholder={'아이디'}
            setValue={handleInputValueChange}
            required
          />
          <Input
            id="password"
            type={'password'}
            placeholder={'비밀번호'}
            setValue={handleInputValueChange}
            required
          />
          <input type="submit" value="로그인" />
        </S.InputStyle>
      </S.Form>
    </S.SignInWrapper>
  );
};

export default SignIn;
