import { useNavigate } from 'react-router-dom';
import UploadPayment from '../components/UploadPayment';
import MainHeader from '../components/common/MainHeader';
import { useEffect } from 'react';
import TokenManager from '../api/TokenManger';
import styled from '@emotion/styled';

const PageWrapper = styled.div`
  padding-top: ${({ headerHeight }) => headerHeight}px;
`;

const UploadPaymentPage = () => {
  const navigate = useNavigate();
  const headerHeight = 176; // 헤더의 높이를 적절히 조정해주세요.

  useEffect(() => {
    const tokenManager = new TokenManager();

    if (!tokenManager.checkAccessExpToken() || !tokenManager.accessToken)
      return navigate('/sign-in');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MainHeader />
      <PageWrapper headerHeight={headerHeight}>
        <UploadPayment />
      </PageWrapper>
    </>
  );
};

export default UploadPaymentPage;
