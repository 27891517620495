import React, { useRef } from 'react';
import styled from '@emotion/styled';
import CustomButton from '../common/Button';
import { PiX } from 'react-icons/pi';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { formatCurrency } from '../../util/format';

const SettlementModal = ({ isOpen, onClose, settlementData }) => {
  const modalRef = useRef(null);
  if (!isOpen || !settlementData) return null;

  const handlePDFDownload = async () => {
    const modal = modalRef.current;
    const canvas = await html2canvas(modal);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('ArtMuse_Settlement_details.pdf');
  };

  const renderTableHeader = (text) => <TableHeader>{text}</TableHeader>;

  const renderTableCell = ({
    text,
    width,
    background,
    align,
    rowSpan,
    colSpan,
    removeBorder,
    removeVerticalBorder,
  }) => (
    <TableCell
      width={width}
      background={background}
      align={align}
      rowSpan={rowSpan}
      colSpan={colSpan}
      removeBorder={removeBorder}
      removeVerticalBorder={removeVerticalBorder}
    >
      {text}
    </TableCell>
  );

  const renderTableRow = (cells, removeBorder) => (
    <TableRow removeBorder={removeBorder}>
      {cells.map((cell, index) => (
        <React.Fragment key={index}>{cell}</React.Fragment>
      ))}
    </TableRow>
  );

  const renderTable = (header, rows) => (
    <TableContainer>
      {renderTableHeader(header)}
      <Table>{rows.map((row, index) => renderTableRow(row, index))}</Table>
    </TableContainer>
  );

  const generalInformationRows = [
    [
      renderTableCell({
        text: '거래 내역',
        width: 100,
        background: '#E9E9E9',
        align: 'center',
      }),
      renderTableCell({ text: settlementData.artworkName, colSpan: 3 }),
    ],
    [
      renderTableCell({
        text: '거래 일자',
        width: 100,
        background: '#E9E9E9',
        align: 'center',
      }),
      renderTableCell({ text: settlementData.date }),
      renderTableCell({
        text: '작가명',
        width: 100,
        background: '#E9E9E9',
        align: 'center',
      }),
      renderTableCell({ text: settlementData.artistName }),
    ],
    [
      renderTableCell({
        text: '발행 일자',
        width: 100,
        background: '#E9E9E9',
        align: 'center',
      }),
      renderTableCell({ text: settlementData.publishDate }),
      renderTableCell({
        text: '담당자',
        width: 100,
        background: '#E9E9E9',
        align: 'center',
      }),
      renderTableCell({ text: settlementData.settlementName }),
    ],
    [
      renderTableCell({
        text: '구매 수량',
        width: 100,
        background: '#E9E9E9',
        align: 'center',
      }),
      renderTableCell({ text: settlementData.amount }),
      renderTableCell({
        text: '정산 일자',
        width: 100,
        background: '#E9E9E9',
        align: 'center',
      }),
      renderTableCell({ text: settlementData.settlementDate }),
    ],
  ];

  const detailInformationRows = [
    [
      renderTableCell({
        text: '구분',
        width: 100,
        background: '#E9E9E9',
        align: 'center',
      }),
      renderTableCell({
        text: '비목',
        width: 253,
        background: '#E9E9E9',
        align: 'center',
      }),
      renderTableCell({
        text: '금액',
        width: 105,
        background: '#E9E9E9',
        align: 'center',
      }),
      renderTableCell({
        text: '비율',
        width: 100,
        background: '#E9E9E9',
        align: 'center',
      }),
      renderTableCell({
        text: '비고',
        width: 100,
        background: '#E9E9E9',
        align: 'center',
      }),
    ],
    [
      renderTableCell({
        text: '영상비\n(판화 기본가)',
        width: 100,
        background: '#E9E9E9',
        align: 'center',
        rowSpan: 4,
      }),
      renderTableCell({ text: '영상비', width: 253 }),
      renderTableCell({
        text: formatCurrency(settlementData.printingPrice),
        width: 105,
        align: 'right',
      }),
      renderTableCell({ text: '-', width: 105, align: 'center' }),
      renderTableCell({ text: '-', align: 'center' }),
    ],
    [
      renderTableCell({
        text: 'WAE 운영위원회',
        width: 253,
        background: '#D3D3D3',
        removeBorder: true,
        removeVerticalBorder: true,
      }),
      renderTableCell({
        text: formatCurrency(settlementData.printingPrice * 0.4),
        width: 105,
        background: '#D3D3D3',
        align: 'right',
        removeBorder: true,
        removeVerticalBorder: true,
      }),
      renderTableCell({
        text: '40%',
        width: 105,
        background: '#D3D3D3',
        align: 'center',
        removeBorder: true,
        removeVerticalBorder: true,
      }),
      renderTableCell({
        text: 'WAE (위원회)',
        background: '#D3D3D3',
        align: 'center',
        removeBorder: true,
        removeVerticalBorder: true,
      }),
    ],
    [
      renderTableCell({
        text: 'WAE 적립금',
        width: 253,
        background: '#D3D3D3',
        removeBorder: true,
        removeVerticalBorder: true,
      }),
      renderTableCell({
        text: formatCurrency(settlementData.printingPrice * 0.1),
        width: 105,
        background: '#D3D3D3',
        align: 'right',
        removeBorder: true,
        removeVerticalBorder: true,
      }),
      renderTableCell({
        text: '10%',
        width: 105,
        background: '#D3D3D3',
        align: 'center',
        removeBorder: true,
        removeVerticalBorder: true,
      }),
      renderTableCell({
        text: 'WAE (주최자)',
        background: '#D3D3D3',
        align: 'center',
        removeBorder: true,
        removeVerticalBorder: true,
      }),
    ],
    [
      renderTableCell({
        text: '아트앤플로우 수익',
        width: 253,
        background: '#D3D3D3',
        removeVerticalBorder: true,
      }),
      renderTableCell({
        text: formatCurrency(settlementData.printingPrice * 0.5),
        width: 105,
        background: '#D3D3D3',
        align: 'right',
        removeVerticalBorder: true,
      }),
      renderTableCell({
        text: '50%',
        width: 105,
        background: '#D3D3D3',
        align: 'center',
        removeVerticalBorder: true,
      }),
      renderTableCell({
        text: '아트앤플로우',
        background: '#D3D3D3',
        align: 'center',
        removeVerticalBorder: true,
      }),
    ],
    [
      renderTableCell({
        text: '인증 솔루션',
        width: 100,
        background: '#E9E9E9',
        align: 'center',
      }),
      renderTableCell({
        text: 'IAA 공식 인증서버 / 민팅(VFT 등록) 및 관리비',
        width: 253,
      }),
      renderTableCell({ text: '20,000', width: 105, align: 'right' }),
      renderTableCell({ text: '-', width: 105, align: 'center' }),
      renderTableCell({ text: '아트앤플로우', align: 'center' }),
    ],
    [
      renderTableCell({
        text: '수익분배',
        width: 100,
        background: '#E9E9E9',
        align: 'center',
      }),
      renderTableCell({ text: '기본 수익금', width: 253 }),
      renderTableCell({
        text: formatCurrency(settlementData.printingPrice),
        width: 105,
        align: 'right',
      }),
      renderTableCell({ text: '-', width: 105, align: 'center' }),
      renderTableCell({ text: '작가 / 에이전시', align: 'center' }),
    ],
    [
      renderTableCell({
        text: '제작비',
        width: 100,
        background: '#E9E9E9',
        align: 'center',
        rowSpan: 4,
      }),
      renderTableCell({ text: '인쇄비', width: 253, removeBorder: true }),
      renderTableCell({
        text: '25,000',
        width: 105,
        align: 'right',
        removeBorder: true,
      }),
      renderTableCell({
        text: '-',
        width: 105,
        align: 'center',
        removeBorder: true,
      }),
      renderTableCell({
        text: '미림화방',
        align: 'center',
        removeBorder: true,
      }),
    ],
    [
      renderTableCell({ text: '포장비', width: 253, removeBorder: true }),
      renderTableCell({
        text: settlementData.isFramed ? '30,000' : '6,000',
        width: 105,
        align: 'right',
        removeBorder: true,
      }),
      renderTableCell({
        text: '-',
        width: 105,
        align: 'center',
        removeBorder: true,
      }),
      renderTableCell({
        text: '미림화방',
        align: 'center',
        removeBorder: true,
      }),
    ],
    [
      renderTableCell({ text: '액자비', width: 253, removeBorder: true }),
      renderTableCell({
        text: settlementData.isFramed ? '20,000' : '-',
        width: 105,
        align: 'right',
        removeBorder: true,
      }),
      renderTableCell({
        text: '-',
        width: 105,
        align: 'center',
        removeBorder: true,
      }),
      renderTableCell({
        text: '미림화방',
        align: 'center',
        removeBorder: true,
      }),
    ],
    [
      renderTableCell({ text: '운송비', width: 253 }),
      renderTableCell({
        text: settlementData.isFramed ? '10,000' : '6,000',
        width: 105,
        align: 'right',
      }),
      renderTableCell({ text: '-', width: 105, align: 'center' }),
      renderTableCell({ text: '미림화방', align: 'center' }),
    ],
    [
      renderTableCell({
        text: '작품 단가 합계',
        width: 353,
        background: '#E9E9E9',
        align: 'center',
        colSpan: 2,
      }),
      renderTableCell({
        text: formatCurrency(settlementData.artworkPrice),
        width: 105,
        background: '#E9E9E9',
        align: 'right',
      }),
      renderTableCell({
        text: '-',
        width: 105,
        background: '#E9E9E9',
        align: 'center',
      }),
      renderTableCell({
        text: '-',
        width: 105,
        background: '#E9E9E9',
        align: 'center',
      }),
    ],
    [
      renderTableCell({
        text: '총 합계',
        width: 353,
        background: '#E9E9E9',
        align: 'center',
        colSpan: 2,
      }),
      renderTableCell({
        text: formatCurrency(
          settlementData.artworkPrice * settlementData.amount,
        ),
        width: 105,
        background: '#E9E9E9',
        align: 'right',
      }),
      renderTableCell({
        text: '-',
        width: 105,
        background: '#E9E9E9',
        align: 'center',
      }),
      renderTableCell({
        text: '-',
        width: 105,
        background: '#E9E9E9',
        align: 'center',
      }),
    ],
  ];

  return (
    <ModalOverlay>
      <ModalWrapper>
        <ModalContent ref={modalRef}>
          <Title>아트뮤즈 세부 정산 내역서</Title>

          <CloseButton onClick={onClose}>
            <PiX size={24} />
          </CloseButton>

          {renderTable('General Information', generalInformationRows)}
          {renderTable('Detail Information', detailInformationRows)}

          <SettlementStatement>
            위와 같이 영수내역을 첨부하여 정산하도록 한다.
          </SettlementStatement>

          <SettlementDate>{settlementData.settlementDate}</SettlementDate>
        </ModalContent>
        <CustomButton
          size="small"
          $state="default"
          text="다운로드"
          $variant="modalSettlementDown"
          onClick={handlePDFDownload}
        />
      </ModalWrapper>
    </ModalOverlay>
  );
};

// Emotion Styled Components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1003;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  background-color: white;
  border-radius: 4px;
  padding-bottom: 50px;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  display: flex;
  width: 836px;
  padding: 50px 50px 0px 50px;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: black;
  text-align: center;
  margin-bottom: 30px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
`;

const TableContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

const TableHeader = styled.div`
  background-color: black;
  color: white;
  padding: 6px 12px;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #a7a6a6;
`;

const TableRow = styled.tr`
  &:not(:last-of-type) {
    border-bottom: ${({ removeBorder }) =>
      removeBorder ? 'none' : '1px solid #a7a6a6'};
    border-right: ${({ removeBorder }) =>
      removeBorder ? 'none' : '1px solid #a7a6a6'};
  }
`;

const TableCell = styled.td`
  padding: 6px 12px;
  text-align: ${({ align }) => align || 'left'};
  background-color: ${({ background }) => background || 'white'};
  font-size: 12px;
  color: #434343;
  white-space: pre-wrap;
  border-right: ${({ removeVerticalBorder }) =>
    removeVerticalBorder ? 'none' : '1px solid #a7a6a6'};
  border-bottom: ${({ removeBorder }) =>
    removeBorder ? 'none' : '1px solid #a7a6a6'};
`;

const SettlementStatement = styled.div`
  text-align: center;
  font-size: 16px;
  color: black;
`;

const SettlementDate = styled.div`
  text-align: center;
  font-size: 16px;
  color: black;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export default SettlementModal;
