import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const navigate = useNavigate();
  const navigateToErrorPage = useCallback(() => {
    navigate('/404');
  }, [navigate]);

  useEffect(() => {
    navigateToErrorPage();
  }, [navigateToErrorPage]);

  return <></>;
};

export default ErrorPage;
