/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const buttonSizes = {
  small: css`
    height: 30px;
    font-size: 12px;
    font-weight: 500;
  `,
  medium: css`
    width: 157px;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  `,
  large: css`
    width: 212px;
    height: 48px;
    font-size: 20px;
    font-weight: 700;
  `,
};

const buttonStates = {
  default: css`
    background-color: #1e2024;
    color: white;
    &:hover {
      background-color: #919eab;
      opacity: 0.5;
    }
  `,
  disabled: css`
    background-color: #e0e0e0;
    color: #9e9e9e;
    cursor: not-allowed;
  `,
};

const buttonVariants = {
  default: css`
    background-color: #000000;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 4px;
  `,
  login: css`
    background-color: #1e2024;
  `,
  logout: css`
    background-color: #fff;
    border: 1px rgba(145, 158, 171, 0.8) solid;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 4px;
    font-size: 14px;
    color: #000;
    margin: 20px;
  `,
  confirm: css`
    background-color: #576ee0;
    color: white;
    font-size: 16px;
    width: 212px;
  `,
  cancel: css`
    background-color: transparent;
    color: #262626;
    border: 1px #dddfe3 solid;
    font-size: 16px;
    width: 200px;
  `,
  alert: css`
    background-color: #f76659;
    font-size: 16px;
    width: 212px;
  `,
  saveEdit: css`
    width: 200px;
    background-color: #1e2024;
    color: #fff;
    border-radius: 6px;
  `,
  gobackhome: css`
    width: 157px;
    background-color: #fff;
    color: #000;
    border: 1px #dddfe3 solid;
    border-radius: 6px;
  `,
  disabledSaveEdit: css`
    width: 200px;
  `,
  excelDownload: css`
    width: 100px;
    height: 32px;
    font-size: 14px;
    padding: 0 8px;
  `,
  settlement: css`
    background-color: #576ee0;
    width: 100px;
    height: 32px;
    font-size: 14px;
    padding: 0 8px;
  `,
  settlementQuery: css`
    width: 130px;
    height: 36px;
    font-size: 14px;
    padding: 0 8px;
  `,
  modalCancel: css`
    width: 204px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    background-color: #fff;
    color: #262626;
    border: 1px solid #dddfe3;
  `,
  modalLogoutConfirm: css`
    width: 204px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    background-color: #f76659;
    &:hover {
      background-color: #f76659;
      opacity: 0.3;
    }
  `,
  modalExcelDownConfirm: css`
    width: 204px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    background-color: #576ee0;
    &:hover {
      background-color: #576ee0;
      opacity: 0.3;
    }
  `,
  modalSettlementConfirm: css`
    width: 204px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    background-color: #576ee0;
    &:hover {
      background-color: #576ee0;
      opacity: 0.3;
    }
  `,
  modalConfirm: css`
    width: 204px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    background-color: #576ee0;
    &:hover {
      background-color: #576ee0;
      opacity: 0.3;
    }
  `,
  modalSettlementDown: css`
    width: 108px;
    height: 35px;
    font-size: 14px;
    font-weight: 600;
    background-color: #1e2024;
    &:hover {
      background-color: #1e2024;
      opacity: 0.3;
    }
  `,
};

export const CustomButton = styled.button`
  padding: 0 16px;
  border-radius: 6px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  ${({ size }) => buttonSizes[size]}
  ${({ $state }) => buttonStates[$state]}
  ${({ $variant }) => buttonVariants[$variant]}
  ${({ disabled }) =>
    disabled &&
    css`
      ${buttonStates.disabled}
    `}
`;
