import { useSearchParams } from 'react-router-dom';
import * as S from './style';

const MyPageButtons = ({ onButtonClick1, onButtonClick2 }) => {
  const [searchParams] = useSearchParams();
  const userType = searchParams.get('userType');

  return (
    <>
      {userType === 'Artist' && (
        <S.BtnWrapper>
          <S.ArtistEditBtn onClick={onButtonClick1}>
            작가 정보 수정
          </S.ArtistEditBtn>
          <S.ArtWorkEditBtn onClick={onButtonClick2}>
            작품 관리
          </S.ArtWorkEditBtn>
        </S.BtnWrapper>
      )}
    </>
  );
};

export default MyPageButtons;
