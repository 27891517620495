import styled from '@emotion/styled';

export const FixedTagWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
`;

export const FixedTag = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #a6a6a6;
  font-size: 0.85em;
`;

export const TableWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  border: 1px solid rgba(33, 33, 33, 0.1);
  overflow: hidden;

  > table {
    table-layout: fixed;
    width: 500px;
    @media (max-width: 500px) {
      width: 300px;
      font-size: 12px;
    }
  }

  th {
    text-align: center;
    word-break: break-all;
    font-weight: 600;
    background-color: #dddfe3;
    border: 0.5px solid #f1f2f4;
    &.real-price {
      width: 60%; /* 실물 가격 컬럼 너비 */
    }
    &.print-price {
      width: 40%; /* 판화 장당 가격 컬럼 너비 */
    }
  }

  tr {
    text-align: center;
    height: 40px;
  }

  td {
    border: 0.5px solid #f1f2f4;
    font-size: 11px;
    &.real-price {
      width: 60%; /* 실물 가격 컬럼 너비 */
    }
    &.print-price {
      width: 40%; /* 판화 장당 가격 컬럼 너비 */
    }
    @media (max-width: 500px) {
      font-size: 8px;
    }
  }
`;

export const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;

  &:hover > div {
    display: block;
  }
`;
