// 어드민 페이지의 로그인 페이지

import { useEffect } from 'react';
import TokenManager from '../api/TokenManger';
import { useNavigate } from 'react-router-dom';
import SignIn from '../components/SignIn';

const SignInPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const tokenManager = new TokenManager();

    if (tokenManager.checkAccessExpToken() && tokenManager.accessToken)
      return navigate('/pending-artworks');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SignIn />;
};

export default SignInPage;
