/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import * as S from './style';
import Portal from '../common/Portal';
import PendingArtDetail from '../common/PendingArtDetail';
import useFetch from '../../hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import TokenManager from '../../api/TokenManger';
import Modal from '../common/Modal';
import useModal from '../../hooks/useModal';
import CustomButton from '../common/Button';
import {
  FIRST_APPROVE,
  PENDING,
  UPDATE_ONHOLD,
  PendingModePerStatus,
} from '../../Lib/PendingModePerStatus';
import Dropdown from '../common/Dropdown';
import CustomTable from '../common/CustomTable';
import XlsxStyle from 'xlsx-js-style';

const PendingMode = {
  OFFLINE: '오프라인',
  ONLINE: '온라인',
};

const PendingStatus = {
  PENDING: '작품승인 대기중',
  FIRST_ONHOLD: '1차 승인 진행중',
  FIRST_APPROVE: '1차 승인 완료',
  SECOND_ONHOLD: '2차 승인 진행중',
  DONE: '최종 완료',
  CANCEL: '승인 취소',
  UPDATE_ONHOLD: '수정 대기 중', // 추가
};

const PendingArtWorks = () => {
  const [pendingStatus, setPendingStatus] = useState('ALL');
  const [pendingMode, setPendingMode] = useState('ONLINE');
  const [artworks, setArtworks] = useState([]);
  const [artId, setArtId] = useState();
  const [detailData, setDetailData] = useState();
  const [modal, setModal] = useState(false);
  const [size, setSize] = useState(10);
  const navigate = useNavigate();

  const {
    isModalOpen,
    modalTitle,
    modalContent,
    showModal,
    closeModal,
    handleConfirm,
  } = useModal();

  const { fetch: getPendingList } = useFetch({
    url: `/artwork/approve?status=${pendingStatus}&mode=${pendingMode}`,
    method: 'get',
    onSuccess: (data) => setArtworks(data || []),
    onFailure: () => {
      const tokenManager = new TokenManager();
      tokenManager.deleteToken();
      return navigate('/sign-in');
    },
  });

  const { fetch: getDetail } = useFetch({
    url: `/artwork/approve/${artId}`,
    method: 'get',
    onSuccess: setDetailData,
    onFailure: (error) => {
      if (error.response?.status === 401) {
        const tokenManager = new TokenManager();
        tokenManager.deleteToken();
        navigate('/sign-in');
      }
    },
  });

  useEffect(() => {
    if (pendingStatus) {
      getPendingList();
    }
  }, [pendingStatus, pendingMode]);

  useEffect(() => {
    if (artId) {
      getDetail();
    }
  }, [artId]);

  const handleSetPendingStatus = (value) => {
    setPendingStatus(value);
  };

  const handleSetPendingMode = (value) => {
    setPendingStatus('ALL');
    setPendingMode(value);
  };

  const handleModal = (id) => {
    setArtId(id);
    setModal(true);
  };

  const onClose = () => {
    setArtId();
    setDetailData(null);
    setModal(false);
    getPendingList();
  };

  const handlePageSizeChange = (newPageSize) => {
    setSize(newPageSize);
  };

  const handleExcelDownload = (data) => {
    const ws = XlsxStyle.utils.json_to_sheet(
      data.map((row) => ({
        제목: row.title,
        작가: row.artist,
        에디션번호: row.maxMinting,
        등록날짜: row.createdDate,
        상태: PendingStatus[row.status],
      })),
    );

    // 시작 행에 스타일 적용
    const headerStyle = {
      fill: {
        patternType: 'solid',
        fgColor: { rgb: 'D9E1F2' },
      },
      border: {
        top: { style: 'thin', color: { rgb: '000000' } },
        bottom: { style: 'thin', color: { rgb: '000000' } },
        left: { style: 'thin', color: { rgb: '000000' } },
        right: { style: 'thin', color: { rgb: '000000' } },
      },
      font: {
        bold: true,
      },
      alignment: {
        horizontal: 'center',
        vertical: 'center',
      },
    };

    // 테두리 스타일 적용
    const borderStyle = {
      top: { style: 'thin', color: { rgb: '000000' } },
      bottom: { style: 'thin', color: { rgb: '000000' } },
      left: { style: 'thin', color: { rgb: '000000' } },
      right: { style: 'thin', color: { rgb: '000000' } },
    };

    const range = XlsxStyle.utils.decode_range(ws['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cell = XlsxStyle.utils.encode_cell({ r: range.s.r, c: C });
      ws[cell].s = headerStyle;
    }

    for (let R = range.s.r + 1; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell = XlsxStyle.utils.encode_cell({ r: R, c: C });
        ws[cell].s = { border: borderStyle };
      }
    }

    // 열 너비 조정
    ws['!cols'] = [
      { wch: 40 }, // 제목
      { wch: 10 }, // 작가
      { wch: 10 }, // 에디션번호
      { wch: 30 }, // 등록날짜
      { wch: 20 }, // 상태
    ];

    const wb = XlsxStyle.utils.book_new();
    XlsxStyle.utils.book_append_sheet(wb, ws, 'PendingArtworks');
    XlsxStyle.writeFile(wb, 'ArtMuse_pendingArtworks.xlsx');
    closeModal();
  };

  const excelDownloadModal = (selectedRows) => {
    const title =
      selectedRows.length === 0
        ? '전체 다운로드 하시겠습니까?'
        : `${selectedRows.length}개의 선택한 정보를 다운로드 하시겠습니까?`;
    const data = selectedRows.length === 0 ? artworks : selectedRows;

    showModal(title, '', 'confirm', () => handleExcelDownload(data));
  };

  const columns = [
    {
      id: 'select',
      header: ({ table }) => (
        <input
          type="checkbox"
          checked={table.getIsAllRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
          onClick={(e) => e.stopPropagation()}
        />
      ),
      cell: ({ row }) => (
        <input
          type="checkbox"
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
          onClick={(e) => e.stopPropagation()}
        />
      ),
    },
    {
      header: '제목',
      accessorKey: 'title',
      enableSorting: false, // 정렬 비활성화
      align: 'center',
    },
    {
      header: '작가',
      accessorKey: 'artist',
      enableSorting: false, // 정렬 비활성화
      align: 'center',
    },
    {
      header: '에디션 번호',
      accessorKey: 'maxMinting',
      enableSorting: true, // 정렬 비활성화
      align: 'center',
    },
    {
      header: '등록 날짜',
      accessorKey: 'createdDate',
      enableSorting: true, // 정렬 비활성화
      align: 'center',
    },
    {
      header: '상태',
      accessorKey: 'status',
      enableSorting: false, // 정렬 비활성화
      align: 'center',
      cell: ({ row }) => (
        <S.PendingState status={row.original.status}>
          {PendingStatus[row.original.status]}
        </S.PendingState>
      ),
    },
  ];

  const columnWidths = [
    { id: 'select', width: '5%' },
    { id: 'title', width: '35%' },
    { id: 'artist', width: '10%' },
    { id: 'maxMinting', width: '10%' },
    { id: 'createdDate', width: '30%' },
    { id: 'status', width: '10%' },
  ];

  const buttonProps = [
    {
      size: 'small',
      $state: 'default',
      $variant: 'excelDownload',
      text: '엑셀 다운로드',
      onClick: (selectedRows) => excelDownloadModal(selectedRows),
    },
  ];

  const actions = (
    <>
      <CustomButton
        size="small"
        $state="default"
        text="취소"
        $variant="modalCancel"
        onClick={closeModal}
      />
      <CustomButton
        size="small"
        $state="default"
        text="다운로드"
        $variant="modalExcelDownConfirm"
        onClick={handleConfirm}
      />
    </>
  );

  // 승인요청 취소시 아예 삭제.
  const { fetch: deleteArt } = useFetch({
    url: `/artwork/${artId}`,
    method: 'delete',
    onFinally: onClose,
  });

  return (
    <>
      <S.PendingArtWorksWrapper>
        <div>
          <S.Title>작품 목록</S.Title>
          <S.ListWrapper>
            <S.TableHeadTap>
              <h1>{PendingModePerStatus[pendingMode][pendingStatus]}</h1>
              <div>
                <Dropdown
                  options={Object.keys(PendingMode)}
                  selectedValue={pendingMode}
                  onOptionChange={handleSetPendingMode}
                  renderItem={(mode) => PendingMode[mode]}
                  minWidth="120px"
                  height="32px"
                />
                <Dropdown
                  options={Object.keys(PendingModePerStatus[pendingMode])}
                  selectedValue={pendingStatus}
                  onOptionChange={handleSetPendingStatus}
                  renderItem={(status) =>
                    PendingModePerStatus[pendingMode][status]
                  }
                  minWidth="120px"
                  height="32px"
                />
              </div>
            </S.TableHeadTap>
            <CustomTable
              columns={columns}
              data={artworks}
              searchPlaceholder="작품명 검색"
              rowNames="작품"
              pageSizeOptions={[10, 20, 50]}
              onPageSizeChange={handlePageSizeChange}
              onRowClick={(rowData) => handleModal(rowData.id)}
              tableWidth="1000px"
              columnWidths={columnWidths}
              buttonProps={buttonProps}
            />
          </S.ListWrapper>
        </div>
      </S.PendingArtWorksWrapper>
      {modal && detailData && (
        <Portal>
          <PendingArtDetail
            data={detailData}
            isApproveArt={[PENDING, FIRST_APPROVE, UPDATE_ONHOLD].includes(
              detailData.status,
            )}
            isDeleteArt={detailData.isCanceled}
            deleteArt={deleteArt}
            onClose={onClose}
            columnWidths={columnWidths}
          />
        </Portal>
      )}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle}
        content={modalContent}
        actions={actions}
      />
    </>
  );
};

export default PendingArtWorks;
