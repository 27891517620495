// TODO: 1. 작가 리스트 조회 API 수정 혹은 테이블 조회 API 신규 생성 후 매칭. 0603-tw
// TODO: 2. 엑셀 다운로드 부분 백엔드 API 수정 하고 엑셀 다운 로드 버튼 부분 로직 추가 0603-tw

import { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../common/CustomTable';
import useFetch from '../../hooks/useFetch';
import Modal from '../common/Modal';
import useModal from '../../hooks/useModal';
import CustomButton from '../common/Button';
import XlsxStyle from 'xlsx-js-style';

const AdminArtist = () => {
  const [size, setSize] = useState(10);
  const [artistsData, setArtistsData] = useState([]);
  const {
    isModalOpen,
    modalTitle,
    modalContent,
    showModal,
    closeModal,
    handleConfirm,
  } = useModal();

  const { fetch: getArtists } = useFetch({
    url: 'artwork/artists',
    method: 'get',
    onSuccess: (data) => {
      setArtistsData(data.data);
    },
    onError: (error) => {
      console.error('Error fetching artist data:', error);
    },
  });

  const navigate = useNavigate();

  const handleRowClick = (rowData) => {
    const { artistCode } = rowData;
    navigate(`/artist-profile?artistCode=${artistCode}&edit=true`);
  };

  const handlePageSizeChange = (newPageSize) => {
    setSize(newPageSize);
  };

  const handleExcelDownload = (data) => {
    const ws = XlsxStyle.utils.json_to_sheet(
      data.map((row) => ({
        활동명: row.activityName,
        실명: row.artistName,
        연락처: row.phoneNumber,
        이메일: row.email,
        정산계좌번호: row.bankData,
        판매중인작품개수: row.saleCount ? row.saleCount : '-',
        최근정산일: row.settlementDate ? row.settlementDate : '-',
        계정생성일: row.createdAt,
      })),
    );

    // 시작 행에 스타일 적용
    const headerStyle = {
      fill: {
        patternType: 'solid',
        fgColor: { rgb: 'D9E1F2' },
      },
      border: {
        top: { style: 'thin', color: { rgb: '000000' } },
        bottom: { style: 'thin', color: { rgb: '000000' } },
        left: { style: 'thin', color: { rgb: '000000' } },
        right: { style: 'thin', color: { rgb: '000000' } },
      },
      font: {
        bold: true,
      },
      alignment: {
        horizontal: 'center',
        vertical: 'center',
      },
    };

    const range = XlsxStyle.utils.decode_range(ws['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cell = XlsxStyle.utils.encode_cell({ r: range.s.r, c: C });
      ws[cell].s = headerStyle;
    }

    // 테두리 스타일 적용
    const borderStyle = {
      top: { style: 'thin', color: { rgb: '000000' } },
      bottom: { style: 'thin', color: { rgb: '000000' } },
      left: { style: 'thin', color: { rgb: '000000' } },
      right: { style: 'thin', color: { rgb: '000000' } },
    };

    for (let R = range.s.r + 1; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell = XlsxStyle.utils.encode_cell({ r: R, c: C });
        ws[cell].s = { border: borderStyle };
      }
    }

    // 열 너비 조정
    ws['!cols'] = [
      { wch: 15 }, // 활동명
      { wch: 10 }, // 실명
      { wch: 15 }, // 연락처
      { wch: 30 }, // 이메일
      { wch: 25 }, // 정산계좌번호
      { wch: 15 }, // 판매중인작품개수
      { wch: 15 }, // 최근정산일
      { wch: 15 }, // 계정생성일
    ];

    const wb = XlsxStyle.utils.book_new();
    XlsxStyle.utils.book_append_sheet(wb, ws, 'ArtistList');
    XlsxStyle.writeFile(wb, 'ArtMuse_ArtistList.xlsx');
    closeModal();
  };

  const excelDownloadModal = (selectedRows) => {
    if (selectedRows.length === 0) {
      showModal('전체 다운로드 하시겠습니까?', '', 'confirm', () =>
        handleExcelDownload(artistsData),
      );
    } else {
      showModal(
        `${selectedRows.length}개의 선택한 정보를 다운로드 하시겠습니까?`,
        '',
        'confirm',
        () => handleExcelDownload(selectedRows),
      );
    }
  };

  useEffect(() => {
    getArtists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <input
            type="checkbox"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            onClick={(e) => e.stopPropagation()}
          />
        ),
        cell: ({ row }) => (
          <input
            type="checkbox"
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
            onClick={(e) => e.stopPropagation()}
          />
        ),
      },
      {
        header: '활동명',
        accessorKey: 'activityName',
        enableSorting: false, // 정렬 비활성화
      },
      {
        header: '실명',
        accessorKey: 'artistName',
        enableSorting: false, // 정렬 비활성화
      },
      {
        header: '연락처',
        accessorKey: 'phoneNumber',
        enableSorting: false, // 정렬 비활성화
        align: 'center',
        cell: ({ row }) => row.original.phoneNumber || '-',
      },
      {
        header: '이메일',
        accessorKey: 'email',
        enableSorting: false, // 정렬 비활성화
      },
      {
        header: '정산 계좌번호',
        accessorKey: 'bankData',
        enableSorting: false, // 정렬 비활성화
      },
      {
        header: '판매 중 작품 개수',
        accessorKey: 'saleCount',
        enableSorting: false,
        align: 'center',
        cell: ({ row }) => row.original.saleCount || '-',
      },
      {
        header: '최근정산일',
        accessorKey: 'settlementDate',
        enableSorting: true,
        align: 'center',
        cell: ({ row }) => row.original.settlementDate || '-',
      },
      {
        header: '계정 생성일',
        accessorKey: 'createdAt',
        enableSorting: true, // 정렬 비활성화
      },
    ],
    [],
  );

  const columnWidths = [
    { id: 'select', width: '2.68%' },
    { id: 'activityName', width: '8.33%' },
    { id: 'artistName', width: '8.33%' },
    { id: 'phoneNumber', width: '9.33%' },
    { id: 'email', width: '22.67%' },
    { id: 'bankData', width: '22.67%' },
    { id: 'saleCount', width: '9.33%' },
    { id: 'settlementDate', width: '8.33%' },
    { id: 'createdAt', width: '8.33%' },
  ];

  const buttonProps = [
    {
      size: 'small',
      $state: 'default',
      $variant: 'excelDownload',
      text: '엑셀 다운로드',
      onClick: (selectedRows) => excelDownloadModal(selectedRows),
    },
  ];

  const actions = (
    <>
      <CustomButton
        size="small"
        $state="default"
        text="취소"
        $variant="modalCancel"
        onClick={closeModal}
      />
      <CustomButton
        size="small"
        $state="default"
        text="다운로드"
        $variant="modalExcelDownConfirm"
        onClick={handleConfirm}
      />
    </>
  );

  return (
    <div className="container">
      <CustomTable
        title="작가 목록"
        columns={columns}
        data={artistsData}
        searchPlaceholder="작가명을 입력하세요"
        rowNames="작가 목록"
        pageSizeOptions={[10, 20, 50]}
        onPageSizeChange={handlePageSizeChange}
        buttonProps={buttonProps}
        columnWidths={columnWidths}
        onRowClick={handleRowClick}
        tableWidth="1000px"
        showSearchField={true}
      />
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle}
        content={modalContent}
        actions={actions}
      />
    </div>
  );
};

export default AdminArtist;
