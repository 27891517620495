import { useState } from 'react';

const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [modalVariant, setModalVariant] = useState('default');
  const [onConfirmCallback, setOnConfirmCallback] = useState(null);

  const showModal = (title, content, variant = 'default', onConfirm = null) => {
    setModalTitle(title);
    setModalContent(content);
    setModalVariant(variant);
    setOnConfirmCallback(() => onConfirm);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalTitle('');
    setModalContent('');
    setModalVariant('default');
    setOnConfirmCallback(null);
  };

  const handleConfirm = () => {
    if (onConfirmCallback) {
      onConfirmCallback();
    }
  };

  return {
    isModalOpen,
    modalTitle,
    modalContent,
    modalVariant,
    showModal,
    closeModal,
    handleConfirm,
  };
};

export default useModal;
