// 어드민 페이지의 메인 페이지

import { useEffect, useMemo, useState } from 'react';
import * as S from './style';

const Main = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = useMemo(
    () => [
      'https://images.joseartgallery.com/100736/what-kind-of-art-is-popular-right-now.jpg',
      'https://cdn.britannica.com/78/43678-050-F4DC8D93/Starry-Night-canvas-Vincent-van-Gogh-New-1889.jpg',
      'https://luxefair.com/lifestyle/arts/what-is-the-role-of-art-in-human-life/attachment/what-is-the-role-of-art-in-human-life-3/',
      'https://images.pexels.com/photos/1183992/pexels-photo-1183992.jpeg?cs=srgb&dl=pexels-steve-johnson-1183992.jpg&fm=jpg',
    ],
    [],
  );
  const interval = 3000;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1,
      );
    }, interval);

    return () => clearInterval(intervalId);
  }, [images, interval]);

  return (
    <S.MainWrapper>
      <S.SectionArea>
        <S.backgroundSlider
          style={{ backgroundImage: `url(${images[currentIndex]})` }}
        >
          <S.LoaderWrapper>
            <S.TextLoader textArray={[...'Art-Muse']} />
          </S.LoaderWrapper>
        </S.backgroundSlider>
        <S.InfoWrapper>
          <S.Title>
            <h1>글로벌 미술품 거래 플랫폼</h1>
            <strong>ARTMUSE</strong>
          </S.Title>
          <S.IAALogo>
            <h1>IAA</h1>
            <div>
              <p>
                <strong>I</strong>nternational <br />
                <strong>A</strong>ssociation of
                <br />
                <strong>A</strong>rt
              </p>
            </div>
          </S.IAALogo>
          <S.Info>
            <h2>
              IAA는 세계 예술인의 교류와 협력 증진, 창작의 자유를 보장하기 위해
              만들어진 유네스코 산하 비정부기구입니다.
            </h2>

            <p>
              <span>
                국제조형예술협회(International Association of Art, IAA)는 1954년
                유네스코(UNESCO)와 함께 창설된 비정부 기구로,
              </span>
              <span>
                전 세계 미술계와 예술교육계를 대표하는 국제기구입니다. IAA의
                목적은 예술의 국제적인 교류와 협력을 촉진하며,
              </span>
              <span>
                예술가의 지위와 권리를 보호하고 예술교육의 개선을 위해 노력하는
                것입니다.
              </span>
            </p>

            <p>
              <span>
                IAA는 현재 90개국 이상의 회원을 가지고 있으며, 다양한 문화와
                예술 현장에서 활동하고 있습니다.
              </span>
              <span>
                IAA는 회원국들 간의 예술교류와 협력을 촉진하기 위해 다양한
                활동을 진행하고 있습니다.
              </span>
              <span>
                이러한 활동에는 세계미술대전 개최, 국제미술캠프 운영, 예술가
                교류 프로그램 운영,
              </span>
              <span>예술교육 워크숍 개최 등이 포함됩니다.</span>
            </p>

            <p>
              <span>
                IAA는 예술가들의 지위와 권리를 보호하기 위해 노력하고 있습니다.
                이를 위해 예술가들의 저작권 보호와 공정한 대우,
              </span>
              <span>
                그리고 예술가들 간의 교류와 협력을 위한 네트워킹을 지원하고
                있습니다. 또한, IAA는 세계미술계의 발전을 위해
              </span>
              <span>
                다양한 예술 분야에서 활동하고 있으며, 예술가들과 예술교육계에서
                인식되고 존중받는
              </span>
              <span>국제적인 조직으로 자리매김하고 있습니다.</span>
            </p>
          </S.Info>
        </S.InfoWrapper>
      </S.SectionArea>
      <S.FooterArea></S.FooterArea>
    </S.MainWrapper>
  );
};

export default Main;
