import AdminArtist from '../components/AdminArtist';
import MainHeader from '../components/common/MainHeader';
import styled from '@emotion/styled';

const PageWrapper = styled.div`
  padding-top: ${({ headerHeight }) => headerHeight}px;
`;

const AdminArtistPage = () => {
  const headerHeight = 176; // 헤더의 높이를 적절히 조정해주세요.
  return (
    <>
      <MainHeader />
      <PageWrapper headerHeight={headerHeight}>
        <AdminArtist />
      </PageWrapper>
    </>
  );
};

export default AdminArtistPage;
