import styled from '@emotion/styled';

export const TableWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px;
  padding-left: 200px;
  padding-right: 200px;
  justify-content: center;
  align-items: center;

  table {
    font-size: 12px;
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        th {
          font-weight: 400;
          color: #1e2024;
          background-color: #efefef;
          padding: 10px;
          text-align: start;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          border-right: 2px solid #fff;
          &:first-of-type {
            padding: 4px;
          }
          &:last-of-type {
            border-right: none;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          border-bottom: 1px solid #f1f2f4;
          padding: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:first-of-type {
            padding: 0;
            text-align: center;
          }
        }
        &:hover {
          background-color: #efefef4d;
        }
      }
    }
  }
`;

// TabelTitle
export const TableTitle = styled.div`
  display: flex;
  min-width: 830px;
  justify-content: center;
  font-weight: 700;
  color: rgba(33, 33, 33);
  font-size: 30px;
  line-height: 32px;
  margin-bottom: 30px;
`;

// TableHeaderWrapper
export const TableHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  min-width: 830px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  height: 32px;
`;

export const TableHeaderBoxFirst = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-start;
`;

export const RowNames = styled.div`
  display: flex;
  color: #262626;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
`;

export const TableHeaderBoxSecond = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

export const SearchInputWrapper = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid ${({ isFocused }) => (isFocused ? '#000' : '#dddfe3')};
  align-items: center;
  padding: 5px 12px 5px 12px;
`;

export const SearchInput = styled.input`
  padding-left: 8px;
  box-sizing: border-box;
  border: none;
  outline: none;
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

// Pagination
export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0px 0px 0px;
  gap: 10px;
  max-width: 830px;
`;

export const PageButton = styled.button`
  background-color: white;
  color: #1a1a1a;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  cursor: pointer;
`;

export const PageNumberButton = styled.button`
  background-color: transparent;
  border: none;
  color: #c4c4c4;
  font-size: 16px;
  font-weight: 500;
  padding: 4px 8px;
  margin: 0 4px;
  cursor: pointer;
  &.active {
    color: black;
  }
`;
