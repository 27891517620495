import * as S from './style';
import { PiInfo } from 'react-icons/pi';
import Tooltip from '../Tooltip';
import PriceTable from '../PriceTable';

const Input = ({
  id,
  type,
  setValue,
  label,
  fixedDescription,
  regex,
  required,
  isPrice,
  ...rest
}) => {
  const onChange = (e) => {
    setValue(e);
  };

  return (
    <>
      <label htmlFor={id}>
        <div
          required={required}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {label}{' '}
          {isPrice && (
            <S.TooltipWrapper>
              <PiInfo
                style={{ marginLeft: '5px', width: '20px', height: '20px' }}
              />
              <Tooltip>
                <PriceTable /> {/* 가격 테이블 컴포넌트 */}
              </Tooltip>
            </S.TooltipWrapper>
          )}
        </div>

        <S.FixedTagWrapper>
          <input
            id={id}
            type={type}
            onChange={onChange}
            autoComplete="off"
            required={required}
            {...rest}
          />
          {fixedDescription && <S.FixedTag>{fixedDescription}</S.FixedTag>}
        </S.FixedTagWrapper>
      </label>
    </>
  );
};

export default Input;
