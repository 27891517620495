import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  width: 475px;
  height: 200px;
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 2px #1e2024 solid;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #1e2024;
`;

const SubTitle = styled.div`
  font-size: 12px;
  color: #9fa1a8;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 14px;
  color: #1e2024;
  border-bottom: 1px #dddfe3 solid;
`;

const Label = styled.div`
  font-weight: bold;
  width: 140px;
  flex-direction: row;
  display: flex;
`;

const SubLabel = styled.div`
  font-size: 12px;
  color: #9fa1a8;
  padding-left: 6px;
`;

const Value = styled.div``;

const CompanyInfo = ({
  title,
  subtitle,
  settlementData,
  startDate,
  endDate,
  bankData,
  contactData,
}) => {
  return (
    <Container>
      <HeaderWrapper>
        <Title>{title}</Title>
        <SubTitle>영상비의 {subtitle}</SubTitle>
      </HeaderWrapper>
      <Row>
        <Label>
          정산 금액
          <SubLabel>(VAT 별도)</SubLabel>
        </Label>
        <Value>{settlementData}원</Value>
      </Row>
      <Row>
        <Label>정산 기간</Label>
        <Value>
          {startDate} - {endDate}
        </Value>
      </Row>
      <Row>
        <Label>정산 계좌</Label>
        <Value>{bankData}</Value>
      </Row>
      <Row>
        <Label>연락처</Label>
        <Value>{contactData}</Value>
      </Row>
    </Container>
  );
};

export default CompanyInfo;
