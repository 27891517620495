import styled from '@emotion/styled';

export const BtnWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`;

export const ArtistEditBtn = styled.div`
  width: auto;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #212121;
  cursor: pointer;
  font-size: 13px;
  transition: 0.4s;
  border-radius: 4px;
  border: 1px solid #000;
  padding: 8px 16px;

  :hover {
    background-color: #d3d3d3;
    color: #212121;
  }
`;

export const ArtWorkEditBtn = styled(ArtistEditBtn)`
  background-color: #000;
  color: #fff;
`;
