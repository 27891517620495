// 작가 리스트 페이지

import { useEffect, useState } from 'react';
import useFetch from '../../hooks/useFetch';
import * as S from './style';
import { FaUser } from 'react-icons/fa';

//이미지 로딩 처리.
const preloadImages = (imageUrls) => {
  const promises = imageUrls.map((url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = resolve;
      img.onerror = reject;
    });
  });

  return Promise.all(promises);
};

const ArtistList = ({ artists, locationProfilePage }) => {
  const { data } = artists;

  return (
    <S.ArtistsWrapper>
      {data.map((e) => {
        const {
          representativeImgUrl,
          activityName,
          shortDescription,
          artistCode,
        } = e;
        return (
          <S.ArtistItemWrapper key={artistCode}>
            {representativeImgUrl ? (
              <S.ArtistImg
                src={representativeImgUrl}
                alt={activityName}
                onClick={() => locationProfilePage(artistCode)}
              />
            ) : (
              <S.DefaultUserIcon>
                <FaUser />
              </S.DefaultUserIcon>
            )}
            <S.ArtistInfoWrapper>
              <S.ArtistName>{activityName}</S.ArtistName>
              <S.ArtistDescription>{shortDescription}</S.ArtistDescription>
            </S.ArtistInfoWrapper>
          </S.ArtistItemWrapper>
        );
      })}
    </S.ArtistsWrapper>
  );
};

const Artist = () => {
  const [artists, setArtists] = useState(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const { fetch: getArtists } = useFetch({
    url: `artwork/artists?page=${0}&size=${30}`,
    method: 'get',
    onSuccess: (e) => {
      setArtists(e);
    },
  });

  const locationProfilePage = (artistCode) => {
    const targetOrigin = 'https://artmuse.im';
    const normalizedTargetOrigin = new URL(targetOrigin).origin;

    window.top.postMessage(
      {
        works: `${normalizedTargetOrigin}/artist-profile?artistCode=${artistCode}`,
      },
      normalizedTargetOrigin,
    );
  };

  useEffect(() => {
    getArtists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (artists) {
      const targetOrigin = 'https://artmuse.im';
      const normalizedTargetOrigin = new URL(targetOrigin).origin;

      const message = {
        artistsHeight: document.querySelector('#root > div').scrollHeight + 10,
      };
      window.top.postMessage(message, normalizedTargetOrigin);
    }
  }, [artists]);

  useEffect(() => {
    if (artists) {
      const imageUrls = artists.data.map(
        (artist) => artist.representativeImgUrl,
      );
      preloadImages(imageUrls)
        .then(() => setImagesLoaded(true))
        .catch((error) => console.error('이미지 프리로딩 실패:', error));
    }
  }, [artists]);

  return (
    <S.ArtistWrapper>
      {artists === null || !imagesLoaded ? (
        <>
          <S.ArtistsWrapper>
            {Array.from({ length: 12 }).map((_, index) => (
              <S.ArtistItemWrapper key={index}>
                <S.SkeletonWrapper>
                  <S.SkeletonImage />
                  <S.SkeletonText />
                  <S.SkeletonText />
                </S.SkeletonWrapper>
              </S.ArtistItemWrapper>
            ))}
          </S.ArtistsWrapper>
        </>
      ) : (
        <ArtistList
          artists={artists}
          locationProfilePage={locationProfilePage}
        />
      )}
    </S.ArtistWrapper>
  );
};

export default Artist;
