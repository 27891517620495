// 작품 등록 페이지

import { useEffect } from 'react';
import MyArtWork from '../components/MyArtWork';

const MyArtWorkPage = () => {
  // iframe의 높이를 조작하기 위한 로직입니다.
  // https://velog.io/@seoyaon/Javascript-%EC%95%84%EC%9D%B4%ED%94%84%EB%A0%88%EC%9E%84iframe-%EB%86%92%EC%9D%B4-%EC%9E%90%EB%8F%99-%EC%A1%B0%EC%A0%88-%ED%95%98%EA%B8%B0feat.-%ED%81%AC%EB%A1%9C%EC%8A%A4-%EB%8F%84%EB%A9%94%EC%9D%B8
  useEffect(() => {
    const handleMessage = () => {
      const message = { height: document.body.scrollHeight + 400 };
      window.top.postMessage(message, 'https://artmuse.im');
    };

    if (document.readyState === 'complete') {
      handleMessage();
    } else {
      window.addEventListener('load', handleMessage);
      return () => document.removeEventListener('load', handleMessage);
    }
  }, []);

  return <MyArtWork />;
};

export default MyArtWorkPage;
