import { useState } from 'react';
import * as S from './style';
import Input from '../common/Input';
import useFetch from '../../hooks/useFetch';

const UploadPayment = () => {
  const [orderNo, setOrderNo] = useState();

  const handleInputValueChange = (e) => {
    const value = e.target.value;
    setOrderNo(value);
  };

  const { fetch: orderPayment, isLoading } = useFetch({
    url: '/excel/order',
    method: 'post',
    onFailure: () => alert('잘못된 상품 번호입니다'),
    onFinally: () => setOrderNo(''),
  });

  const onSubmit = (e) => {
    e.preventDefault();
    if (isLoading) return;
    orderPayment({ orderNo });
  };
  return (
    <S.UploadPayment>
      <S.FormWrapper>
        <S.Title>주문 번호 입력</S.Title>
        <S.Form onSubmit={onSubmit}>
          <S.InputStyle>
            <Input
              id="orderNo"
              type={'text'}
              setValue={handleInputValueChange}
              label={'주문번호'}
              placeholder={'주문 번호를 입력해주세요'}
              value={orderNo || ''}
              required
            />
            <input
              type="submit"
              value={isLoading ? '등록중...' : '주문 번호 입력하기'}
            />
          </S.InputStyle>
        </S.Form>
      </S.FormWrapper>
    </S.UploadPayment>
  );
};

export default UploadPayment;
