import * as S from './style';

const Spinner = () => {
  return (
    <S.Spinner>
      <img src={process.env.PUBLIC_URL + '/spinner.png'} alt="spinner" />
    </S.Spinner>
  );
};

export default Spinner;
