import styled from '@emotion/styled';

export const PendingArtDeatilOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PendingArtDeatilWrapper = styled.div`
  position: relative;
  width: 500px;
  height: 700px;
  padding: 30px;
  background: white;
  border-radius: 8px;
  z-index: 1001;
  overflow-y: scroll;
  box-shadow: 0px 0px 4px rgba(55, 55, 55, 0.4);

  &::-webkit-scrollbar {
    width: 8px; // 스크롤바 너비
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f1f1f1; // 스크롤바 썸네일 색상
    border-radius: 4px; // 스크롤바 썸네일 모서리 둥글기
  }

  @media (max-width: 500px) {
    width: 100%;
    height: auto;
  }
`;

export const Tilte = styled.h1`
  text-align: center;
  margin-top: 30px;
`;

export const CloseBtn = styled.div`
  width: 100%;
  text-align: end;

  > span {
    background: #fff0f0;
    color: #e82c2c;
    padding: 5px 25px;
    border-radius: 8px;
    font-weight: 700;
    cursor: pointer;
  }
`;

export const ProductImg = styled.div`
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
  position: relative;
`;

export const ProgressiveImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: opacity 0.3s ease-in-out;
  z-index: 2;
`;

export const ProductImgWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const LowQualityImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: blur(10px);
  transition: opacity 0.3s ease-in-out;
`;

export const HighQualityImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.loaded {
    opacity: 1;
  }
`;

export const InfoTab = styled.div`
  width: 100%;
  display: flex;
  border-top: 2px solid black;
  justify-content: center;
  > div {
    box-sizing: border-box;
    max-width: 390px;
    width: 100%;

    > div {
      margin: 20px 40px;
      box-sizing: border-box;
      display: flex;
      > p {
        :nth-of-type(2) {
          flex: 1 0 0px;
          width: 1px;
          word-break: keep-all;
        }

        :nth-of-type(1) {
          width: 80px;
          font-weight: 600;
        }
      }
    }
  }
`;
export const BtnWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
export const ControlBtn = styled.button`
  display: block;
  width: 300px;
  height: 50px;
  border-radius: 8px;
  font-weight: 700;
  border: none;
  cursor: pointer;

  background: ${({ modeState }) =>
    modeState === 'approve'
      ? '#e5faec'
      : modeState === 'delete'
      ? '#fff0f0'
      : modeState === 'edit'
      ? '#007bff'
      : '#e5e5e5'};
  color: ${({ modeState }) =>
    modeState === 'approve'
      ? '#00ad74'
      : modeState === 'delete'
      ? '#e82c2c'
      : modeState === 'edit'
      ? '#ffffff'
      : '#000000'};
`;

export const CloseBtnSpan = styled.span``;

export const LoadingSpinner = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #f0f0f0;
  border-top-color: #3498db;
  animation: spin 1s ease-in-out infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;
