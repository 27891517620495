import axios from 'axios';
import TokenManager from './TokenManger';

const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

const createTokenManager = () => {
  return new TokenManager();
};

const handleErrors = (err) => {
  const error = err instanceof Error ? err : new Error(err);
  return Promise.reject(error);
};

API.interceptors.request.use(async (config) => {
  try {
    const tokenManager = createTokenManager();
    config.headers['Authorization'] = tokenManager.accessToken
      ? `Bearer ${tokenManager.accessToken}`
      : undefined;
    return config;
  } catch (err) {
    return handleErrors(err);
  }
});

API.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const tokenManager = createTokenManager();
    if (err.response && err.response.status === 401) {
      tokenManager.deleteToken();
    }
    return handleErrors(err);
  },
);

export default API;
