import { useState, useRef, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import CustomDateRangePicker from '../common/DateRangePicker/CustomDateRangePicker';
import Dropdown from '../common/Dropdown/index';
import CustomButton from '../common/Button';
import { formatDateYYYYMMDD } from '../../util/fordate';
import BasicInfoCard from './BasicInfoCard';
import DetailInfoCard from './DetailInfoCard';
import ArtistInfoCard from './ArtistInfoCard';
import CustomTable from '../common/CustomTable';
import Modal from '../common/Modal';
import useModal from '../../hooks/useModal';
import { PiMagnifyingGlass } from 'react-icons/pi';
import SettlementModal from './SettlementModal';
import XlsxStyle from 'xlsx-js-style';
import { formatCurrency } from '../../util/format';

const Container = styled.div`
  width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
`;

const Title = styled.div`
  display: flex;
  font-size: 30px;
  font-weight: 700;
  padding: 20px 0px 30px 0px;
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const JoinPeriodContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative; // 추가
`;

const JoinPeriodInputWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const JoinPeriodInput = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 120px;
  cursor: pointer;
  height: 36px;
`;

const StyledJoinPeriodToggle = styled.div`
  min-width: 147px;
  height: 36px;
  padding: 4px;
  background: #d9d9d9;
  border-radius: 6px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const StyledJoinPeriodToggleButton = styled.div`
  flex: 1 1 0;
  align-self: stretch;
  padding: 7px 10px;
  background: ${(props) => (props.$active ? 'white' : 'transparent')};
  box-shadow: ${(props) =>
    props.$active ? '0px 1px 2px rgba(0, 0, 0, 0.06)' : 'none'};
  border-radius: 4px;
  border: ${(props) => (props.$active ? '0.5px #d1d5db solid' : 'none')};
  justify-content: flex-start;
  align-items: center;
  display: flex;
  cursor: pointer;
`;

const StyledJoinPeriodToggleButtonLabel = styled.div`
  flex: 1 1 0;
  text-align: center;
  color: ${(props) => (props.$active ? 'black' : '#374151')};
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: ${(props) => (props.$active ? '500' : '400')};
  line-height: 14px;
  word-wrap: break-word;
`;

const DatePickerWrapper = styled.div`
  position: absolute;
  top: 100%; // 수정
  left: 0;
  z-index: 999;
  margin-top: 8px; // 추가
`;

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;
`;

const DetailCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(475px, 1fr));
  gap: 50px;
  justify-content: center;
  width: 100%;
  max-width: 1100px; // DetailCardContainer의 최대 너비를 설정합니다.
  margin-top: 90px;
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
`;

const ArtistCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
  width: 100%;
  justify-content: center;
  max-width: 1100px;
  margin-top: 90px;
  margin-bottom: 80px;
  margin-left: auto;
  margin-left: auto;
`;

const StatusButton = styled.div`
  width: 82px;
  height: 24px;
  padding-left: 4px;
  padding-right: 4px;
  background: #f1f2f4;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  cursor: pointer;
`;

const StatusLabel = styled.div`
  color: ${({ status }) => (status ? '#686B73' : 'red')};
  font-size: 12px;
  font-family: 'Pretendard';
  padding-left: 4px;
  font-weight: 500;
  line-height: 18px;
  word-wrap: break-word;
`;

const dummySettlementData = [
  {
    orderNumber: '20240611-1453',
    createdAt: '2024.06.11',
    type: '박OO',
    title: '별이 빛나는 밤 (Starry Night)',
    price: 185000,
    artistPrice: 40000,
    rate: 4000,
    status: true,
    bankData: 'OO은행 YYY-ZZZZ-ZZZZ-CT',
    phoneNumber: '02-555-5555 / 010-4440-5645',
    settlementDetails: {
      artworkName: '별이 빛나는 밤 (Starry Night)',
      date: '2024.06.19',
      artistName: '박OO',
      publishDate: '2024.06.31',
      settlementName: '정산 담당자',
      amount: 1,
      settlementDate: '2024.06.15',
      printingPrice: 40000,
      artworkPrice: 185000,
      isFramed: true,
    },
  },
  {
    orderNumber: '20240612-1514',
    createdAt: '2024.06.12',
    type: '박OO',
    title: '달이 빛나는 밤',
    price: 225000,
    artistPrice: 60000,
    rate: 6000,
    status: true,
    bankData: 'OO은행 YYY-ZZZZ-ZZZZ-CT',
    phoneNumber: '02-555-5555 / 010-4440-5645',
    settlementDetails: {
      artworkName: '달이 빛나는 밤',
      date: '2024.06.12',
      artistName: '박OO',
      publishDate: '2024.06.12',
      settlementName: '정산 담당자',
      amount: 1,
      settlementDate: '2024.06.31',
      printingPrice: 60000,
      artworkPrice: 225000,
      isFramed: true,
    },
  },
  {
    orderNumber: '20240613-2345',
    createdAt: '2024.06.13',
    type: '노OO',
    title: '햇님, 달님, 별님',
    price: 137000,
    artistPrice: 40000,
    rate: 4000,
    status: false,
    bankData: '**은행 AAA-BBBB-CCCC-CT',
    phoneNumber: '02-444-4444 / 010-3456-6543',
  },
  {
    orderNumber: '20240614-0004',
    createdAt: '2024.06.14',
    type: '김OO',
    title: '흐르는 강물을 거꾸로',
    price: 137000,
    artistPrice: 40000,
    rate: 4000,
    status: true,
    bankData: '--은행 CCC-DDDD-FFFF-CT',
    phoneNumber: '02-123-4321 / 010-2356-2732',
    settlementDetails: {
      artworkName: '흐르는 강물을 거꾸로',
      date: '2024.06.14',
      artistName: '김OO',
      publishDate: '2024.06.14',
      settlementName: '정산 담당자',
      amount: 1,
      settlementDate: '2024.06.31',
      printingPrice: 40000,
      artworkPrice: 137000,
      isFramed: false,
    },
  },
  {
    orderNumber: '20240615-0005',
    createdAt: '2024.06.15',
    type: '김OO',
    title: '흐르는 강물을 거꾸로',
    price: 137000,
    artistPrice: 40000,
    rate: 4000,
    status: true,
    bankData: '--은행 CCC-DDDD-FFFF-CT',
    phoneNumber: '02-123-4321 / 010-2356-2732',
    settlementDetails: {
      artworkName: '흐르는 강물을 거꾸로',
      date: '2024.06.15',
      artistName: '김OO',
      publishDate: '2024.06.16',
      settlementName: '정산 담당자',
      amount: 1,
      settlementDate: '2024.06.31',
      printingPrice: 40000,
      artworkPrice: 137000,
      isFramed: false,
    },
  },
  {
    orderNumber: '20240616-0006',
    createdAt: '2024.06.16',
    type: '김OO',
    title: '흐르는 강물을 거꾸로',
    price: 137000,
    artistPrice: 40000,
    rate: 4000,
    status: true,
    bankData: '--은행 CCC-DDDD-FFFF-CT',
    phoneNumber: '02-123-4321 / 010-2356-2732',
    settlementDetails: {
      artworkName: '흐르는 강물을 거꾸로',
      date: '2024.06.16',
      artistName: '김OO',
      publishDate: '2024.06.16',
      settlementName: '정산 담당자',
      amount: 1,
      settlementDate: '2024.06.31',
      printingPrice: 40000,
      artworkPrice: 137000,
      isFramed: false,
    },
  },
  {
    orderNumber: '20240617-0007',
    createdAt: '2024.06.17',
    type: '노OO',
    title: '햇님, 달님, 별님',
    price: 185000,
    artistPrice: 40000,
    rate: 4000,
    status: false,
    bankData: '**은행 AAA-BBBB-CCCC-CT',
    phoneNumber: '02-444-4444 / 010-3456-6543',
  },
  {
    orderNumber: '20240618-0008',
    createdAt: '2024.06.18',
    type: '박OO',
    title: '별이 빛나는 밤 (Starry Night)',
    price: 185000,
    artistPrice: 40000,
    rate: 4000,
    status: true,
    bankData: 'OO은행 YYY-ZZZZ-ZZZZ-CT',
    phoneNumber: '02-555-5555 / 010-4440-5645',
    settlementDetails: {
      artworkName: '별이 빛나는 밤 (Starry Night)',
      date: '2024.06.18',
      artistName: '박OO',
      publishDate: '2024.06.20',
      settlementName: '정산 담당자',
      amount: 1,
      settlementDate: '2024.06.31',
      printingPrice: 40000,
      artworkPrice: 185000,
      isFramed: true,
    },
  },
  {
    orderNumber: '20240619-0009',
    createdAt: '2024.06.19',
    type: '박OO',
    title: '별이 빛나는 밤 (Starry Night)',
    price: 185000,
    artistPrice: 40000,
    rate: 4000,
    status: true,
    bankData: 'OO은행 YYY-ZZZZ-ZZZZ-CT',
    phoneNumber: '02-555-5555 / 010-4440-5645',
    settlementDetails: {
      artworkName: '별이 빛나는 밤 (Starry Night)',
      date: '2024.06.19',
      artistName: '박OO',
      publishDate: '2024.06.20',
      settlementName: '정산 담당자',
      amount: 1,
      settlementDate: '2024.06.31',
      printingPrice: 40000,
      artworkPrice: 185000,
      isFramed: true,
    },
  },
  {
    orderNumber: '20240620-0010',
    createdAt: '2024.06.20',
    type: '박OO',
    title: '별이 빛나는 밤 (Starry Night)',
    price: 185000,
    artistPrice: 40000,
    rate: 4000,
    status: true,
    bankData: 'OO은행 YYY-ZZZZ-ZZZZ-CT',
    phoneNumber: '02-555-5555 / 010-4440-5645',
    settlementDetails: {
      artworkName: '별이 빛나는 밤 (Starry Night)',
      date: '2024.06.20',
      artistName: '박OO',
      publishDate: '2024.06.21',
      settlementName: '정산 담당자',
      amount: 1,
      settlementDate: '2024.06.31',
      printingPrice: 40000,
      artworkPrice: 185000,
      isFramed: true,
    },
  },
  {
    orderNumber: '20240621-0011',
    createdAt: '2024.06.21',
    type: '노OO',
    title: '햇님, 달님, 별님',
    price: 137000,
    artistPrice: 40000,
    rate: 4000,
    status: false,
    bankData: '**은행 AAA-BBBB-CCCC-CT',
    phoneNumber: '02-444-4444 / 010-3456-6543',
  },
  {
    orderNumber: '20240622-0012',
    createdAt: '2024.06.22',
    type: '박OO',
    title: '별이 빛나는 밤 (Starry Night)',
    price: 185000,
    artistPrice: 40000,
    rate: 4000,
    status: true,
    bankData: 'OO은행 YYY-ZZZZ-ZZZZ-CT',
    phoneNumber: '02-555-5555 / 010-4440-5645',
    settlementDetails: {
      artworkName: '별이 빛나는 밤 (Starry Night)',
      date: '2024.06.22',
      artistName: '박OO',
      publishDate: '2024.06.24',
      settlementName: '정산 담당자',
      amount: 1,
      settlementDate: '2024.06.31',
      printingPrice: 40000,
      artworkPrice: 185000,
      isFramed: true,
    },
  },
];

const SearchComponent = () => {
  const [selectedOption, setSelectedOption] = useState('전체');
  const [isAllPeriod, setIsAllPeriod] = useState(true);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
  );
  const [size, setSize] = useState(10);
  const [settlementData, setSettlementData] = useState([]);
  const [options, setOptions] = useState(['전체']);
  const {
    isModalOpen,
    modalTitle,
    modalContent,
    showModal,
    closeModal,
    handleConfirm,
  } = useModal();
  const [isSettlementModalOpen, setIsSettlementModalOpen] = useState(false);
  const [selectedSettlement, setSelectedSettlement] = useState(null);

  const containerRef = useRef(null);

  const calculateBasicInfo = (data) => {
    const totalPrice = data.reduce((sum, item) => sum + item.price, 0);
    const totalArtistPrice = data.reduce(
      (sum, item) => sum + item.artistPrice,
      0,
    );

    const waeCommittee = totalArtistPrice * 0.1;
    const waeReserve = totalArtistPrice * 0.4;
    const artAndFlow = totalArtistPrice * 0.5;
    const artistAndAgency = totalArtistPrice;
    const mirimhwabang =
      totalPrice - (waeCommittee + waeReserve + artAndFlow + artistAndAgency);

    return [
      { title: '총 판매금액', value: formatCurrency(totalPrice) },
      { title: 'WAE 운영위원회', value: formatCurrency(waeCommittee) },
      { title: 'WAE 적립금', value: formatCurrency(waeReserve) },
      { title: '아트앤플로우', value: formatCurrency(artAndFlow) },
      { title: '작가 / 에이전시', value: formatCurrency(artistAndAgency) },
      { title: '미림화방', value: formatCurrency(mirimhwabang) },
    ];
  };

  const [basicInfoData, setBasicInfoData] = useState(
    calculateBasicInfo(dummySettlementData),
  );

  const [detailCardData, setDetailCardData] = useState([
    {
      companyName: 'WAE 운영위원회',
      settlementRatio: '10%',
      settlementData: `${dummySettlementData.length}건 | ${formatCurrency(
        basicInfoData[1].value,
      )}`,
      startDate: formatDateYYYYMMDD(startDate),
      endDate: formatDateYYYYMMDD(endDate),
      bankData: '00은행 YYY-ZZZZ-ZZZZ-CR',
      contactData: '김도경 / 010-4440-5645',
    },
    {
      companyName: '아트앤플로우',
      settlementRatio: '50% + 민팅(NFT 등록)및 관리비',
      settlementData: `${dummySettlementData.length}건 | ${formatCurrency(
        basicInfoData[3].value,
      )}`,
      startDate: formatDateYYYYMMDD(startDate),
      endDate: formatDateYYYYMMDD(endDate),
      bankData: '00은행 YYY-ZZZZ-ZZZZ-CR',
      contactData: '홍길동 / 010-4440-5645',
    },
    {
      companyName: 'WAE 적립금',
      settlementRatio: '40%',
      settlementData: `${dummySettlementData.length}건 | ${formatCurrency(
        basicInfoData[2].value,
      )}`,
      startDate: formatDateYYYYMMDD(startDate),
      endDate: formatDateYYYYMMDD(endDate),
      bankData: '00은행 YYY-ZZZZ-ZZZZ-CR',
      contactData: '김철수 / 010-4440-5645',
    },
  ]);

  const [artistCardData, setArtistCardData] = useState();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  // TODO: 실 데이터로 변경
  useEffect(() => {
    // 초기 데이터 로드
    setSettlementData(dummySettlementData);

    // dummySettlementData에서 중복을 제거한 type 값을 options에 설정
    const uniqueTypes = [
      ...new Set(dummySettlementData.map((data) => data.type)),
    ];
    setOptions(['전체', ...uniqueTypes]);
  }, []);

  const handlePageSizeChange = (newPageSize) => {
    setSize(newPageSize);
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleDirectSettingClick = () => {
    setIsAllPeriod(false);
    setShowDatePicker(true);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const toggleAllPeriod = () => {
    setIsAllPeriod(true);
    setShowDatePicker(false);
    setStartDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    setEndDate(
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    );
  };

  const handleSearch = () => {
    const searchParams = {
      option: selectedOption,
      startDate: startDate ? formatDateYYYYMMDD(startDate) : null,
      endDate: endDate ? formatDateYYYYMMDD(endDate) : null,
    };

    console.log('검색 요청:', searchParams);

    // 선택된 날짜에 따라 데이터 필터링
    let filteredData = dummySettlementData;
    if (startDate && endDate) {
      filteredData = dummySettlementData.filter((data) => {
        const createdAt = new Date(data.createdAt);
        return createdAt >= startDate && createdAt <= endDate;
      });
    }

    // 선택된 옵션에 따라 데이터 필터링
    if (selectedOption !== '전체') {
      filteredData = filteredData.filter(
        (data) => data.type === selectedOption,
      );
    }

    // 선택된 작가의 정보 추출
    if (selectedOption !== '전체') {
      const titles = [...new Set(filteredData.map((data) => data.title))];

      const artworks = filteredData.reduce((acc, data) => {
        if (data.settlementDetails) {
          const { artworkName, amount } = data.settlementDetails;
          const artworkPrice = data.status ? data.artistPrice : null;

          if (artworkName && amount && artworkPrice) {
            const existingArtwork = acc.find(
              (artwork) => artwork.artworkName === artworkName,
            );

            if (existingArtwork) {
              existingArtwork.artworkCount += amount;
              existingArtwork.artworkPrice += artworkPrice;
            } else {
              acc.push({
                artworkName,
                artworkCount: amount,
                artworkPrice,
              });
            }
          }
        }

        return acc;
      }, []);

      const settlementPrice = filteredData.reduce(
        (sum, data) => (data.status ? sum + data.artistPrice : sum),
        0,
      );

      const artistCardData = {
        type: selectedOption,
        startDate: formatDateYYYYMMDD(startDate),
        endDate: formatDateYYYYMMDD(endDate),
        bankData: filteredData[0]?.bankData || '', // Optional chaining 사용
        phoneNumber: filteredData[0]?.phoneNumber || '', // Optional chaining 사용
        titles,
        artworks,
        settlementPrice,
      };
      setArtistCardData(artistCardData);
    } else {
      setArtistCardData(null);
    }

    setSettlementData(filteredData);
    setBasicInfoData(calculateBasicInfo(filteredData));
    setDetailCardData([
      {
        companyName: 'WAE 운영위원회',
        settlementRatio: '10%',
        settlementData: `${filteredData.length}건 | ${formatCurrency(
          calculateBasicInfo(filteredData)[1].value,
        )}`,
        startDate: formatDateYYYYMMDD(startDate),
        endDate: formatDateYYYYMMDD(endDate),
        bankData: '00은행 YYY-ZZZZ-ZZZZ-CR',
        contactData: '김도경 / 010-4440-5645',
      },
      {
        companyName: '아트앤플로우',
        settlementRatio: '50% + 민팅(NFT 등록)및 관리비',
        settlementData: `${filteredData.length}건 | ${formatCurrency(
          calculateBasicInfo(filteredData)[3].value,
        )}`,
        startDate: formatDateYYYYMMDD(startDate),
        endDate: formatDateYYYYMMDD(endDate),
        bankData: '00은행 YYY-ZZZZ-ZZZZ-CR',
        contactData: '홍길동 / 010-4440-5645',
      },
      {
        companyName: 'WAE 적립금',
        settlementRatio: '40%',
        settlementData: `${filteredData.length}건 | ${formatCurrency(
          calculateBasicInfo(filteredData)[2].value,
        )}`,
        startDate: formatDateYYYYMMDD(startDate),
        endDate: formatDateYYYYMMDD(endDate),
        bankData: '00은행 YYY-ZZZZ-ZZZZ-CR',
        contactData: '김철수 / 010-4440-5645',
      },
    ]);
  };

  const handleExcelDownload = (data) => {
    console.log('선택된 행 데이터:', data);
    const ws = XlsxStyle.utils.json_to_sheet(
      data.map((row) => ({
        주문번호: row.orderNumber,
        판매일자: row.createdAt,
        작가명: row.type,
        작품명: row.title,
        판매금액: row.price,
        정산금액: row.artistPrice,
        '부가세 (10%)': row.rate,
        정산상태: row.status ? '정산완료' : '미정산',
      })),
    );

    // 시작 행에 스타일 적용
    const headerStyle = {
      fill: {
        patternType: 'solid',
        fgColor: { rgb: 'D9E1F2' },
      },
      border: {
        top: { style: 'thin', color: { rgb: '000000' } },
        bottom: { style: 'thin', color: { rgb: '000000' } },
        left: { style: 'thin', color: { rgb: '000000' } },
        right: { style: 'thin', color: { rgb: '000000' } },
      },
      font: {
        bold: true,
      },
      alignment: {
        horizontal: 'center',
        vertical: 'center',
      },
    };

    const range = XlsxStyle.utils.decode_range(ws['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cell = XlsxStyle.utils.encode_cell({ r: range.s.r, c: C });
      ws[cell].s = headerStyle;
    }

    // 테두리 스타일 적용
    const borderStyle = {
      top: { style: 'thin', color: { rgb: '000000' } },
      bottom: { style: 'thin', color: { rgb: '000000' } },
      left: { style: 'thin', color: { rgb: '000000' } },
      right: { style: 'thin', color: { rgb: '000000' } },
    };

    for (let R = range.s.r + 1; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell = XlsxStyle.utils.encode_cell({ r: R, c: C });
        ws[cell].s = { border: borderStyle };
      }
    }

    // 열 너비 조정
    ws['!cols'] = [
      { wch: 15 }, // 주문번호
      { wch: 15 }, // 판매일자
      { wch: 10 }, // 작가명
      { wch: 30 }, // 작품명
      { wch: 15 }, // 판매금액
      { wch: 15 }, // 정산금액
      { wch: 15 }, // 부가세 (10%)
      { wch: 15 }, // 정산상태
    ];

    const wb = XlsxStyle.utils.book_new();
    XlsxStyle.utils.book_append_sheet(wb, ws, 'SettlementList');
    XlsxStyle.writeFile(wb, 'ArtMuse_settlementList.xlsx');
    closeModal();
  };

  const excelDownloadModal = (selectedRows) => {
    const title =
      selectedRows.length === 0
        ? '전체 다운로드 하시겠습니까?'
        : `${selectedRows.length}개의 선택한 정보를 다운로드 하시겠습니까?`;
    const data = selectedRows.length === 0 ? settlementData : selectedRows;

    showModal(title, '', 'confirm', () => handleExcelDownload(data));
  };

  const handleSettlement = (data) => {
    console.log('정산 처리할 데이터:', data);
    // TODO: 실제 정산 처리 로직을 여기에 구현
    closeModal();
  };

  const settlementModal = (selectedRows) => {
    const title =
      selectedRows.length === 0
        ? '전체 정산 처리 하시겠습니까?'
        : `${selectedRows.length}개의 선택한 건을 정산 처리 하시겠습니까?`;
    const data = selectedRows.length === 0 ? settlementData : selectedRows;

    showModal(title, '정산 완료 시, 취소가 불가능 합니다.', 'confirm', () => {
      handleSettlement(data);
      showModal('정산 완료', '', 'alert', () => {});
    });
  };

  const renderStatusButton = (status, data) => {
    if (status === true) {
      return (
        <StatusButton
          onClick={() => {
            setSelectedSettlement(data.settlementDetails);
            setIsSettlementModalOpen(true);
          }}
        >
          <PiMagnifyingGlass size={14} color="#686B73" />
          <StatusLabel status={status}>정산내역서</StatusLabel>
        </StatusButton>
      );
    } else if (status === false) {
      return <StatusLabel status={status}>미정산</StatusLabel>;
    }
    return null;
  };

  const columns = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <input
            type="checkbox"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            onClick={(e) => e.stopPropagation()}
          />
        ),
        cell: ({ row }) => (
          <input
            type="checkbox"
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
            onClick={(e) => e.stopPropagation()}
          />
        ),
      },
      {
        header: '주문번호',
        accessorKey: 'orderNumber',
        enableSorting: false,
        align: 'center',
      },
      {
        header: '판매일자',
        accessorKey: 'createdAt',
        enableSorting: true,
        align: 'center',
      },
      {
        header: '작가명',
        accessorKey: 'type',
        enableSorting: false,
        align: 'center',
      },
      {
        header: '작품명',
        accessorKey: 'title',
        enableSorting: false,
        align: 'center',
      },
      {
        header: '판매금액 (₩)',
        accessorKey: 'price',
        enableSorting: false,
        align: 'center',
        cell: ({ row }) => formatCurrency(row.original.price),
      },
      {
        header: '정산금액 (₩)',
        accessorKey: 'artistPrice',
        enableSorting: false,
        align: 'center',
        cell: ({ row }) => formatCurrency(row.original.artistPrice),
      },
      {
        header: '부가세 (10%) (₩)',
        accessorKey: 'rate',
        enableSorting: false,
        align: 'center',
        cell: ({ row }) => formatCurrency(row.original.rate),
      },
      {
        header: '정산상태',
        accessorKey: 'status',
        enableSorting: false,
        cell: ({ row }) =>
          renderStatusButton(row.original.status, row.original),
        align: 'center',
      },
    ],
    [],
  );

  const buttonProps = [
    {
      size: 'small',
      $state: 'default',
      $variant: 'excelDownload',
      text: '엑셀 다운로드',
      onClick: (selectedRows) => excelDownloadModal(selectedRows),
    },
    {
      size: 'small',
      $state: 'default',
      $variant: 'settlement',
      text: '정산 완료',
      onClick: (selectedRows) => settlementModal(selectedRows),
    },
  ];

  const actions = (
    <>
      {modalTitle.includes('정산 완료') ? (
        <CustomButton
          size="small"
          $state="default"
          text="확인"
          $variant="modalConfirm"
          onClick={closeModal}
        />
      ) : (
        <>
          <CustomButton
            size="small"
            $state="default"
            text="취소"
            $variant="modalCancel"
            onClick={closeModal}
          />
          <CustomButton
            size="small"
            $state="default"
            text={modalTitle.includes('다운로드') ? '다운로드' : '정산'}
            $variant={
              modalTitle.includes('다운로드')
                ? 'modalExcelDownConfirm'
                : 'modalSettlementConfirm'
            }
            onClick={handleConfirm}
          />
        </>
      )}
    </>
  );

  return (
    <Container>
      <Title>정산 관리</Title>

      <SearchContainer ref={containerRef}>
        <SearchWrapper>
          <Dropdown
            options={options}
            selectedValue={selectedOption}
            onOptionChange={handleOptionChange}
            minWidth="120px"
            height="36px"
          />
          <JoinPeriodContainer>
            <JoinPeriodInputWrapper>
              <JoinPeriodInput
                value={startDate ? `${formatDateYYYYMMDD(startDate)} 부터` : ''}
                placeholder="시작일"
                readOnly
                onClick={handleDirectSettingClick}
              />
              <JoinPeriodInput
                value={endDate ? `${formatDateYYYYMMDD(endDate)} 까지` : ''}
                placeholder="종료일"
                readOnly
                onClick={handleDirectSettingClick}
              />
            </JoinPeriodInputWrapper>

            <StyledJoinPeriodToggle>
              <StyledJoinPeriodToggleButton
                $active={isAllPeriod}
                onClick={toggleAllPeriod}
              >
                <StyledJoinPeriodToggleButtonLabel $active={isAllPeriod}>
                  초기화
                </StyledJoinPeriodToggleButtonLabel>
              </StyledJoinPeriodToggleButton>
              <StyledJoinPeriodToggleButton
                $active={!isAllPeriod}
                onClick={handleDirectSettingClick}
              >
                <StyledJoinPeriodToggleButtonLabel $active={!isAllPeriod}>
                  기간설정
                </StyledJoinPeriodToggleButtonLabel>
              </StyledJoinPeriodToggleButton>
            </StyledJoinPeriodToggle>
            {showDatePicker && (
              <DatePickerWrapper>
                <CustomDateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  onChange={handleDateChange}
                  onCancel={() => setShowDatePicker(false)}
                  onConfirm={() => setShowDatePicker(false)}
                  selectsRange
                />
              </DatePickerWrapper>
            )}
          </JoinPeriodContainer>

          <CustomButton
            size="medium"
            $state="default"
            text="조회"
            $variant="settlementQuery"
            onClick={handleSearch}
          />
        </SearchWrapper>
      </SearchContainer>

      <CardContainer>
        {basicInfoData.map((data, index) => (
          <BasicInfoCard
            key={index}
            backgroundColor={index === 0 ? '#DDDFE3' : '#F5F5F5'}
            title={data.title}
            value={data.value}
          />
        ))}
      </CardContainer>

      {artistCardData ? (
        <ArtistCardContainer>
          <ArtistInfoCard artistCardData={artistCardData} />
        </ArtistCardContainer>
      ) : (
        <DetailCardContainer>
          {detailCardData.map((data, index) => (
            <DetailInfoCard
              key={index}
              title={data.companyName}
              subtitle={data.settlementRatio}
              settlementData={data.settlementData}
              startDate={data.startDate}
              endDate={data.endDate}
              bankData={data.bankData}
              contactData={data.contactData}
            />
          ))}
        </DetailCardContainer>
      )}

      <CustomTable
        columns={columns}
        data={settlementData}
        rowNames="건별 상세"
        pageSizeOptions={[10, 20, 50]}
        onPageSizeChange={handlePageSizeChange}
        buttonProps={buttonProps}
        tableWidth="1000px"
        showSearchField={false}
      />

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle}
        content={modalContent}
        actions={actions}
      />

      <SettlementModal
        isOpen={isSettlementModalOpen}
        onClose={() => setIsSettlementModalOpen(false)}
        settlementData={selectedSettlement}
      />
    </Container>
  );
};

export default SearchComponent;
