// 작품 디테일 페이지

import * as S from './style';

const DetailItem = () => {
  const param = new URLSearchParams(window.location.search);

  const handleMoreItem = (value) =>
    window.top.postMessage({ item: value }, 'https://artmuse.im');

  const GateWayDetail = ({ mode, value }) => {
    switch (mode) {
      case 'customerCode':
        return (
          <S.BtnWrapper>
            <S.Btn onClick={() => handleMoreItem(value)}>
              이 작가의 다른 상품 보러 가기
            </S.Btn>
          </S.BtnWrapper>
        );
      default:
        return (
          <S.ItemSection
            dangerouslySetInnerHTML={{ __html: value.replace(/'/g, '') }}
          />
        );
    }
  };

  return (
    <S.DetailItemWrapper>
      {[...param].map(([key, value]) => {
        return <GateWayDetail key={key} mode={key} value={value} />;
      })}
    </S.DetailItemWrapper>
  );
};

export default DetailItem;
