import styled from '@emotion/styled';

export const ArtistDetailInfoTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #1e2024;
  font-weight: 700;
  line-height: 32px;
`;

export const ArtistProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  justify-content: center;
  align-items: center;
  /* margin: 20px 20px 0px 20px; */
  margin: ${({ $hasToken }) => ($hasToken ? '20px' : 'none')};
  padding-bottom: ${({ $hasToken }) => ($hasToken ? '90px' : 'none')};
  gap: 32px;
  transition: height 1s ease-in-out;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;

export const ContentsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 32px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContentsLeftWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 600px;
  flex-direction: column;
  @media (max-width: 768px) {
    width: auto;
    order: 1;
  }
`;

export const ContentsRightWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 600px;
  gap: 32px;
  @media (max-width: 768px) {
    width: auto;
    order: 2;
  }
`;

export const SkeletonContentsRightWrapper = styled(ContentsRightWrapper)`
  width: 600px;
  @media (max-width: 768px) {
    width: auto;
  }
`;

export const ProfileImage = styled.img`
  width: 600px;
  height: 600px;
  object-fit: cover;
  border: 1px #dddfe3 solid;
  background-color: #a3a3a3;
  transition: opacity 0.5s ease-in-out;
  @media (max-width: 768px) {
    width: auto;
    height: auto; /* 높이를 자동으로 조정하여 비율을 유지합니다 */
  }
`;

export const SignImage = styled.img`
  background-color: ${({ isEditing }) => (isEditing ? '#a3a3a3' : '#ffffff')};
  border: 1px #dddfe3 solid;
  padding: 30px;
  width: 100%;
  max-width: 600px;
  height: auto;
  max-height: 250px;
  transition: opacity 0.5s ease-in-out;
  @media (max-width: 768px) {
    width: 100%; /* 모바일에서는 이미지 너비를 화면 너비에 맞춥니다 */
    height: auto; /* 높이를 자동으로 조정합니다 */
    padding: 15px; /* 모바일에서 패딩을 줄여 더 많은 화면 공간을 활용합니다 */
  }
`;

export const ArtistNameWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
`;

export const ArtistNameTitle = styled.div`
  font-size: 20px;
  font-family: 'Noto Sans KR';
  font-weight: 700;
  line-height: 24px;
`;

export const ArtistName = styled.div`
  font-size: 16px;
  color: #1e2024;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 24px;
  transition: opacity 0.5s ease-in-out;
`;

export const ArtistNameInput = styled.input`
  font-size: 16px;
  color: #1e2024;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 24px;
  border: 1px #1e2024 solid;
  border-radius: 4px;
  min-width: 254px;
  height: 40px;
  padding: 10px;
  @media (max-width: 768px) {
    min-width: auto;
  }
`;

export const ArtistDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  gap: 10px;
`;

export const ArtistDescriptionTitle = styled.div`
  font-size: 20px;
  font-family: 'Noto Sans KR';
  font-weight: 700;
  line-height: 24px;
`;

export const ArtistDescription = styled.div`
  font-size: 16px;
  color: #1e2024;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 24px;
  transition: opacity 0.5s ease-in-out;
`;

export const ArtistDescriptionTextarea = styled.textarea`
  border: 1px #1e2024 solid;
  border-radius: 4px;
  font-size: 16px;
  color: #1e2024;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 24px;
  min-width: 548px;
  resize: none;
  height: 370px; // 원하는 높이로 조정
  padding: 10px;
  @media (max-width: 768px) {
    min-width: auto;
    height: auto;
  }
`;

export const ArtistHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ArtistHistoryTitle = styled.div`
  font-size: 20px;
  font-family: 'Noto Sans KR';
  font-weight: 700;
  line-height: 24px;
`;

export const ArtistHistory = styled.div`
  height: 100%;
  border-radius: 6px;
  font-size: 16px;
  color: #1e2024;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 24px;
  transition: opacity 0.5s ease-in-out;
  /* overflow-y: auto;
  word-break: keep-all;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c9c9c9;
    border-radius: 6px;
  } */
`;

export const ArtistHistoryTextarea = styled.textarea`
  height: 370px;
  border: 1px #1e2024 solid;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  color: #1e2024;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 24px;
  overflow-y: auto;
  word-break: keep-all;
  min-width: 548px;
  min-height: 627px;
  resize: none;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c9c9c9;
    border-radius: 6px;
  }
  @media (max-width: 768px) {
    min-width: auto;
    height: auto;
  }
`;

export const ProductItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 600px; // ProductItemWrapper의 최대 너비를 설정합니다.
  margin: 16px 0px 16px 0px;
  gap: 10.23px;
  overflow-x: auto;
  scroll-behavior: smooth;
  transition: opacity 0.5s ease-in-out;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ProductItem = styled.div`
  width: 193px;
  height: 259px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
`;

export const ProductImage = styled.img`
  width: 193px;
  height: 193px;
  background-color: #d9d9d9;
  object-fit: cover;
  user-select: none;
`;

export const ProductTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
`;

export const ProductPrice = styled.div`
  font-size: 12px;
  color: #666;
  user-select: none;
`;

export const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 14px;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const ImageUploadLabel = styled.label`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

export const ProfileImageUploadInfo = styled.div`
  position: absolute;
  top: 57%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  color: #fff;
`;

export const SignImageUploadInfo = styled.div`
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  color: #fff;
`;

export const InputButton = styled.div`
  display: flex;
  width: 157px;
  height: 48px;
  border: 1px #dddfe3 solid;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 22px;
  word-wrap: break-word;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const BasicInfoContatiner = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  min-width: 580px;
  flex-direction: column;
  padding: auto;
  margin: auto;
`;

export const BasicInfoHeader = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 2px solid #dddfe3;
`;

export const BasicInfoHeaderTitle = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  padding-bottom: 10px;
`;

export const BasicInfoContents = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 14px;
  margin-top: 33px;
`;

export const BasicInfoWrapper = styled.div`
  display: flex;
  &:nth-of-type(2) {
    order: 3;
  }
  &:nth-of-type(3) {
    order: 5;
  }
  &:nth-of-type(4) {
    order: 2;
  }
  &:nth-of-type(5) {
    order: 4;
  }
`;

export const BasicInfotitle = styled.div`
  display: flex;
  align-items: center;
  width: 80px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #474a53;
`;

export const BasicInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #1e2024;
  height: 30px;
  padding: 4px 8px;
`;

export const BasicInfoInput = styled.input`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #1e2024;
  border: 1px solid #dddfe3;
  border-radius: 4px;
  padding: 4px 8px;
  height: 30px;
`;

export const BasicInfoGroup = styled.div`
  display: flex;
  gap: 10px;
`;

export const SkeletonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 30px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const SkeletonProfileImage = styled.div`
  width: 100%;
  max-width: 600px;
  height: 600px;
  background-color: #f0f0f0;
  transition: opacity 0.5s ease-in-out;
  animation: skeleton-loading 2s infinite;
  @keyframes skeleton-loading {
    0% {
      background-color: #f0f0f0;
    }
    50% {
      background-color: #e0e0e0;
    }
    100% {
      background-color: #f0f0f0;
    }
  }
  @media (max-width: 768px) {
    height: 375px;
  }
`;

export const SkeletonProductItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 30px 0 60px 0;
  gap: 10.23px;
  overflow-x: auto;
  scroll-behavior: smooth;
  transition: opacity 0.5s ease-in-out;
  @keyframes skeleton-loading {
    0% {
      background-color: #f0f0f0;
    }
    50% {
      background-color: #e0e0e0;
    }
    100% {
      background-color: #f0f0f0;
    }
  }
`;

export const SkeletonProductItem = styled.div`
  width: 193px;
  height: 259px;
  background-color: #f0f0f0;
  animation: skeleton-loading 2s infinite;
  @keyframes skeleton-loading {
    0% {
      background-color: #f0f0f0;
    }
    50% {
      background-color: #e0e0e0;
    }
    100% {
      background-color: #f0f0f0;
    }
  }
`;

export const SkeletonProductImage = styled.div`
  width: 193px;
  height: 193px;
  background-color: #e0e0e0;
  animation: skeleton-loading 2s infinite;
  @keyframes skeleton-loading {
    0% {
      background-color: #f0f0f0;
    }
    50% {
      background-color: #e0e0e0;
    }
    100% {
      background-color: #f0f0f0;
    }
  }
`;

export const SkeletonProductTitle = styled.div`
  width: 100px;
  height: 20px;
  background-color: #e0e0e0;
  animation: skeleton-loading 2s infinite;
  @keyframes skeleton-loading {
    0% {
      background-color: #f0f0f0;
    }
    50% {
      background-color: #e0e0e0;
    }
    100% {
      background-color: #f0f0f0;
    }
  }
`;

export const SkeletonProductPrice = styled.div`
  width: 80px;
  height: 16px;
  background-color: #e0e0e0;
  animation: skeleton-loading 2s infinite;
  @keyframes skeleton-loading {
    0% {
      background-color: #f0f0f0;
    }
    50% {
      background-color: #e0e0e0;
    }
    100% {
      background-color: #f0f0f0;
    }
  }
`;

export const SkeletonSignImage = styled.div`
  width: 100%;
  max-width: 600px;
  height: 250px;
  background-color: #f0f0f0;
  transition: opacity 0.5s ease-in-out;
  @media (max-width: 768px) {
    width: 100%; /* 모바일에서는 이미지 너비를 화면 너비에 맞춥니다 */
    height: auto; /* 높이를 자동으로 조정합니다 */
    padding: 15px; /* 모바일에서 패딩을 줄여 더 많은 화면 공간을 활용합니다 */
  }
  animation: skeleton-loading 2s infinite;
  @keyframes skeleton-loading {
    0% {
      background-color: #f0f0f0;
    }
    50% {
      background-color: #e0e0e0;
    }
    100% {
      background-color: #f0f0f0;
    }
  }
`;

export const SkeletonArtistNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SkeletonArtistNameTitle = styled.div`
  width: 150px;
  height: 24px;
  background-color: #e0e0e0;
  animation: skeleton-loading 2s infinite;
  @keyframes skeleton-loading {
    0% {
      background-color: #f0f0f0;
    }
    50% {
      background-color: #e0e0e0;
    }
    100% {
      background-color: #f0f0f0;
    }
  }
`;

export const SkeletonArtistName = styled.div`
  width: 200px;
  height: 24px;
  background-color: #e0e0e0;
  transition: opacity 0.5s ease-in-out;
  animation: skeleton-loading 2s infinite;
  @keyframes skeleton-loading {
    0% {
      background-color: #f0f0f0;
    }
    50% {
      background-color: #e0e0e0;
    }
    100% {
      background-color: #f0f0f0;
    }
  }
`;

export const SkeletonArtistDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SkeletonArtistDescriptionTitle = styled.div`
  width: 150px;
  height: 24px;
  background-color: #e0e0e0;
  animation: skeleton-loading 2s infinite;
  @keyframes skeleton-loading {
    0% {
      background-color: #f0f0f0;
    }
    50% {
      background-color: #e0e0e0;
    }
    100% {
      background-color: #f0f0f0;
    }
  }
`;

export const SkeletonArtistDescription = styled.div`
  width: 100%;
  height: 100px;
  background-color: #e0e0e0;
  transition: opacity 0.5s ease-in-out;
  animation: skeleton-loading 2s infinite;
  @keyframes skeleton-loading {
    0% {
      background-color: #f0f0f0;
    }
    50% {
      background-color: #e0e0e0;
    }
    100% {
      background-color: #f0f0f0;
    }
  }
`;

export const SkeletonArtistHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SkeletonArtistHistoryTitle = styled.div`
  width: 150px;
  height: 24px;
  background-color: #e0e0e0;
  animation: skeleton-loading 2s infinite;
  @keyframes skeleton-loading {
    0% {
      background-color: #f0f0f0;
    }
    50% {
      background-color: #e0e0e0;
    }
    100% {
      background-color: #f0f0f0;
    }
  }
`;

export const SkeletonArtistHistory = styled.div`
  width: 100%;
  height: 910px;
  background-color: #e0e0e0;
  transition: opacity 0.5s ease-in-out;
  animation: skeleton-loading 2s infinite;
  @keyframes skeleton-loading {
    0% {
      background-color: #f0f0f0;
    }
    50% {
      background-color: #e0e0e0;
    }
    100% {
      background-color: #f0f0f0;
    }
  }
`;
