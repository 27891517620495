import styled from '@emotion/styled';

export const Spinner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 20%;
    animation: rotate 4s linear infinite;
    opacity: 0.3;

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;
