import styled from '@emotion/styled';

export const MainWrapper = styled.div`
  width: 100%;
`;

export const SectionArea = styled.section`
  width: 100%;
`;

export const FooterArea = styled.footer`
  width: 100%;
  height: 100px;
  margin-top: 100px;
  background: black;
`;

export const backgroundSlider = styled.div`
  width: 100%;
  height: 700px;
  background: rgba(0, 0, 0, 0.2);
  background-size: cover;
  background-position: center;
  transition: background-image 0.5s ease-in-out;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 120px;
  font-weight: 700;
`;
export const TextLoader = styled.div`
  font-size: 10vw;
  color: #fff;
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  position: relative;

  :before {
    content: '';
    animation: 5s print linear infinite;
  }

  :after {
    content: '';
    position: absolute;
    right: -4px;
    top: 50%;
    transform: translatey(-45%);
    width: 2px;
    height: 1.3em;
    background: currentColor;
    opacity: 0.8;
    animation: 0.7s blink steps(2) infinite;
  }

  @keyframes blink {
    0% {
      visibility: hidden;
    }
    100% {
      visibility: visible;
    }
  }
  @keyframes print {
    0% {
      content: '';
    }
    10% {
      content: 'A';
    }
    20% {
      content: 'Ar';
    }
    30% {
      content: 'Art';
    }
    40% {
      content: 'Art-';
    }
    50% {
      content: 'Art-M';
    }
    60% {
      content: 'Art-Mu';
    }
    70% {
      content: 'Art-Mus';
    }
    80%,
    90%,
    100% {
      content: 'Art-Muse';
    }
  }
`;

export const InfoWrapper = styled.div`
  width: 100%;
  padding: 30px;
  background: rgba(33, 33, 33, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Title = styled.div`
  > h1 {
    text-align: center;
    font-size: 30px;
  }
  > strong {
    color: #4f77dc;
    font-weight: 800;
    font-size: 70px;
  }
`;
export const IAALogo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 30px;

  > h1 {
    font-size: 90px;
  }

  > div {
    display: flex;
  }
`;
export const Info = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 5.5vw;
  text-align: center;
  color: #212121;

  > h2 {
    font-size: 2vw;
    font-weight: 400;
  }

  > p {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 1.5vw;
  }
`;
