export const PENDING = 'PENDING';
export const FIRST_APPROVE = 'FIRST_APPROVE';
export const DONE = 'DONE';
export const CANCEL = 'CANCEL';
export const UPDATE_ONHOLD = 'UPDATE_ONHOLD';

export const PendingModePerStatus = {
  OFFLINE: {
    ALL: '전체',
    PENDING: '1차 승인 대기 작품',
    FIRST_APPROVE: '2차 승인 대기 작품',
    DONE: '승인 완료 작품',
    CANCEL: '승인 취소 작품',
    UPDATE_ONHOLD: '수정 대기 중', // 추가
  },
  ONLINE: {
    ALL: '전체',
    PENDING: '승인 대기 작품',
    DONE: '승인 완료 작품',
    CANCEL: '승인 취소 작품',
    UPDATE_ONHOLD: '수정 대기 중', // 추가
  },
};
