import styled from '@emotion/styled';

export const DetailItemWrapper = styled.div`
  width: 100%;
`;

export const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const Btn = styled.div`
  border-radius: 30px;
  background: #576ee0;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #ffff;
  height: 40px;
  cursor: pointer;
  transition: 0.4s;

  :hover {
    background: #002b7a;
  }
`;

export const ItemSection = styled.p`
  padding-bottom: 20px;
  color: #212121;
  font-size: 15px;
  font-weight: 300;
`;
