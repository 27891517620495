import { useNavigate } from 'react-router-dom';
import * as S from './style';
import CustomButton from '../common/Button/index';

const NotFound = () => {
  const navigate = useNavigate();

  const handleClickBack = () => {
    navigate('/');
  };

  return (
    <S.NotFoundWrapper>
      <S.Title>404</S.Title>
      <S.Message>페이지를 찾을 수 없습니다.</S.Message>
      <S.Message>존재하지 않는 주소를 입력하셨거나</S.Message>
      <S.Message>
        요청하신 페이지의 주소가 변경, 삭제되어 찾을 수 없습니다.
      </S.Message>
      <CustomButton
        size="large"
        $state="default"
        text="홈으로 돌아가기"
        onClick={handleClickBack}
      />
    </S.NotFoundWrapper>
  );
};

export default NotFound;
