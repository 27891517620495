import { cloneElement } from 'react';

const DragAndDrop = ({ children, dropHandle, draggingHandle }) => {
  const preventEventHandle = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDragEnter = (e) => {
    preventEventHandle(e);
    if (draggingHandle) draggingHandle(true);
  };

  const onDrop = (e) => {
    preventEventHandle(e);
    dropHandle(e.dataTransfer.files);
    if (draggingHandle) draggingHandle(false);
  };

  const onDragLeave = (e) => {
    preventEventHandle(e);

    if (e.currentTarget.contains(e.relatedTarget)) return;
    if (draggingHandle) draggingHandle(false);
  };

  return (
    <>
      {cloneElement(children, {
        onDragEnter,
        onDrop,
        onDragOver: preventEventHandle,
        onDragLeave,
      })}
    </>
  );
};

export default DragAndDrop;
