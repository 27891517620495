import styled from '@emotion/styled';

export const UploadPayment = styled.div`
  max-width: 1200px;
  width: 100%;
  min-height: 70vh;
  background: #fff;
  margin: 20px auto 0 auto;
`;

export const TableStyle = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  table {
    table-layout: fixed;
    width: 500px;
    border-radius: 8px;
    overflow: hidden;

    @media (max-width: 500px) {
      width: 100%;
    }
  }

  th {
    text-align: start;
    word-break: break-all;
    padding-left: 30px;
  }

  tr {
    height: 50px;
  }

  td {
    padding-left: 30px;
  }
`;

export const FormWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.strong`
  font-weight: 700;
  color: rgba(33, 33, 33);
  font-size: 30px;
`;

export const Form = styled.form`
  width: 500px;
  padding: 0 10px;
  margin: 20px 0;
  box-sizing: border-box;

  @media (max-width: 500px) {
    width: 100%;
  }
`;
export const InputStyle = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  label {
    width: 100%;
    cursor: pointer;
    color: rgb(33, 33, 33);
    display: flex;
    flex-direction: column;
    gap: 5px;
    > span[required] {
      ::after {
        content: '*';
        position: relative;
        left: 5px;
        color: red;
      }
    }
  }

  input {
    width: 100%;
    height: 45px;
    border: 1px solid rgba(33, 33, 33, 0.1);
    border-radius: 8px;
    color: #212121;
    font-size: 15px;
    box-sizing: border-box;
    padding: 0 15px;
    ::placeholder {
      color: #919191;
    }
    outline: none;
  }

  input[type='number'] {
    padding: 0 30px 0 15px;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  input[type='submit'] {
    background: #576ee0;
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    cursor: pointer;
  }

  textarea {
    width: 100%;
    height: 15em;
    resize: none;
    outline: none;
    border: 1px solid rgba(33, 33, 33, 0.1);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 8px;
    overflow: scroll;
  }

  select {
    width: 100%;
    height: 45px;
    border: 1px solid rgba(33, 33, 33, 0.1);
    border-radius: 8px;
    color: #212121;
    font-size: 15px;
    box-sizing: border-box;
    padding: 0 15px;

    > option {
      color: #919191;
    }
  }
`;
export const FileUpload = styled.div`
  width: 100%;
  aspect-ratio: 1 / 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(33, 33, 33, 0.1);
  border-radius: 8px;
  cursor: pointer;
  position: relative;

  > div {
    color: ${({ isFile }) => (isFile ? '' : '#919191')};
    font-weight: 400;
    font-size: 30px;
  }

  input[type='file'] {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
  }
`;

export const ExcelSampleDownLoad = styled.div`
  background: #919191;
  border-radius: 8px;
  width: 140px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
  color: #000;
`;
