import { useState } from 'react';
import styled from '@emotion/styled';
import { PiInfo } from 'react-icons/pi';
import PriceTable from '../common/PriceTable';

const Wrapper = styled.div`
  width: 475px;
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 2px #1e2024 solid;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #1e2024;
`;

const SubTitle = styled.div`
  font-size: 12px;
  color: #9fa1a8;
  position: relative;
`;

const PriceTableWrapper = styled.div`
  position: absolute;
  left: -490px;
  z-index: 1;
  background-color: white;
  color: #000;
  border: 1px solid rgba(33, 33, 33, 0.1);
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  @media (max-width: 500px) {
    left: -90px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  font-size: 14px;
  color: #1e2024;
  border-bottom: 1px #dddfe3 solid;
`;

const Label = styled.div`
  font-weight: bold;
  width: 140px;
  flex-direction: row;
  display: flex;
`;

const SubLabel = styled.div`
  font-size: 12px;
  color: #9fa1a8;
  padding-left: 6px;
`;

const ValueWrapper = styled.div``;

const Value = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const ValueTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const ArtWorkTitle = styled.div`
  display: flex;
`;

const ArtworkName = styled.div`
  width: 180px;
`;

const ArtworkCount = styled.div``;

const ArtworkPrice = styled.div`
  width: 70px;
  text-align: end;
`;

const ArtistInfoCard = ({ artistCardData }) => {
  const {
    type,
    startDate,
    endDate,
    bankData,
    phoneNumber,
    titles,
    artworks,
    settlementPrice,
  } = artistCardData;

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <>
      <Wrapper>
        <HeaderWrapper>
          <Title>기본 정보</Title>
        </HeaderWrapper>
        <Row>
          <Label>정산 대상</Label>
          <ValueWrapper>{type}</ValueWrapper>
        </Row>
        <Row>
          <Label>정산 기간</Label>
          <ValueWrapper>
            {startDate} - {endDate}
          </ValueWrapper>
        </Row>
        <Row>
          <Label>정산 계좌</Label>
          <ValueWrapper>{bankData}</ValueWrapper>
        </Row>
        <Row>
          <Label>연락처</Label>
          <ValueWrapper>{phoneNumber}</ValueWrapper>
        </Row>
        <Row>
          <Label>작품</Label>
          <ValueWrapper>
            <ValueTitle>
              {titles.map((title) => (
                <ArtWorkTitle key={title}>{title}</ArtWorkTitle>
              ))}
            </ValueTitle>
          </ValueWrapper>
        </Row>
      </Wrapper>

      <Wrapper>
        <HeaderWrapper>
          <Title>금액 정보</Title>
          <SubTitle
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <PiInfo size={28} />
            {isHovering && (
              <PriceTableWrapper>
                <PriceTable />
              </PriceTableWrapper>
            )}
          </SubTitle>
        </HeaderWrapper>

        <Row>
          <Label>정산 상세 내역</Label>
          <ValueWrapper>
            {artworks.map((artwork) => (
              <Value key={artwork.artworkName}>
                <ArtworkName>{artwork.artworkName}</ArtworkName>
                <ArtworkCount>{artwork.artworkCount}개</ArtworkCount>
                <ArtworkPrice>{artwork.artworkPrice}원</ArtworkPrice>
              </Value>
            ))}
          </ValueWrapper>
        </Row>
        <Row>
          <Label>
            최종 송금액
            <SubLabel>(VAT 별도)</SubLabel>
          </Label>
          <ValueWrapper>{settlementPrice}원</ValueWrapper>
        </Row>
      </Wrapper>
    </>
  );
};

export default ArtistInfoCard;
