// PendingArtDetail.js
import * as S from './style';
import useFetch from '../../../hooks/useFetch';
import Spinner from '../Spinner';
import { useState, useEffect } from 'react';

const PendingArtDetail = ({
  data,
  isApproveArt,
  isDeleteArt,
  deleteArt,
  onClose,
}) => {
  const { id, imageUrl, status, isCanceled, ...rest } = data;
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isImageReady, setIsImageReady] = useState(false);

  const { fetch: approveArt, isLoading } = useFetch({
    url: `/artwork/approve/${id}?status=${status}`,
    method: 'patch',
    onFinally: onClose,
  });

  useEffect(() => {
    setIsImageLoaded(false);
    setIsImageReady(false);
  }, [imageUrl]);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
    setTimeout(() => {
      setIsImageReady(true);
    }, 500); // 이미지 로딩 완료 후 0.5초 후에 스피너 숨김
  };

  const handleClose = () => {
    setIsImageLoaded(false);
    setIsImageReady(false);
    onClose();
  };

  const PendingArtInfo = {
    title: '작품명',
    description: '설명',
    size: '크기',
    price: '가격',
    materials: '재료',
    country: '국가',
    frameSize: '판화 크기',
    artist: '작가 명',
    createdYear: '제작 년도',
  };

  return (
    <S.PendingArtDeatilOverlay>
      <S.PendingArtDeatilWrapper>
        {isLoading ? (
          <>
            <S.Tilte>작품 등록 중 입니다</S.Tilte>
            <Spinner />
          </>
        ) : (
          <>
            <S.CloseBtn>
              <S.CloseBtnSpan onClick={handleClose}>닫기</S.CloseBtnSpan>
            </S.CloseBtn>
            <S.Tilte>작품 상세 정보</S.Tilte>
            <S.ProductImg>
              <S.ProductImgWrapper>
                {!isImageReady && <Spinner />}
                <S.ProgressiveImage
                  src={`${imageUrl}?q=10`}
                  alt="low quality"
                  style={{
                    filter: 'blur(10px)',
                    opacity: isImageLoaded ? 0 : 1,
                  }}
                />
                <S.ProgressiveImage
                  src={imageUrl}
                  alt="high quality"
                  onLoad={handleImageLoad}
                  style={{ opacity: isImageLoaded ? 1 : 0 }}
                />
              </S.ProductImgWrapper>
            </S.ProductImg>
            <S.InfoTab>
              <div>
                {Object.entries({ ...rest }).map(([key, value]) => (
                  <div key={key}>
                    <p>{PendingArtInfo[key]}</p>
                    <p id={key}>{value}</p>
                  </div>
                ))}
              </div>
            </S.InfoTab>

            <S.BtnWrapper>
              {isApproveArt && (
                <S.ControlBtn
                  modeState={'approve'}
                  onClick={() => approveArt()}
                >
                  작품 승인
                </S.ControlBtn>
              )}
              {isDeleteArt && (
                <S.ControlBtn modeState={'delete'} onClick={() => deleteArt()}>
                  {isApproveArt ? '승인 요청 취소' : '등록 취소'}
                </S.ControlBtn>
              )}
            </S.BtnWrapper>
          </>
        )}
      </S.PendingArtDeatilWrapper>
    </S.PendingArtDeatilOverlay>
  );
};

export default PendingArtDetail;
