// 작가 등록 페이지

import { useNavigate, useSearchParams } from 'react-router-dom';
import Input from '../common/Input';
import * as S from './style';
import { useEffect, useState } from 'react';
import useFetch from '../../hooks/useFetch';
import { ArtMuseUrl } from '../../Lib/ArtMuseUrl';
import { Banks } from '../../Lib/Banks';
import DragAndDrop from '../common/DragAndDrop';

const InviteArtist = () => {
  const [searchParams] = useSearchParams();
  const emailSecret = searchParams.get('code');
  const navigate = useNavigate();
  const [profilePreview, setProfilePreview] = useState();
  const [signPreview, setSignPreview] = useState();
  const [email, setEmail] = useState('');
  const [formValue, setFormValue] = useState({
    password: '',
    checkPassword: '',
    name: '',
    activityName: '',
    inviteCode: '',
    bankName: '',
    bankNumber: '',
    description: '',
    history: '',
    signImg: null,
    phoneNumber: '',
    representativeImg: null,
  });

  const { fetch: createArtist, isLoading } = useFetch({
    url: '/user',
    method: 'post',
    onSuccess: () => {
      window.location.href = ArtMuseUrl;
    },
  });

  const { fetch: getEmail } = useFetch({
    url: `/user/exist?emailSecret=${encodeURIComponent(emailSecret)}`,
    method: 'get',
    onSuccess: ({ email }) => setEmail(email),
    onFailure: () => {
      navigate('/404');
    },
  });

  useEffect(() => {
    if (!emailSecret) {
      navigate('/404');
      return;
    }
    getEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const readFile = (e) => {
    const key = e.target.id;
    const files = e.target.files;

    setFile(key, files);
  };

  const setFile = (key, files) => {
    if (!files[0]) return;

    if (key === 'representativeImg') {
      if (
        !files[0].type.startsWith('image/png') &&
        !files[0].type.startsWith('image/jpeg') &&
        !files[0].type.startsWith('image/jpg')
      )
        return window.alert(
          'PNG, JPEG 또는 JPG 형태의 이미지만 업로드 해주세요.',
        );
    } else if (key === 'signImg') {
      if (!files[0].type.startsWith('image/png'))
        return window.alert('PNG 형태의 이미지만 업로드 해주세요.');
    }

    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.onload = () => {
        if (img.width > 4800 || img.height > 4800) {
          window.alert('이미지 크기는 4800px * 4800px 이하여야 합니다.');
          return;
        }

        setFormValue((prev) => ({ ...prev, [key]: files[0] }));
        if (key === 'representativeImg') {
          setProfilePreview(reader.result);
        } else if (key === 'signImg') {
          setSignPreview(reader.result);
        }
      };
      img.src = reader.result;
    };

    reader.readAsDataURL(files[0]);
  };

  const handleInputValueChange = (e) => {
    const key = e.target.id;
    const value = e.target.value;
    setFormValue((prev) => ({ ...prev, [key]: value }));
  };

  const handleTextareaChange = (e) => {
    const key = e.target.id;
    let value = '';
    e.target.value.split('\n').forEach((line) => {
      value += line ? `<p>${line}</p><br/>` : '<br/>';
    });
    setFormValue((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLoading) return;

    const { activityName, name, ...rest } = formValue;
    const formData = new FormData();

    formData.append('email', email);

    Object.entries({
      name,
      activityName: activityName || name,
      ...rest,
    }).forEach(([key, value]) => {
      formData.append(key, value);
    });

    createArtist(formData);
  };

  return (
    <S.InviteArtistWrapper>
      <S.FormWrapper>
        <S.Title>작가 등록</S.Title>
        <S.Form onSubmit={handleSubmit}>
          <S.InputWrapper>
            <label htmlFor="representativeImg">
              <span required>
                작가소개 이미지 등록 ( 1:1 비율의 이미지 파일을 업로드 해주세요)
              </span>
              <DragAndDrop
                dropHandle={(e) => {
                  setFile('representativeImg', e);
                }}
              >
                <S.FileUpload htmlFor="representativeImg">
                  {profilePreview ? (
                    <S.BackgroundImg bgImg={profilePreview}></S.BackgroundImg>
                  ) : (
                    '프로필 혹은 대표작품을 등록해주세요'
                  )}
                  <input
                    id="representativeImg"
                    type={'file'}
                    multiple
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={readFile}
                    required
                  />
                </S.FileUpload>
              </DragAndDrop>
            </label>
            <Input
              id="email"
              type={'email'}
              label={'이메일'}
              placeholder={'이메일를 입력해주세요'}
              readOnly
              defaultValue={email}
              required
            />
            <Input
              id="password"
              type={'password'}
              setValue={handleInputValueChange}
              label={'비밀번호'}
              placeholder={'비밀번호를 입력해주세요'}
              pattern={
                '(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&\\?*])[A-Za-z\\d!@#$%^&\\?*]{8,}'
              }
              title="대문자 1개이상, 소문자 1개이상, 숫자 1개이상, 특수문자 1개이상을 포함한 8자이상 비밀번호를 입력해주세요"
              required
            />
            <Input
              id="checkPassword"
              type={'password'}
              setValue={handleInputValueChange}
              label={'비밀번호 확인'}
              placeholder={'비밀번호를 한번 더 입력해주세요'}
              pattern={`${
                formValue.password === formValue.checkPassword ? '^.*$' : ''
              }`}
              title="비밀번호가 다릅니다"
              required
            />
            <Input
              id="name"
              type={'text'}
              setValue={handleInputValueChange}
              label={'작가명 (본명)'}
              placeholder={'작가명을 입력해주세요'}
              required
            />
            <Input
              id="phoneNumber"
              type={'tel'}
              setValue={handleInputValueChange}
              label={'연락처'}
              placeholder={`연락처 ('-'를 제외한 숫자만 입력해주세요)`}
              pattern="\d*"
              minLength="9"
              maxLength="11"
              required
            />
            <Input
              id="activityName"
              type={'text'}
              setValue={handleInputValueChange}
              label={'활동명 (작품과 함께 전시될 이름을 적어주세요)'}
              placeholder={'활동명을 입력해주세요'}
            />
            <Input
              id="inviteCode"
              type={'text'}
              setValue={handleInputValueChange}
              label={'초대코드'}
              placeholder={'초대코드를 입력해주세요'}
              required
            />
            <label>
              <span required>계좌 번호</span>
              <S.BankNumber>
                <select
                  id="bankName"
                  onChange={handleInputValueChange}
                  required
                >
                  <option value="">은행</option>
                  {Banks.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
                <Input
                  id="bankNumber"
                  type={'text'}
                  setValue={handleInputValueChange}
                  placeholder={`계좌번호 ('-'를 제외한 숫자만 입력해주세요)`}
                  pattern="^[0-9]*"
                  minLength="10"
                  maxLength="14"
                  required
                />
              </S.BankNumber>
            </label>
            <Input
              id="description"
              type={'text'}
              setValue={handleInputValueChange}
              label={'작가 설명'}
              placeholder={'작가 설명을 입력해주세요'}
              required
            />
            <label htmlFor="history">
              <span>작가 이력</span>
              <textarea
                id="history"
                onChange={handleTextareaChange}
                maxLength={20000}
              ></textarea>
            </label>
            <label htmlFor="signImg">
              <span required>
                작가 서명 (배경없는 검정색 사인 이미지,png파일로 등록해주세요.)
              </span>
              <DragAndDrop
                dropHandle={(e) => {
                  setFile('signImg', e);
                }}
              >
                <S.FileUpload htmlFor="signImg">
                  {signPreview ? (
                    <S.BackgroundImg bgImg={signPreview}></S.BackgroundImg>
                  ) : (
                    '작품서명을 등록해주세요'
                  )}
                  <input
                    id="signImg"
                    type={'file'}
                    multiple
                    accept="image/png"
                    onChange={readFile}
                    required
                  />
                </S.FileUpload>
              </DragAndDrop>
            </label>
            <input
              type="submit"
              value={isLoading ? '작가 등록중...' : '작가 등록'}
            />
          </S.InputWrapper>
        </S.Form>
      </S.FormWrapper>
    </S.InviteArtistWrapper>
  );
};

export default InviteArtist;
