import { cloneElement, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import * as S from './style';

const Portal = ({ children, onClose }) => {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    document.body.style.cssText = `
        overflow: hidden
      `;

    return () => {
      document.body.style.cssText = '';
    };
  }, []);

  if (typeof window === 'undefined') return null;

  if (!isMounted) return null;

  const onClick = (e) => {
    e.stopPropagation();
  };

  return ReactDOM.createPortal(
    <S.Positioner onClick={onClose}>
      <S.StopPropagation onClick={onClick}>
        {cloneElement(children)}
      </S.StopPropagation>
    </S.Positioner>,
    document.getElementById('portal'),
  );
};

export default Portal;
